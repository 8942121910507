import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withIdentity, IdentityProps } from 'src/core/services/authentication'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Row, Col, Card, Tabs, Button } from 'antd'
import ContentHeader from 'src/core/ui/content-header'
import { container } from 'src/inversify.config'
import SessionLogPage from './sessionLog'
import ExternalApiAccessLogPage from './externalApiAccessLog'
import SystemLogPage from './systemLog'
import AuthLogPage from './authenticationLog'
import { CacheProps, withCache } from 'src/core/services/cache.service';
const { TabPane } = Tabs

interface AdminPageProps extends WithTranslation, CacheProps , IdentityProps, RouteComponentProps<{}> { }

enum AdminTabs {
    General = 'AuthenticationLogs',
    Member1 = 'SystemLog',
    Member2 = 'SessionLog',
    Member3 = 'ExternalApiAccessLog',
}

const AdminPage: FC<AdminPageProps> = (props) => {
    const { t, cache } = props
    const [tabActive, setTabActive] = useState<string | undefined>(cache.get());
    useEffect(() => {
        if (!props.identity.roles || props.identity.roles.filter((o) => o.includes('Administrator') || o.includes('Manager')).length == 0) {
            props.history.push('/')
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Row gutter={0}>
                <Col span={16}>
                    <ContentHeader showBack  title={t('Logs')} />
                </Col>
          <Col span={8}>
         
          </Col>
            </Row>
            <Row gutter={16} align="top" justify="space-between">
                <Col span={24}>
                    <div className={"ibox"} style={{ width: '100%', padding: "20px 20px", background: "#fff"}}>
                        {<Tabs defaultActiveKey={tabActive} onChange={(activeKey) => cache.save(activeKey)}>
                            <TabPane tab={t("Authentication Logs")} key="authLogs">
                                <AuthLogPage isAdmin={props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0} />
                            </TabPane>
                            <TabPane tab={t("System Logs")} key="systemLog">
                                <SystemLogPage isAdmin={props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0} />
                            </TabPane>
                            <TabPane tab={t("Session Logs")} key="sessionLog">
                                <SessionLogPage isAdmin={props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0} />
                            </TabPane>
                            <TabPane tab={t("External Api Access Log")} key="externalApiAccessLog">
                                <ExternalApiAccessLogPage isAdmin={props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0} />
                            </TabPane>
                        </Tabs>}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default withCache( withRouter(withIdentity(withTranslation()(AdminPage))))