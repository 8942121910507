import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import HttpService, { formatMessage } from 'src/core/services/http.service'
import ContentTitle from 'src/core/ui/content-header'
import { container } from '../../../inversify.config'
import { UserProfileItem, UserProfileStore } from 'src/stores/profiles/profile-store'
import { UsersStore, UserSummary } from 'src/stores/users/users-store'
import { Query } from 'src/core/stores/data-store'
import { IdentityProps, withIdentity } from '../../../core/services/authentication'
import EntitySelect from './components/assignedToEdit'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import { CompressOutlined, GoldOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { clone, formatDate } from 'src/core/utils/object'
import { Card, Col, Empty, Row, Skeleton, Tabs, Typography } from 'antd'
import { TenantDetailsMembersTab } from '../../admin/tenants/components/members/tenant-details-members-tab'
import { render } from 'react-dom'
import { ProfileTenantMembers } from './components/tenant-members'
import Button from 'antd/es/button'


interface ProfileClientsProps extends WithTranslation, IdentityProps {
}

const ProfileClients: FC<ProfileClientsProps> = (props) => {


  const { t } = props
  const [selectedTenantId, setSelectedTenantId] = useState<string>()
  const [loadComplete, setLoadComplete] = useState(false)
  const inputRef = useRef()
  const userId = props.identity.id

  const CheckIfCanAccess = () => {

    let canAccess = props.identity.roles != undefined && props.identity.roles.some(value => value === "SystemManager")
    if (!canAccess) {
      window.location.href = props.identity.identityUrl
      return
    }


  }

  useEffect(
    () => {
      CheckIfCanAccess()
    }, [inputRef, selectedTenantId]
  )

  const onChange = (value) => {
    if (value) {
      setSelectedTenantId(value.value)
    }
  }

  const loadTenant = (tenantId) => {
    if (tenantId) {
      setSelectedTenantId(tenantId)
    }
  }


  return <div>
    <Row gutter={0}>
      <Col span={6}>
        <ContentTitle type={t('Requests')} title={t("Service Manager")} />
      </Col>
      <Col span={10}>
        <div style={{ width: "100%", padding: "20px", border: "solid .5px #EFEFEF", borderRadius: "10px", background: "white" }}>
          <EntitySelect label={ t("Select Tenant...")  } style={{ width: "100%" }} loadComplete={() => setLoadComplete(true)} onChange={value => onChange(value)} loadTenantId={loadTenant} />
        </div>
      </Col>

    </Row>


    {loadComplete &&
      <>
        {
          selectedTenantId != undefined ?
            <Card>
              <ProfileTenantMembers {...props} entityId={selectedTenantId} />
            </Card>
            :
          <Empty
            style={{ padding: 40, marginTop : 50 }}
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={
                <Typography.Text>
                  {t("You dont have access to any Tenant. ")}<a onClick={() => { window.location.reload() }}>{t("Refresh page")}</a>
                </Typography.Text>
              }
            >

            </Empty>

        }
      </>
    }
    {!loadComplete &&
      <Skeleton style={{ padding: 30 }} paragraph={{ rows: 8 }} />

    }

  </div>
}

export default withIdentity(withTranslation()(ProfileClients)) as any