import { Layout, Spin } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router'
import Shell from 'src/core/ui/shell'
import SecureContent from '../../core/services/authentication'
import { AppConfiguration, ConfigurationService } from '../../core/services/configuration.service'
import { container } from '../../inversify.config'
import Home from './home'
import logo from 'src/core/assets/logo.png'
import footerLogo from 'src/core/assets/footer-logo.png'
import ShellIdleApp from 'src/core/ui/shell-idle-app'
import ShellDisclaimer from '../../core/ui/shell-disclaimer'
import { LoadingOutlined } from '@ant-design/icons'
import { WithTranslation, withTranslation } from 'react-i18next'
import ShellExpirationToken from '../../core/ui/shell-expiration-token'

interface HomeRoutesProps extends RouteComponentProps, WithTranslation {

}


const HomeRoutes: FC<HomeRoutesProps> = (props) => {

  const configurationService = container.get(ConfigurationService)
  const [config, setConfig] = useState<AppConfiguration>()
  const inputRef = useRef()

  useEffect(
    () => {
      configurationService.current().then(x => { setConfig(x) })

    }, [inputRef]
  )

  if (config) {
    return <SecureContent
      config={{
        authority: config.identityServerUrl,
        redirectUri: config.serviceUrl,
        silentRedirectUri: `${config!.serviceUrl}/silentrefresh.html`,
        resource: config.scopes,
        clientId: config.clientId,

      }}
      onAuthentication={() => window.history.replaceState(window.history.state, '', window.location.pathname + window.location.search)}
    >
      <div className={"mainhomePage"}>
        <ShellExpirationToken>
          <Shell stickyDisabled hideMyApps statusMode availableLanguages={['es', 'en', 'pt', 'fr', 'de', 'it']} className={config.environment + " home-ficonnect"} v2 logo={logo} footerLogo={footerLogo} >
            <Home />
          </Shell>
        </ShellExpirationToken>
      </div >
    </SecureContent >
  } else {
    return <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '100px',
        marginTop: '-50px',
        marginLeft: '-200px',
        width: '400px',
        textAlign: 'center',
      }}
    >
      <div style={{ marginLeft: '55px', marginBottom: '10px', display: 'flex' }}>
        <Spin style={{ marginRight: '10px', marginTop: '5px' }} indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
        <img style={{ marginTop: 12, background: 'rgb(0,0,164)', padding: '4px 4px 4px 4px' }} alt="fi-logo" src={logo} height={34} />
        <span style={{ fontSize: 18, float: 'right', marginTop: 12, marginLeft: 5, fontWeight: 'bold', color: '#0000a4' }}>
          Helping ideas grow
        </span>
      </div>

    </div>
  }
}

export default withTranslation()(withRouter(HomeRoutes))
