import { FC, useState, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import { clone, formatDateTime } from 'src/core/utils/object'
import { container } from 'src/inversify.config'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import { ApiEntryType, ExternalApiAccessLogStore, ExternalApiAccessLogSummary } from 'src/stores/authLogs/logs-store'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import HttpService from 'src/core/services/http.service'
import React from 'react'
import moment from 'moment'
import { Button, Checkbox, DatePicker, Modal, Select, Tag, Tooltip } from 'antd'
import CodeEditor from 'src/common/code-editor'
import { CheckOutlined, LockOutlined, SearchOutlined, UserDeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons'
const { RangePicker } = DatePicker
const { Option } = Select
interface ExternalApiAccessLogViewProps extends RouteComponentProps, CacheProps, WithTranslation {

}

const ExternalApiAccessLog: FC<ExternalApiAccessLogViewProps> = (props) => {
  const { t } = props
  const externalApiAccessLogStore = container.get<ExternalApiAccessLogStore>(ExternalApiAccessLogStore)
  const externalApiAccessLogStoreState = externalApiAccessLogStore.state
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'date', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    parameters: {
      includeHierarchy: 'true',
    },
    odataObject: {
      filter: {}
    },
  } as Query)

  const [initPagination, setInitPagination] = useState(false)
  const httpService = container.get(HttpService)
  const [ipFilter, setIpFilter] = useState<any>()
  const [userFilter, setUserFilter] = useState<any>()
  const [apiEntryFilter, setApiEntryFilter] = useState<any>()
  const [startDateFilter, setStartDateFilter] = useState<any>()
  const [endDateFilter, setEndDateFilter] = useState<any>()
  const [loadingInitialData, setLoadingInitialData] = useState(true)

  const load = (query: Query) => {
    externalApiAccessLogStore.load(query)
  }

  const Build = (query: Query, ip: any, user: any, apiEntry: any, startDate: any, endDate: any): Query => {
    if (ip) {
      query.parameters.ip = ip
    }
    else {
      query.parameters.ip = undefined
    }

    if (user) {
      var filterUser = {
        userId: { eq: { type: 'guid', value: user.id } }
      }
      query.odataObject.filter.user = filterUser
    }
    else {
      query.odataObject.filter.user = undefined
    }
    if (apiEntry) {
      query.parameters.apiEntry = apiEntry
    }
    else {
      query.parameters.apiEntry = undefined
    }

    if (startDate) {
      var startDateMoment = moment(startDate).format("YYYY-MM-DD HH:mm:ss")
      query.parameters.startDate = startDateMoment
    }
    else {
      query.parameters.startDate = undefined
    }
    if (endDate) {
      var endDateMoment = moment(endDate).format("YYYY-MM-DD HH:mm:ss")
      query.parameters.endDate = endDateMoment
    }
    else {
      query.parameters.endDate = undefined
    }
    props.cache.saveWithCustomKey(externalApiAccessLogStore.baseUrl, { query: query, ip: ip, apiEntry: apiEntry })
    return query
  }

  useEffect(() => {
    var queryStored = props.cache.getWithCustomKey(externalApiAccessLogStore.baseUrl)
    if (queryStored && queryStored.query)
      setQuery(queryStored.query)
    if (queryStored && queryStored.ip)
      setIpFilter(queryStored.ip)
    if (queryStored && queryStored.user)
      setUserFilter(queryStored.user)
    if (queryStored && queryStored.apiEntry)
      setApiEntryFilter(queryStored.apiEntry)
    if (queryStored && queryStored.startDate)
      setStartDateFilter(queryStored.startDate)
    if (queryStored && queryStored.endDate)
      setEndDateFilter(queryStored.endDate)

    load(Build(queryStored?.query || query, queryStored?.ip || ipFilter, (queryStored?.user) || userFilter, (queryStored?.apiEntry) || apiEntryFilter, queryStored?.startDate || startDateFilter, queryStored?.endDate || endDateFilter))
    setLoadingInitialData(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loadingInitialData) {
      load(Build(query, ipFilter, userFilter, apiEntryFilter, startDateFilter, endDateFilter))
    }
  }, [query, ipFilter, userFilter, apiEntryFilter, startDateFilter, endDateFilter])

  const GetOperation = (data) => {
    const showModal = () => {
      let resultData = JSON.stringify(data.metaData, null, 2)
      Modal.info({
        title: 'Metadata',
        width: 800,
        content: (
          <div>
            <CodeEditor
              value={resultData}
              classSuffix="fi-code-editor"
              height={300}
              language="yaml"
            />

          </div>
        ),
        onOk() { },
      })
    }
    if (data.apiEntry === "TenantsController - GetEntities") {
      return (
        <span>
          <Tag icon={<CheckOutlined />} color="#87d068">
            {"TenantsController - GetEntities"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.apiEntry === "PermissionController - GetRoles") {
      return (
        <span>
          <Tag icon={<CheckOutlined />} color="#722ed1">
            {"PermissionController - GetRoles"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.apiEntry === "PermissionController - LockUser") {
      return (
        <span>
          <Tag icon={<LockOutlined />} color="#eb2f96">
            {"PermissionController - LockUser"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.apiEntry === "PermissionController - InviteUsers") {
      return (
        <span>
          <Tag icon={<UsergroupAddOutlined />} color="#fa8c16">
            {"PermissionController - InviteUsers"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.apiEntry === "PermissionController - GetUserWithRole") {
      return (
        <span>
          <Tag icon={<CheckOutlined />} color="#fadb14">
            {"PermissionController - GetUserWithRole"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.apiEntry === "PermissionController - RemoveRolesInTenant") {
      return (
        <span>
          <Tag icon={<UserDeleteOutlined />} color="#1890ff">
            {"PermissionController - RemoveRolesInTenant"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.apiEntry === "PermissionController - InviteUsersWithOperationRequest") {
      return (
        <span>
          <Tag icon={<CheckOutlined />} color="#135200">
            {"PermissionController - InviteUsersWithOperationRequest"}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }

    return null
  }

  const onIpFilterChange = (ip: any) => {
    setInitPagination(!initPagination)
    setIpFilter(ip)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
  }

  const onApiEntryFilterChange = (apiEntry: any) => {
    setInitPagination(!initPagination)
    setApiEntryFilter(apiEntry)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
  }

  const onDateRangeFilterChange = (dates: any) => {
    var [startDate, endDate] = [null, null]
    if (dates) {
      [startDate, endDate] = dates
    }
    setInitPagination(!initPagination)
    setStartDateFilter(startDate)
    setEndDateFilter(endDate)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
  }

  const tableModel = {
    query: query,

    columns: [
      {
        sortable: true,
        centered: true,
        field: 'ip',
        title: t('IP'),
        renderer: (data) => <span>{data.ip}</span>
      },
      {
        sortable: false,
        centered: true,
        field: 'userName',
        title: t('Username'),
        renderer: (data) => <span>{data.userName}</span>
      },
      {
        sortable: true,
        field: 'apiEntry',
        title: t('Api Entry'),
        renderer: (data) => <span>{GetOperation(data)}</span>
      },
      {
        sortable: true,
        field: 'date',
        title: t('Date'),
        renderer: (data) => <span>{formatDateTime(data.date, undefined, "DD-MM-YYYY HH:mm:ss")}</span>,
      },

    ],
    data: externalApiAccessLogStoreState.value,
    sortFields: [
      { field: 'date', text: t('Date'), useProfile: false },
        { field: 'ip', text: 'IP', useProfile: false },
        { field: 'apiEntry', text: t('Api Entry'), useProfile: false }
    ],
  } as TableModel<ExternalApiAccessLogSummary>

  const rightToolbar = (
    <React.Fragment>

      <li>
        <Select defaultValue={apiEntryFilter}
          allowClear placeholder={t("Select Api Entry...")}
          style={{ width: 380 }}
          onChange={(value) => onApiEntryFilterChange(value)}
        >

          <Option value={"TenantsController_GetEntities"}>{
            <Tag icon={<CheckOutlined />} color="#87d068">
              {"TenantsController - GetEntities"}
            </Tag>}
          </Option>

          <Option value={"PermissionController_GetRoles"}>{
            <Tag icon={<CheckOutlined />} color="#722ed1">
              {"PermissionController - GetRoles"}
            </Tag>}
          </Option>

          <Option value={"PermissionController_LockUser"}>{
            <Tag icon={<LockOutlined />} color="#eb2f96">
              {"PermissionController - LockUser"}
            </Tag>}
          </Option>

          <Option value={"PermissionController_InviteUsers"}>{
            <Tag icon={<UsergroupAddOutlined />} color="#fa8c16">
              {"PermissionController - InviteUsers"}
            </Tag>}
          </Option>

          <Option value={"PermissionController_GetUserWithRole"}>{
            <Tag icon={<CheckOutlined />} color="#fadb14">
              {"PermissionController - GetUserWithRole"}
            </Tag>}
          </Option>

          <Option value={"PermissionController_RemoveRolesInTenant"}>{
            <Tag icon={<UserDeleteOutlined />} color="#1890ff">
              {"PermissionController - RemoveRolesInTenant"}
            </Tag>}
          </Option>

          <Option value={"PermissionController_InviteUsersWithOperationRequest"}>{
            <Tag icon={<CheckOutlined />} color="#135200">
              {"PermissionController - InviteUsersWithOperationRequest"}
            </Tag>}
          </Option>

        </Select>
      </li>

      <li>
        <RangePicker
          renderExtraFooter={() => t("Select a date...")}
          style={{ width: 350 }}
          format='DD-MM-YYYY HH:mm:ss'
          showTime
          onChange={(dates) => onDateRangeFilterChange(dates)}
          placeholder={[t('Start date'), t('End date')]}
          value={[startDateFilter, endDateFilter]}
          disabledDate={(current) => {
            if (startDateFilter && !endDateFilter) {
              return current && current < moment(startDateFilter)
            } else if (!startDateFilter && endDateFilter) {
              return current && current > moment(endDateFilter)
            }
            return false
          }}
        />
      </li>

    </React.Fragment>
  )


  return (

    <div style={{ width: '100%' }}>
      <TableView
        enableTableSettings
        rightToolbar={rightToolbar}
        model={tableModel}
        onQueryChanged={(newQuery: Query) => {
          setQuery(newQuery)
        }}
        onRefresh={() => externalApiAccessLogStore.load(query)}
        error={externalApiAccessLogStoreState.errorMessage.value && formatMessage(externalApiAccessLogStoreState.errorMessage.value)}
      >
      </TableView>
    </div>
  )
}



// Wire up the React component to the Redux store
export default withCache(withTranslation()(withRouter(ExternalApiAccessLog)))