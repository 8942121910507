import * as React from 'react'
import { Form, Card, Input, Button, Modal, Row, Col, Alert, notification, Tag, Tooltip } from 'antd'
let FormItem = Form.Item
let TextArea = Input.TextArea
import autobind from 'autobind-decorator'
import { GroupItemStore, GroupItem } from 'src/stores/groups/groups-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import ContentTitle from 'src/core/ui/content-header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import FormEditorView from 'src/core/ui/form/form-editor'
import GroupUsersView from './users/groupuser-list'
import GroupRolesView from './roles/grouproles-list'
import { clone } from 'src/core/utils/object'
import { useEffect, useMemo, useRef, useState } from 'react'
import { container } from '../../../inversify.config'
import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { IdentityProps, withIdentity } from '../../../core/services/authentication'
import { TenantIdentityGroupStore, TenantItem } from '../../../stores/tenant-identity/tenant-identity-store'
import EditMemberDialog from 'src/pages/admin/tenants/components/identity-tenant-roles-editor'
import { AddTenantDialog } from 'src/pages/admin/identity-tenants/tenant-identities'
import AreaEditor from 'src/components/areaEdit'
import FloatInput from '../../../core/ui/awesome/floatComponent/input'
import FloatTextArea from '../../../core/ui/awesome/floatComponent/textArea'

interface GroupViewProps extends WithTranslation, IdentityProps, RouteComponentProps<RouterParameters> {

}


interface RouterParameters {
  id: string
}

const GroupView: React.FC<GroupViewProps> = (props) => {
  const { t } = props
  const currentStore = useMemo(() => container.get(GroupItemStore), [])
  const currentState = currentStore.state
  const [form] = Form.useForm<GroupItem>()
  const [iconUrl, setIconUrl] = React.useState<string | undefined>()
  const inputRef = useRef()

  const isAdmin = props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0 ? true : false
  const isSuperAdmin = props.identity.roles && props.identity.roles.filter((o) => o.includes('God')).length != 0 

  const [editIdentityVisible, setEditIdentityVisible] = useState(false)
  const [addTenantVisible, SetAddTenantVisible] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState<any>(undefined)
  const [data, setData] = useState<TenantItem[]>([])
  const [initialValues, setInitialValues] = useState<GroupItem | undefined>()
  const [isUsersGroup, setisUsersGroup] = useState(false)
  const entityStore = container.get<TenantIdentityGroupStore>(TenantIdentityGroupStore)

  useEffect(
    () => {
      load()
    }, [inputRef]
  )

  const loadTenants = async () => {
    const entity = await entityStore.Get(props.match.params.id)
    setData(entity.tenants)
  }
  const load = async () => {
    currentStore.clear();
    await currentStore.load(props.match.params.id)
    setInitialValues(currentState.item.get())
    loadTenants()
    setisUsersGroup(currentState.item.value.name == "Users")
  }
  const onUpdate = async () => {
    let item: GroupItem
    try {
      item = (await form.validateFields()) as GroupItem
      item.areaId = (item.areaId as any).id || item.areaId
      notification['success']({
        message: t("Changes have been saved"),
        icon: <CheckOutlined style={{ color: '#108ee9' }} />,
      });
    } catch {
      return
    }
    let response = await currentStore.update(props.match.params.id, item)
    if (response) {
    }
  }

  const onCloseAddTenant = (selectedTenantId: any, close: boolean) => {
    SetAddTenantVisible(false)
    if (close) {
      setSelectedTenant(selectedTenantId)
      setEditIdentityVisible(true)
    }


  }

  return <>
    <ContentTitle type={t("Group")} title={initialValues?.name} />
    <Row style={{ padding : 10 }} gutter={16}>
      <Col span={10}>
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <Card title={t("General properties")} style={{ width: "100%" }}>
            {initialValues && <Form form={form} layout="vertical" initialValues={initialValues}>

              <FormItem 
                name='name'>
                <FloatInput label={t("Name")} readOnly placeholder={t("Name")} />
              </FormItem>
              <FormItem label={t("Description")}
                name="description"
              >
                <FloatTextArea placeholder={t("Description")} />
              </FormItem>
              <FormItem  name={'areaId'} rules={[{ required: true, message: t('Area required') }]}>
                <AreaEditor required label={t("Area")}  valueAsItemReference />
              </FormItem>
              <div
                style={{
                  textAlign: 'right'
                }}
              >
                <Button onClick={() => { form.resetFields() }} style={{ marginRight: 8 }}>
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit" onClick={() => onUpdate()} loading={currentState.isBusy?.value} >
                  {t('Save')}
                </Button>
              </div>
            </Form>
            }
          </Card>
        </Row>
        <Row gutter={16}>
          <Card title={t("")} style={{ minHeight: 400, width: "100%" }} >
            <Card title={t("Tenants")} bordered={true}>
              <span>
                {data.any(e => e.id == undefined) &&
                  <Tag
                    onClick={() => {
                      setSelectedTenant(undefined)
                      setEditIdentityVisible(true)

                    }}
                    style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={data.any(e => e.id == null) ? "#0000a4" : "seagreen"}>
                    {t("Global")} </Tag>}
                {data.filter(e => e.id != undefined).map(e =>

                  <Tag
                    onClick={() => {
                      setSelectedTenant(e.id)
                      setEditIdentityVisible(true)

                    }}
                    style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={e.id ? "seagreen" : "#0000a4"}>
                    {e.id ? !e.title || e.title.length === 0 ? t("(Empty Name)") : e.title : t("Global")}
                  </Tag>
                              )}
                                  <Tooltip placement="topRight" title={t('Add Tenant Roles')}>
                                  <Button hidden={!isSuperAdmin && isUsersGroup && data.any(e => e.id == undefined)}
                                          onClick={() => {
                                              SetAddTenantVisible(true)

                                          }}

                                          type="primary" icon={<PlusOutlined />} />
                                  </Tooltip>
              </span>
              {editIdentityVisible && (
                <EditMemberDialog
                  readonly={!isSuperAdmin && isUsersGroup}
                  identityId={props.match.params.id}
                  entityId={selectedTenant}
                  isGroup
                  onClose={() => {
                    setEditIdentityVisible(false)
                    loadTenants()
                  }}
                  {...props}
                />
              )}

              {addTenantVisible && (
                <AddTenantDialog
                  isAdmin={isAdmin}
                  onClose={onCloseAddTenant}
                  {...props}
                />
              )}
            </Card>
          </Card>
        </Row>
      </Col>
      <Col span={14}>
        <Card title={t("Members")} style={{ minHeight: 700 }}>
          <GroupUsersView hideGroup id={props.match.params.id} />
        
        </Card>
      </Col>
    </Row>
  </>
}

export default withIdentity( withRouter(withTranslation()(GroupView) as any) as any)
