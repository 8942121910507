export default {
  "modifiedDate": "2019-02-19T14:47:14.034Z",
  "source": "en",
  "targets": [
    "es",
    "fr",
    "pt",
    "pt-br",
    "it",
    "de"
  ],
  "data": {
    "en": {
      "Request failed with status code 403": "You dont have permission to perform this action",
      "data-delete-info": "(article 17 of the RGPD «Right to erasure (» right to be forgotten «)»): if you no longer want the Fi group to process your data, withdraw your consent, as well as any link, copy or reproduction of your data.",
      "data-portability-info": "(article 20 of the RGPD «Right to data portability»): it is the act of requesting the export of your data and transferring it to another company.",
      "data-rectification-info": "(article 16 of the RGPD «Right of rectification»): the case in which you consider that some of your data is inaccurate or incomplete and request that they be updated as soon as possible.",
      "denial-profiling-info": "(article 22 of the RGPD «Automated individual decision, including profiling»): this means requesting not to be the subject of an automated individual decision.",
      "limitation-treatment-info": "(article 18 of the RGPD «Right to limitation of treatment»): the case in which you want to be able to isolate your data so that it is only kept temporarily so that your data cannot, except for retention, will be processed only with your consent.",
      "object-data-info": "(article 21 of the RGPD «Right to object»): this is the act of stopping all processing of your data under certain conditions, namely the processing necessary for the performance of a public service mission by the controller, or the treatment necessary for the purposes of the legitimate interests pursued by the controller or a third party, or the case in which you no longer want your personal data to be processed for commercial prospecting purposes.",
      "rights-intro": "In accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of individuals with regard to the processing of personal data and the free circulation of such data, you have several rights over your data that complement the right to information allowing you to be informed of the collection of your data and what happens to them.",
      "personal-data-info": "(article 15 of the GDPR «Right of access of the data subject»): if you want to know whether or not Fi group is processing your personal data, if so, obtain a copy and the details of the processing.",
      "UserLocked": "User Locked",
      "Username": "User name",
      "Tenantname": "Tenant name",
      "Include Users Global": "Include Users Global",
      "Error validating TwoAuthentification token for invalid code":"Invalid code",
      "User locked. Draft User!": "User pending approval.",
      "The user {user} has been permanently blocked and must be unblocked by an administrator. {date}": "The user {user} has been permanently blocked and must be unblocked by an administrator. {date}",
      "The user {user} has reached the maximum number of login attempts (5) for the first time and his account has been temporarily locked for 5 minutes. {date}": "The user {user} has reached the maximum number of login attempts (5) for the first time and his account has been temporarily locked for 5 minutes. {date}",
      "User {user} has reached the maximum number of login attempts (5) for the second time and his account has been temporarily locked for 5 minutes. {date}": "User {user} has reached the maximum number of login attempts (5) for the second time and his account has been temporarily locked for 5 minutes. {date}",
      "Invalid or expired token": "Invalid or expired login link. Please contact your FI Group contact or your Time Tracking administrator to have one resent.",
      "Service Manager": "Customer Service Manager",

    },
    "es": {
      "Remove this app from your favorites":"Elimina esta aplicación de tus favoritas",
      "Service Manager": "Responsable Servicio Cliente",
      "You dont have access to any Tenant. ": "No tienes acceso a ningún Tenant. ",
      "Refresh page":"Recargar página",
      "My Clients": "Mis clientes",
      "Are you sure that you want to discard these changes?":"¿Está seguro de que desea descartar estos cambios?",
      "Show hierarchy" : "Mostrar jerarquía",
      "Customized?" : "¿Personalizado?", 
      "Tenants exported successfully" : "Tenants exportados correctamente",
      "Export Tenants Success": "Tenants exportados correctamente",
      "Export Member Success": "Miembros exportados correctamente",
      "Download File":"Descargar fichero",
      "Select Area...": "Seleccione Área...",
      "Select Creation type...": "Seleccione tipo de creación...",
      "Creation": "Creación",
      "Auto": "Auto",
      "Manual": "Manual",
      "Send" : "Enviar",
      "Show only favorites applications":"Mostrar solo aplicaciones favoritas",
      "Sort applications":"Ordenar aplicaciones",
      "Add this app to your favorites":"Agrega esta aplicación a tus favoritas",
      "Are you sure to revoke this access?": "¿Estás seguro de revocar este acceso?",
      "Choose OK to Login and continue...": "Elija OK para iniciar sesión y continuar...",
      "Your session has expired.": "Su sesión ha caducado.",
      "FI-Group password change": "FI-Group Cambio de contraseña",
      "Invitation FI GROUP": "Invitación FI GROUP",
      "Welcome to FI GROUP": " Bienvenidos a FI GROUP",
      "FI Connect is a digital applications HUB created by FI Group that seeks to transform and optimize the relationship we have with our customers.": "FI Connect es un HUB de aplicaciones digitales creado por FI Group que busca transformar y optimizar la relación que tenemos con nuestros clientes.",
      "Please confirm your invitation": "Por favor, confirme su invitación de acceso",
      "Operation Completed Successfully": "Operación completada con éxito",
      "Your operation will be processed in background.": "Su operación será completada en segundo plano.",
      "Is Owner": "Propietario",
      "Are you sure to revoke all user Access?": "¿Estás seguro de revocar todo el acceso de usuario?",
      "Global roles": "Roles globales",
      "Group's Users": "Usuarios del grupo",
      "Add Tenant Roles": "Agregar roles de Tenant",
      "(Empty Email)": "(Email vacío)",
      "(Empty Name)": "Nombre vacío",
      "Clear All": "Borrar Todas",
      "Add Member to Tenant": "Agregar miembro al Tenant",
      "elements deleted sussessful": "elementos eliminados con éxito",
      "Delete elements": "Eliminar elementos",
      "Identity Tenants": "Tenants de Identidad",
      "Apply to this entity and all its descendants": "Aplicar a esta entidad y todos sus descendientes",
      "Start typing to search for applications...": "Comience a escribir para buscar aplicaciones...",
      "Add new user role": "Agregar nuevo rol de usuario",
      "Group Name": "Nombre del grupo",
      "Entities": "Entidades",
      "European VAT": "IVA Europeo",
      "Ok": "Aceptar",
      "This file will be processed in the background.": "Este archivo se procesará en segundo plano",
      "Upload Successful": "Carga exitosa",
      "New Notification": "Nueva Notificación",
      "Upload complete": "Carga completa",
      "Identities": "Identidades",
      "Add member to Group": "Agregar miembro al grupo",
      "Group Details": "Detalles del grupo",
      "Group Users": "Usuarios del grupo",
      "Add Tenant Roles to User": "Agregar roles de Tenant al usuario",
      "Add Tenant Roles to Group": "Agregar roles de Tenant al grupo",
      "Tenant": "Tenant",
      "Global": "Global",
      "Hierarchy": "Jerarquía",
      "Parent": "Padre",
      "View Number": "Número de vista",
      "New Entity": "Nueva Entidad",
      "Please enter a title": "Ingrese un título, por favor",
      "Area is requiered": "Se requiere área",
      "Select entity": "Seleccione una entidad",
      "Tax Number": "Número de impuesto",
      "Change Parent Tenant": "Cambiar Tenant padre",
      "Edit": "Editar",
      "Display Name": "Nombre para mostrar",
      "Inheritance": "Herencia",
      "Inherited": "Heredado",
      "Tenant Details": "Detalles del Tenant",
      "Terms of service": "Condiciones de uso",
      "Job Title": "Cargo",
      "Manager": "Gerente",
      "Teams": "Equipos",
      "(2MB size limit)": "(Límite de tamaño de 2 MB)",
      "2MB files size limit exceeded": "Se superó el límite de tamaño de archivos de 2MB",
      "Access is denied": "Acceso denegado",
      "Add new": "Agregar nuevo elemento",
      "Already exists an app with this app id": "Ya existe una aplicación con este Id de aplicación",
      "Already exists an app with this app id and name": "Ya existe una aplicación con este Id de aplicación y nombre",
      "Already exists an app with this name": "Ya existe una aplicación con este nombre",
      "An error ocurred": "Ha ocurrido un error",
      "API Resources": "Recursos API",
      "App URI": "URI de Aplicación",
      "Application Id": "Id de la aplicación",
      "Application Id must have less than 200 chars.": "Id de Aplicación debe tener menos de 200 caracteres.",
      "Application name": "Nombre de la aplicación",
      "Application not found": "Aplicación no encontrada",
      "Application required": "Aplicación requerida",
      "Application shared secret": "Llave secreta de aplicación",
      "Application": "Aplicación",
      "Applications": "Aplicaciones",
      "Applications which is a member": "Aplicaciones de las que es miembro",
      "Are you sure you want to change this entity parent?": "¿Está seguro que desea cambiar el padre de esta entidad?",
      "This action can affect users permissions": "Esta acción puede afectar los permisos de los usuarios.",
      "Are you sure want to delete selected items?": "¿Está seguro que desea eliminar los elementos seleccionados?",
      "Are you sure want to reset your password? An email will be sent to": "¿Está seguro de que desea restablecer su contraseña? Se le enviará un correo a",
      "Attach to this application a copy of your identification, or where appropriate, a copy of the order and identification of the representative": "Adjunte a la presente solicitud una copia de su identificación, o cuando sea apropiado, una copia de la orden y la identificación del representante",
      "Base Application": "Aplicación base",
      "Base role": "Rol base",
      "Basic info": "Información básica",
      "Bulk upload": "Carga masiva",
      "Bulk upload items": "Carga masiva",
      "Bulk Upload Options": "Opciones de Carga masiva",
      "Bulk Insert Users": "Insertar Usuarios en masa",
      "Bulk Insert Users's Role": "Insertar Roles de usuarios en masa",
      "Uploaded User'Roles have been created/updated": "Se han creado/actualizado los roles de usuario cargados ",
      "Uploaded Group'Roles have been created/updated": "Se han creado/actualizado los roles de grupo cargados ",
      "Uploaded User have been created/updated": "Se han creado/actualizado los usuarios cargados ",
      "Bulk Insert Users's Roles": "Insertar Roles de usuarios en masa",
      "Bulk Insert Groups's Roles": "Insertar Roles de grupos en masa",
      "Cancel": "Cancelar",
      "Cannot reset password at this stage": "No se puede restablecer la contraseña en esta etapa",
      "Cargando": "Cargando",
      "Change password": "Cambiar la contraseña",
      "Check User Permissions": "Verificar permisos de usuario",
      "Click or drag file to this area to upload": "Haga clic o arrastre el archivo a esta área para cargar",
      "Click or drag file to update the app picture": "Haga clic o arrastre un archivo para actulizar la imagen de la aplicación",
      "Click or drag file to update your profile picture": "Haga clic o arrastre un archivo para actualizar su foto de perfil",
      "Close session": "Cerrar sesión",
      "Configure clients, workgroups and companies": "Configurar clientes, grupos de trabajo y empresas",
      "Confirm password": "Confirmar contraseña",
      "Confirmation required": "Confirmación requerida",
      "Cookies Policy": "Política de cookies",
      "Copyright": "Derechos de autor",
      "Copy to clipboard": "Copiar al portapapeles",
      "Created on": "Creado el",
      "Create Password": "Crear contraseña",
      "data-delete-info": "(artículo 17 del RGPD «Derecho de eliminación (» derecho al olvido «)»): si ya no desea que Fi group procese sus datos, retire su consentimiento, así como cualquier enlace, copia o reproducción de sus datos.",
      "data-portability-info": "(artículo 20 del RGPD «Derecho a la portabilidad de datos»): es el acto de solicitar la exportación de sus datos y transferirlos a otra empresa.",
      "data-rectification-info": "(artículo 16 del RGPD «Derecho de rectificación»): el caso en el que considere que algunos de sus datos son inexactos o incompletos y solicite que se actualicen a la mayor brevedad.",
      "Data portability request": "Solicitud de portabilidad de datos",
      "Data Subject Right": "Tratamientos de datos",
      "Delete selected": "Eliminar elementos seleccionados",
      "denial-profiling-info": "(artículo 22 del RGPD «Decisión individual automatizada, incluida la elaboración de perfiles»): esto significa solicitar no ser objeto de una decisión individual automatizada.",
      "Description": "Descripción",
      "Description must have less than 1000 chars.": "Descripción debe tener menos de 1000 caracteres.",
      "Direct request": "Solicitud directa",
      "Display name": "Nombre",
      "Display name must have less than 200 chars.": "Nombre debe tener menos de 200 caracteres.",
      "Display name must have less than 256 chars.": "Nombre debe tener menos de 256 caracteres.",
      "Done": "Listo",
      "Download import template": "Descargar modelo de importación",
      "Download template": "Descargar plantilla",
      "Do you want to access these applications? Ask us!": "¿Quieres acceder a estas aplicaciones? ¡Consúltanos!",
      "Each URI must have 2000 chars max.": "Cada URI debe tener 2000 caracteres como máximo.",
      "El inicio de sesión ha terminado con éxito": "El inicio de sesión ha terminado con éxito",
      "Email missing": "Falta el email",
      "Email": "Email",
      "Email required": "Email requerido",
      "Enable the application": "Habilitar la aplicación",
      "Enabled": "Habilitada",
      "English": "Inglés",
      "Enter your password": "Introduzca su contraseña",
      "Enter your username": "Introduzca su nombre de usuario",
      "Error": "Error",
      "Errors": "Errores",
      "Exercise rights": "Ejercer derechos",
      "EXERCISE YOUR RIGHTS": "EJERCER DERECHOS",
      "Expires at": "Expira el",
      "Export to Excel": "Exportar a Excel",
      "Facebook account": "Cuenta de Facebook",
      "FI Group all rights reserved": "FI Group, todos los derechos reservados",
      "Fi Group users have to connect through Office 365 professional account": "Los usuarios de Fi Group deben conectarse a través de una cuenta profesional de Office 365",
      "Filter by application": "Filtrar por aplicación",
      "First name": "Nombre",
      "Forgot your password?": "¿Ha olvidado su contraseña?",
      "French": "Francés",
      "Full name": "Nombre completo",
      "General properties": "Propiedades generales",
      "Google account": "Cuenta de Google",
      "Grant search service": "Servicio de búsqueda de subvenciones",
      "Grant": "Subvención",
      "Grants": "Subvenciones",
      "Group is required": "El grupo es requerido",
      "Group required": "Grupo requerido",
      "Group roles": "Roles del grupo",
      "Group": "Grupo",
      "Group name": "Nombre del grupo",
      "Groups": "Grupos",
      "Groups assigned": "Grupos asignados",
      "Hide in user List": "Ocultar en lista de usuarios",
      "Icon (Ant Icon, URL or base64 image)": "Icono (Ant Icon, URL o imagen base64)",
      "Icon": "Icono",
      "Identity management": "Identity management",
      "Incorrect username or password": "Nombre de usuario o contraseña incorrectos",
      "Invalid preferred language": "Idioma preferido inválido",
      "Invalit token.": "Token inválido.",
      "Italian": "Italiano",
      "In case of being requested by representation, please indicate the representative's data": "En caso de ser solicitada por representación, por favor indicar los datos del representante",
      "Log in": "Iniciar sesión",
      "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna": "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna",
      "Import template": "Modelo de importación",
      "Input search text...": "Texto de búsqueda...",
      "Introduzca su contraseña": "Introduzca su contraseña",
      "Introduzca su nombre de usuario": "Introduzca su nombre de usuario",
      "Invalid email address.": "Dirección de email inválida.",
      "It seems that you dont have access to any application. Please contact your administartor": "Al parecer no tiene acceso a ninguna aplicación. Por favor, contacte a su administrador",
      "Last name": "Apellidos",
      "limitation-treatment-info": "(artículo 18 del RGPD «Derecho a la limitación del tratamiento»): el caso en el que desee poder aislar sus datos para que solo se conserven temporalmente de modo que sus datos no puedan, a excepción de la retención, se procesará solo con su consentimiento.",
      "Loading": "Cargando",
      "Local User": "Usuario local",
      "Login": "Iniciar sesión",
      "Login has successfully ended": "El inicio de sesión ha terminado con éxito",
      "Manage your R&D claims with this application": "Gestione sus solicitudes de I+D con esta aplicación",
      "Manages applications, users, groups and permissions": "Administrar applicaciones, usuarios, grupos y permisos",
      "Manages receipts & taxes": "Gestione recibos e impuestos",
      "Manual assigned roles": "Roles asignados manualmente",
      "Member of": "Miembro de",
      "Members": "Miembros",
      "Modified on": "Modificado en",
      "My data": "Mis datos",
      "My profile": "Mi perfil",
      "Name and surname of the interested": "Nombre y apellidos del interesado",
      "Name is required": "El nombre es requerido",
      "Name": "Nombre",
      "New application": "Nueva aplicación",
      "New group": "Nuevo grupo",
      "New group's scope": "Nuevo ámbito del grupo",
      "New password": "Nueva contraseña",
      "New Permission": "Nuevo permiso",
      "New Personal Access Token (PAT)": "Nuevo Token de Acceso Personal (TAP)",
      "New role": "Nuevo rol",
      "New role's scope": "Nuevo ámbito del rol",
      "New user": "Nuevo usuario",
      "No": "No",
      "No data": "No hay datos",
      "No groups found for this user": "No se han encontrado grupos para este usuario",
      "No permissions found for this user": "No se han encontrado permisos para este usuario",
      "Notifications": "Notificaciones",
      "Open Result": "Abrir Resultados",
      "No Notifications": "No hay notificaciones",
      "object-data-info": "(artículo 21 del RGPD «Derecho a oponerse»): este es el acto de detener todo procesamiento de sus datos bajo ciertas condiciones, a saber, el procesamiento necesario para el desempeño de una misión de servicio público por parte del controlador, o el tratamiento necesario para los fines de los intereses legítimos perseguidos por el responsable del tratamiento o un tercero, o el caso en el que ya no desee que sus datos personales sean tratados con fines de prospección comercial.",
      "O cree una cuenta local": "O cree una cuenta local",
      "Or create a local account": "O cree una cuenta local",
      "Or the page may not be found ...": "O puede que no se encuentre la página ...",
      "Or with the following providers": "O con los siguientes proveedores",
      "Owners": "Dueño",
      "Page not found": "Página no encontrada",
      "Password": "Contraseña",
      "Passwords don't match": "Las contraseñas no coinciden",
      "Password has been successfully reseted": "La contraseña se ha cambiado con éxito",
      "Passwords must be at least 6 characters.": "Las contraseñas deben tener al menos 6 caracteres.",
      "Passwords must have at least one non alphanumeric character.": "Las contraseñas deben tener al menos un carácter no alfanumérico.",
      "Passwords must have at least one digit ('0'-'9').": "Las contraseñas deben tener al menos un dígito ('0'-'9').",
      "Passwords must have at least one lowercase ('a'-'z').": "Las contraseñas deben tener al menos una minúscula ('a'-'z').",
      "Passwords must have at least one uppercase ('A'-'Z').": "Las contraseñas deben tener al menos una mayúscula ('A'-'Z').",      
      "PAT copied to clipboard!": "TAP copiado al portapapeles!",
      "Permission name": "Nombre",
      "Permission": "Permiso",
      "BulkInsert": "Inserción masiva",
      "Permissions": "Permisos",
      "Permissions assigned": "Permisos asignados",
      "Permission display name": "Nombre del permiso",
      "Personal Access Token (PAT) Created": "Token de Acceso Personal (TAP) Creado",
      "Personal Access Tokens": "Tokens de Acceso Personal",
      "personal-data-info": "(artículo 15 del RGPD «Derecho de acceso del interesado»): si desea saber si Fi group está procesando o no sus datos personales, en caso afirmativo, obtener una copia y el detalles de los tratamientos.",
      "Personal data stored for the user: {0}": "Datos personales almacenados para el usuario: {0}",
      "Please add the required documents!": "¡Por favor, agregue los documentos requeridos!",
      "Please check at least one right to exercise!": "¡Por favor, marque al menos un derecho a ejercer!",
      "Please check your email inbox. We have sent instructions on how reset your password.": "Por favor, verifique su bandeja de entrada. Hemos enviado instrucciones sobre cómo restablecer su contraseña.",
      "Please confirm your password!": "¡Por favor, confirme su contraseña!",
      "Please enter the scope!": "¡Por favor, introduzca el ámbito!",
      "Please enter your email before continue with the password reset.": "Por favor, introduzca el email antes de continuar con el restablecimiento de la contraseña.",
      "Please indicate if it is a direct request or through a representative": "Por favor, indique si se trata de una solicitud directa o a través de un representante",
      "Please input the application description!": "¡Por favor, introduzca la descripción de la aplicación!",
      "Please input the application display name!": "¡Por favor, introduzca el nombre de la aplicación!",
      "Please input the application private URL!": "¡Por favor, introduzca la URL privada de la aplicación!",
      "Please input the application redirect URIs!": "¡Por favor, introduzca las URLs de redireccionamiento de la aplicación!",
      "Please input the email!": "Por favor, introduzca el correo electrónico!",
      "Please input the name of the interested!": "¡Por favor, introduzca el nombre del interesado!",
      "Please input the new password!": "¡Por favor entre la nueva contraseña!",
      "Please input the password confirmation!": "¡Por favor entre la confimación de la contraseña!",
      "Please input the permission name!": "¡Por favor entre el nombre del permiso!",
      "Please input the permission!": "¡Por favor entre el permiso!",
      "Please input the request date!": "¡Por favor ingrese la fecha de la solicitud!",
      "Please input your API title!": "¡Por favor, introduzca el título de su API!",
      "Please input your application display name!": "¡Por favor, introduzca el nombre de su aplicación!",
      "Please input your application id!": "¡Por favor, introduzca el identificador de su aplicación!",
      "Please input your application name!": "¡Por favor, introduzca el nombre de su aplicación!",
      "Please input your email!": "¡Por favor, introduzca su email!",
      "Please input your first name!": "¡Por favor, introduzca su nombre!",
      "Please input your group name!": "¡Por favor, introduzca el nombre de su grupo!",
      "Please input your last name!": "¡Por favor, introduzca sus apellidos!",
      "Please input your password!": "¡Por favor, introduzca su contraseña!",
      "Please input your PAT's name!": "¡Por favor introduzca el nombre de su TAP!",
      "Please input your redirect URIs!": "¡Por favor, introduzca las URLs de redireccionamiento de su aplicación!",
      "Please input the request type!": "¡Por favor ingrese el tipo de solicitud!",
      "Please input your username!": "¡Por favor, introduzca su nombre de usuario!",
      "Please select a user to check permissions": "Por favor, seleccione un usuario para verificar permisos",
      "Please select your PAT's Application!": "¡Por favor seleccione la aplicación de su TAP!",
      "Please select your preferred language!": "¡Por favor, seleccione su idioma preferido!",
      "Please type a valid email!": "¡Por favor, escriba un correo electrónico válido!",
      "Please enter your password. Remember that the login name is your email address." : "Introduzca su contraseña. Recuerde que el nombre de usuario es su dirección de correo electrónico.",
      "Invalid or expired token":"Enlace de conexión no válido o caducado. Póngase en contacto con la persona de contacto de su grupo FI o con el administrador de Time Tracking para que le envíen uno nuevo.",
      "Portal settings": "Configuración del portal",
      "Portuguese": "Portugués",
      "Portuguese (PT)": "Portugués (PT)",
      "Portuguese (BR)": "Portugués (BR)",
      "Preferred language": "Idioma preferido",
      "Preferred language required": "Idioma preferido requerido",
      "Private url": "URI privada",
      "Problems to access? Contact Us!": "¿Tienes problemas para acceder? ¡Contáctanos!",
      "Professional account (Office 365)": "Cuenta profesional (Office 365)",
      "Profile": "Perfil",
      "Public URI": "URI pública",
      "Remember me": "Recordarme",
      "Redirect URIs": "URIs de redireccionamiento",
      "Re-enter your password": "Repetir contraseña",
      "Request failed with status code 403": "No tiene permiso para realizar esta acción",
      "Reset your password": "Restablezca su contraseña",
      "Restrict client access by scope": "Restringir el acceso del cliente por ámbito",
      "Restricted": "Restringido",
      "Right of access": "Derecho de acceso",
      "Right to be informed (that is, to obtain a copy of the same that may be in the possession of the company)": "Derecho a ser informado (es decir, a obtener una copia de la misma que pueda estar en poder de la empresa)",
      "Right to data portability": "Derecho a la portabilidad de los datos",
      "Right to erasure (right to be forgotten)": "Derecho de supresión (derecho al olvido)",
      "Right to object to treatment": "Derecho de oposición al tratamiento",
      "Right to rectification (that is, to correct personal data, or to complete incomplete data)": "Derecho a la rectificación (es decir, a corregir los datos personales, o a completar los datos incompletos)",
      "RIGHTS AND EXERCISE OF THE INTERESTED PARTIES": "DERECHOS Y EJERCICIO DE LOS INTERESADOS",
      "rights-intro": "De acuerdo con las disposiciones del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo sobre la protección de las personas con respecto al procesamiento de datos personales y a la libre circulación de dichos datos, usted tiene varios derechos sobre su datos que complementan el derecho a la información permitiéndole estar informado de la recogida de sus datos y de lo que les ocurre.",
      "Representative data": "Datos del representante",
      "Request date": "Fecha de la solicitud",
      "Request details": "Detalles de la solicitud",
      "Request for access to your personal data": "Solicitud de acceso a sus datos personales",
      "Request for limitation of treatment": "Solicitud de limitación del tratamiento",
      "Request for rectification of your personal data": "Solicitud de rectificación de sus datos personales",
      "Request for denial of profiling": "Solicitud de denegación de elaboración de perfiles",
      "Request sent successfully": "Solicitud enviada con éxito",
      "Request to delete your personal data": "Solicitud de eliminación de sus datos personales",
      "Request to object to data processing": "Solicitud de oposición al procesamiento de datos",
      "Request through a representative": "Solicitar a través de un representante",
      "Role access scopes": "Ámbitos del acceso de roles",
      "Role is required": "El rol es requerido",
      "Role required": "Rol requerido",
      "Role": "Rol",
      "Role name": "Nombre del rol",
      "Role not found or it is not available for selected application": "Rol no encontrado o no está disponible para la aplicación seleccionada",
      "Roles": "Roles",
      "Roles assigned": "Roles asignados",
      "Row": "Fila",
      "Save": "Guardar",
      "Save this string elsewhere because it will not be shown again": "Guarde este valor en algún otro lugar porque no se mostrará nuevamente",
      "Scope is required": "El ámbito es requerido",
      "Scope": "Ámbito",
      "Select a user to check": "Seleccione un usuario",
      "Select another authentication method": "Seleccionar otro método de autenticación",
      "Select request type": "Seleccione el tipo de solicitud",
      "Send email invite": "Enviar invitación por correo electrónico",
      "Shared secret": "Llave secreta",
      "Sign in using your Office 365 account if you have any": "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna",
      "Spanish": "Español",
      "Successful action": "Acción exitosa",
      "Terms of Use": "Términos y condiciones",
      "The expiration date must be greater than today's date": "La fecha de vencimiento debe ser mayor que la fecha actual",
      "The passwords you entered do not match": "Las contraseñas que escribió no coinciden",
      "This appplication is in PRE-RELEASE mode": "Esta aplicación esta en modo PRE-RELEASE",
      "This is your newly created PAT. Save it carefuly since once you close this dialog, you will not see the token anymore": "Esta es su TAP recién creada. Guárdelo con cuidado ya que una vez que cierre este diálogo, no volverá a ver el token",
      "to": "a",
      "To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right": "Para ayudarnos a procesar su solicitud de manera eficiente, sírvase proporcionar la mayor cantidad de detalles posibles sobre los datos personales que solicita para ejercer su derecho",
      "To create a local account you need to define a password. Your username will be your email.": "Para crear una cuenta local es necesario que defina una contraseña. Su nombre de usuario será su correo electrónico.",
      "Total elements": "Total de elementos",
      "Total imported": "Total importados",
      "Upload completed": "Carga completada",
      "Download errors file": "Descargar fichero de errores",
      "Use an excel template to easily import": "Use un modelo de excel para importar facilmente",
      "Use an Excel template to easily import your data": "Use un modelo de excel para importar facilmente sus datos",
      "User is required": "El usuario es requerido",
      "User Management": "Gestión de Usuarios",
      "User name": "Nombre de usuario",
      "Tenant name": "Nombre del Tenant",
      "Operation": "Operación",
      "User Permissions": "Permisos de usuario",
      "User required": "Usuario requerido",
      "User": "Usuario",
      "Username": "Nombre de usuario",
      "Username is required": "El nombre de usuario es requerido",
      "Username required": "Nombre de usuario requerido",
      "Users": "Usuarios",
      "Local user": "Usuario local",
      "Usuario": "Usuario",
      "View progress and goals achieved by your companies.": "Ver progresos y metas alcanzadas en sus empresas",
      "Vuelva a introducir su contraseña": "Repetir contraseña",
      "What right do you want to exercise?": "¿Qué derecho desea ejercer?",
      "We help you import your information in an agile way": "Te ayudamos a importar tu información de forma ágil",
      "We no longer know where to put so much innovation!!": "¡¡Ya no sabemos donde meter tantísima innovación!!",
      "Welcome {0}!": "Bienvenido/a {0}!",
      "Welcome": "Bienvenido/a",
      "Welcome to": "Bienvenido/a a",
      "(without description)": "(sin descripción)",
      "(without name)": "(sin noombre)",
      "Yes": "Sí",
      "You can add more roles later from the user edit screen.": "Puede agregar más roles posteriormente a través de la pantalla de edición",
      "You dont have access to this resource. You might not have the required permissions. Please, contact your administrator for more information.": "No tiene acceso a este recurso. No debe tener los permisos necesarios. Por favor, contacte a su administrador para más información",
      "You dont have permission to obtain a PAT token for this application": "No tiene permiso para obtener un token PAT para esta aplicación",
      "Your information": "Su información",
      "Your picture": "Su foto",
      "Your Profile": "Su perfil",
      "Policies": "Políticas",
      "Policy Body": "Cuerpo de la política",
      "Areas": "Áreas",
      "Area": "Área",
      "Is Default Area": "Es Área Predeterminada",
      "Is System Area": "Es Área del Sistema",
      "Tenants": "Tenants",
      "Helpdesk": "Helpdesk",
      "Title": "Título",
      "Changes have been saved": "Los cambios han sido guardados",
      "Export": "Exportar",
      "New Policy": "Nueva Política",
      "Edit Policy": "Editar Política",
      "Please input your policy title!":"¡Ingrese el título de su política!",
      "Policy Id must have less than 200 chars.":"El ID de la política debe tener menos de 200 caracteres.",
      "Logging in": "Iniciando sesión",
      "OK": "Acept",
      "My disclaimers": "Mis Descargos de responsabilidad",
      "Disclaimers": "Descargo de responsabilidad",
      "Accept": "Aceptar",
      "Reason": "Razón",
      "Date": "Fecha",
      "Login Result": "Resultado de inicio de sesión",
      "Authentication Provider": "Proveedor de autenticación",
      "Success": "Exitoso",
      "Failure": "Fallido",
      "UserLocked": "Usuario bloqueado",
      "Select Login Result...": "Seleccionar resultado de inicio de sesión...",
      "Select Authentication Provider...": "Seleccionar proveedor de autenticación...",
      "Select Operation...": "Seleccionar operación...",
      "Select User...": "Seleccionar usuario...",
      "Select Tenant...": "Seleccionar tenant...",
      "Application Name": "Nombre de la aplicación",
      "Search into Metadata?": "¿Buscar en metadatos?",
      "Authentication Logs": "Registros de autenticación",
      "System Logs": "Registros del sistema",
      "Session Logs": "Registros de sesión",
      "Login Success": "Acceso exitoso",
      "User Locked": "Usuario bloqueado",
      "Invalid credentials": "Credenciales no válidas",
      "Unknown error" : "Error desconocido",
      "Please verify your email.": "Por favor verifica tu correo electrónico.",
      "Include Users Global": "Incluir usuarios globales",
      "Locked": "Bloqueado",
      "Unlocked": "Desbloqueado",
      "Status": "Estado",
      "The user": "El usuario",
      "has been unlocked": "ha sido desbloqueado",
      "User Unlocked":"Usuario desbloqueado",
      "User Locked Permanently": "Usuario bloqueado permanentemente",
      "Permanent lock":"Bloqueo Permanente",
      "Your password has expired, please create a new password to login.": "Tu contraseña ha expirado, por favor crea una nueva contraseña para ingresar.",
      "Group Alias": "Alias Grupo",
      "Bulk Insert Group members" : "Carga masiva de miembros del Grupo",
      "Applied":"Aprobado",
      "Waiting for Approver":"Esperando aprobación",
      "Cancelled":"Cancelado",
      "Rejected":"Rechazado",
      "RollBack":"Retroceder",
      "Incorrect username or password, you have 1 attempts left or your account will be locked" : "Credenciales incorrectas, le queda 1 intento o su cuenta será bloqueada",
      "Incorrect username or password, you have 2 attempts left or your account will be locked" : "Credenciales incorrectas, le quedan 2 intentos o su cuenta será bloqueada",
      "Incorrect username or password, you have 3 attempts left or your account will be locked" : "Credenciales incorrectas, le quedan 3 intentos o su cuenta será bloqueada",
      "Incorrect username or password, you have 4 attempts left or your account will be locked" : "Credenciales incorrectas, le quedan 4 intentos o su cuenta será bloqueada",
      "Incorrect username or password, you have 5 attempts left or your account will be locked" : "Credenciales incorrectas, le quedan 5 intentos o su cuenta será bloqueada",
      "User Is Locked Out": "Usuario bloqueado", 
      "User does not exist": "Usuario no existe",
      "Are you sure to disable the MFA factor?":"¿Está seguro de que desea de deshabilitar el modo MFA?",
      "Code expired!":"¡El código expiró!",
      "Resend Code":"Reenviar código",
      "A code was sent to your Email, please type code for login. The code will expire in 5 minutes":"Se envió un código a su correo electrónico, escriba el código para iniciar sesión. El código caducará en 5 minutos.",
      "Minutes":"Minutos",
      "Seconds":"Segundos", 
      "Submit": "Enviar",
      "Error validating TwoAuthentification token for invalid code":"Código incorrecto",
      "Hi, ":"Hola, ",
      "Cannot reset password, user is locked":"No se puede restablecer la contraseña, el usuario está bloqueado",
      "New User":"Nuevo usuario",
      "User Information":"Información del usuario",
      "User's Permissions":"Permisos",
      "Create Operation Request":"Solicitud de operación",
      "Operation Requests":"Solicitar operación",
      "Area required":"¡Por favor, introduzca el área!",
      "Work Position":"Cargo",
      "Phone Number":"Número de teléfono",
      "Departments":"Departamentos",
      "Save and exit":"Guardar y salir",
      "Next":"Siguiente",
      "Clients": "Clientes",
      "Result":"Resultado",
      "Provider":"Proveedor",
      "ReturnUrl":"URL",
      "User locked. Draft User!": "Usuario pendiente de aprobación.",
      "Access Requests": "Solicitudes de acceso",
      "You are validating access to FI Connect to users outside the company. Ensure that users must access the requested accounts and each application with the requested role": "Estás validando el acceso a FI Connect a usuarios externos a la compañía. Asegúrate de que los usuarios deben acceder a las cuentas solicitadas y a cada aplicación con el rol solicitado",
      "Are you sure you want to remove all permissions from all current tenants for this user?":"¿Está seguro que desea eliminar todos los permisos de todos los tenants actuales para este usuario?",
      "Bulk assignment of permissions to existing users of this Tenant":"Asignación masiva de permisos a usuarios existentes de este Tenant",
      "Bulk loading of users and permissions for this Tenant":"Carga masiva de usuarios y permisos para este Tenant",
      "Invalid action: No more Tenant roles can be added to the Users Group.": "Acción no válida: No se pueden añadir más roles Tenant al Grupo de Usuarios.",
      "You do not have access to Users group permission assignment": "Usted no tiene acceso a la asignación de permisos del grupo Users",
      "Global roles only": "Solo roles globales",
      "MFA Configuration": "MFC Configuración",
      "Running": "Ejecutando",
      "Stopped": "Detenido",
      "Maintenance": "Mantenimiento",
      "Connected per App last 30 minutes": "Conectados por App los últimos 30 minutos",
      "Users Connected": "Usuarios conectados",
      "Global Administrator per Areas": "Administrador global por área",
      "Global Administrator per App": "Administrador global por aplicación",
      "Global Administrators": "Administradores globales",
      "Login success per hour": "Éxito de inicio de sesión por hora",
      "Logs": "Registros",
      "Global Admin": "Administrador Global",
      "Polaris Permission Create": "Polaris Permiso de Crear",
      "Invitation mail sent successfully.": "Correo de invitación enviado correctamente.",
      "Failed to send invitation mail. Please try again later.": "No se ha podido enviar el correo de invitación. Vuelva a intentarlo más tarde.",
      "Select Api Entry...": "Seleccionar entrada API...",
      "Api Entry": "Entrada API",
      "Select a date...": "Seleccionar una fecha...",
      "Start date": "Fecha de inicio",
      "End date": "Fecha de finalización",
      "Access Per User. Date Range": "Acceso por usuario. Rango de fechas",
      "User Access": "Acceso de usuario",
      "Select a Time Range": "Seleccione un rango de tiempo",
      "The 'Identity' application cannot be deleted.": "La aplicación 'Identity' no se puede eliminar.",
      "The User is not the owner of the application and cannot delete it.": "El usuario no es el propietario de la aplicación y no puede eliminarla.",
      "Application deleted successfully.": "Aplicación eliminada con éxito.",
      "Failed to delete the application. Please try again later.": "No se pudo eliminar la aplicación. Por favor, inténtelo de nuevo más tarde.",
      "Image uploaded successfully": "Imagen cargada con éxito.",
      "At least 12 characters long.": "Al menos 12 caracteres.",
      "At least one uppercase letter, one lowercase letter, one number, and one symbol.": "Al menos: una letra mayúscula, una letra minúscula, un número y un símbolo.",
      "Password must be changed every 90 days.": "Debe cambiarse cada 90 días.",
       "Cannot reuse any of the last 7 passwords.": "No se puede reutilizar ninguna de las últimas 7 contraseñas.",
      "Password Requirements": "Requisitos de la contraseña",
      "Approve Operation Request": "Aprobación de solicitud de acceso",
      "Owner": "Propietario",
      "Last Date of Use": "Ultima fecha de uso",
      "There's already a group named {0}": "Ya existe un grupo llamado {0}",
      "Your session will expire in": "Su sesión expirará en",
      "Your Code": "Tú Código", 
      "Go to": "Ir a",
      "Search application..." :  "Buscar aplicaciones..."
      },
    "fr": {
      "Remove this app from your favorites":"Supprimez cette application de vos favoris",
      "My Clients": "Mes clients",
      "Are you sure that you want to discard these changes?": "Voulez-vous vraiment annuler ces modifications ?",
      "Show hierarchy": "Afficher la hiérarchie",
      "Customized?": "Personnalisé?", 
      "Tenants exported successfully": "Tenants exportés avec succès",
      "Export Tenants Success": "Succès de l'exportation des Tenants",
      "Export Member Success": "Membres exportés avec succès",
      "Download File": "Télécharger un fichier",
      "Select Area...": "Sélectionnez la zone...",
      "Select Creation type...": "Sélectionnez le type de création...",
      "Creation": "Creación",
      "Auto": "Auto",
      "Manual": "Manuel",
      "Send": "Envoyer",
      "Show only favorites applications":"Afficher uniquement les applications favorites",
      "Sort applications":"Trier les candidatures",
      "Add this app to your favorites":"Ajoutez cette application à vos favoris",
      "Are you sure to revoke this access?": "Êtes-vous sûr de révoquer cet accès?",
      "Choose OK to Login and continue...": "Choisissez OK pour vous connecter et continuer...",
      "Your session has expired.": "Votre session a expiré.",
      "FI-Group password change": "FI-Group Changement de mot de passe",
      "Invitation FI GROUP": "Invitation du FI GROUP",
      "Welcome to FI GROUP": "Bienvenue sur FI GROUP",
      "FI Connect is a digital applications HUB created by FI Group that seeks to transform and optimize the relationship we have with our customers." : "FI Connect est un HUB d'applications numériques créé par le Groupe FI qui vise à transformer et à optimiser la relation que nous entretenons avec nos clients.",
      "Please confirm your invitation": "S'il vous plaît confirmer votre invitation",
      "Are you sure to revoke all user Access?": "Êtes-vous sûr de révoquer tous les accès utilisateur ?",
      "Add Tenant Roles": "Ajouter des rôles de Tenant",
      "Group's Users": "Utilisateurs du groupe",
      "(Empty Email)": "(E-mail vide)",
      "(Empty Name)": "(Nom vide)",
      "Identity Tenants": "Tenants d'identité",
      "Apply to this entity and all its descendants": "Appliquer à cette entité et à tous ses descendants",
      "Start typing to search for applications...": "Commencez à taper pour rechercher des applications...",
      "Add new user role": "Ajouter un nouveau rôle d'utilisateur",
      "Group Name": "Nom de groupe",
      "Entities": "Entités",
      "Add Member to Tenant": "Ajouter un membre au Tenant",
      "Delete elements": "Supprimer des éléments",
      "elements deleted sussessful": "éléments supprimés avec succès",
      "European VAT": "TVA européenne",
      "Clear All": "Tout Effacer",
      "Ok": "Accepter",
      "Upload Successful": "Téléchargement réussi",
      "Upload complete": "Téléchargement complet",
      "New Notification": "Nouvelle Notification",
      "Identities": "Identités",
      "This file will be processed in the background.": "Ce fichier sera traité en arrière-plan",
      "Add member to Group": "Ajouter un membre au groupe",
      "Group Details": "Détails du groupe",
      "Group Users": "Utilisateurs du groupe",
      "Add Tenant Roles to User": "Ajouter des rôles de Tenant à l'utilisateur",
      "Add Tenant Roles to Group": "Ajouter des rôles de Tenant au groupe",
      "Tenant": "Tenant",
      "Global": "Global",
      "Edit": "Éditer",
      "Display Name": "Nom d'affichage",
      "Inheritance": "Héritage",
      "Inherited": "Hérité",
      "Tenant Details": "Détails du Tenant",
      "Change Parent Tenant": "Changer le Tenant parent",
      "Tax Number": "Numéro d'identification fiscale",
      "Hierarchy": "Hiérarchie",
      "Parent": "Parent",
      "View Number": "Numéro de vue",
      "New Entity": "Nouvelle entité",
      "Please enter a title": "Veuillez entrer un titre",
      "Area is requiered": "La zone est requise",
      "Select entity": "Sélectionnez l'entité",
      "Terms of service": "Conditions d’utilisation",
      "Job Title": "Profession",
      "Manager": "Directeur",
      "Teams": "Équipes",
      "(2MB size limit)": "(Limite de taille de 2 Mo)",
      "2MB files size limit exceeded": "Limite de taille des fichiers de 2Mo dépassée",
      "Are you sure you want to change this entity parent?": "¿Voulez-vous vraiment modifier cette entité parent?",
      "This action can affect users permissions": "Cette action peut affecter les autorisations des utilisateurs",
      "Already exists an app with this app id": "Il existe déjà une application avec cet identifiant d'application",
      "Already exists an app with this app id and name": "Il existe déjà une application avec cet identifiant et ce nom d'application",
      "Already exists an app with this name": "Existe déjà une application avec ce nom",
      "An error ocurred": "Une erreur s'est produite",
      "Ask us if you want to also access:": "Demandez-nous si vous souhaitez également accéder à:",
      "Created on": "Créé sur",
      "Change password": "Changer son mot de passe",
      "Hide in user List": "masquer dans la liste des utilisateurs",
      "Grant": "Subvention",
      "Grants": "Subventions",
      "Forgot your password?": "Mot de passe oublié?",
      "Grant search service": "Service de recherche de subventions",
      "Manages receipts & taxes": "Gérer les reçus et les taxes",
      "Uploaded User'Roles have been created/updated": "Les rôles d'utilisateur téléchargés ont été créés/mis à jour",
      "Uploaded Group'Roles have been created/updated": "Les rôles d'groupe téléchargés ont été créés/mis à jour",
      "Uploaded User have been created/updated": "Les utilisateurs téléchargés ont été créés/mis à jour",
      "Welcome {0}!": "Bonjour {0}!",
      "Welcome": "Bonjour!",
      "Welcome to": "Bienvenue à",
      "(without description)": "(sans description)",
      "(without name)": "(sans nom)",
      "Cannot reset password at this stage": "Impossible de réinitialiser mot de passe à ce stade",
      "No data": "Pas de données",
      "Manage your R&D claims with this application": "Gérez vos demandes de R & D avec cette application",
      "View progress and goals achieved by your companies.": "Voir les progrès et les objectifs atteints par vos entreprises",
      "Configure clients, workgroups and companies": "Configurer les clients, les groupes de travail et les entreprises",
      "Manages applications, users, groups and permissions": "Gère les applications, utilisateurs, groupes et autorisations",
      "Identity management": "Gestion de l'identité",
      "Please select your preferred language!": "Veuillez sélectionner votre langue préférée!",
      "Please type a valid email!": "Veuillez saisir un e-mail valide!",
      "Portal settings": "Paramètres du portail",
      "French": "Français",
      "Full name": "Nom et prénom",
      "My profile": "Mon profil",
      "Name and surname of the interested": "Nom et prénom de l'intéressé",
      "Check User Permissions": "Vérifier les autorisations des utilisateur",
      "User Permissions": "Autorisations utilisateur",
      "Click or drag file to this area to upload": "Cliquez ou faites glisser le fichier dans cette zone pour télécharger",
      "Close session": "Fermer la session",
      "Google account": "Compte Google",
      "Facebook account": "Compte Facebook",
      "Fi Group users have to connect through Office 365 professional account": "Les utilisateurs de Fi Group doivent se connecter via un compte professionnel Office 365",
      "Local user": "Utilisateur local",
      "El inicio de sesión ha terminado con éxito": "La connexion s'est terminée avec succès",
      "Introduzca su nombre de usuario": "S'il vous plaît entrer votre nom d'utilisateur",
      "Introduzca su contraseña": "Veuillez saisir votre mot de passe",
      "Remember me": "Se souvenir de moi",
      "Invalid preferred language": "Langue préférée non valide",
      "Invalit token.": "Jeton invalide.",
      "Italian": "Italien",
      "In case of being requested by representation, please indicate the representative's data": "En cas de demande par représentation, veuillez indiquer les données du représentant",
      "Log in": "Commencer la session",
      "Usuario": "Nom d'utilisateur",
      "Password": "Mot de passe",
      "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna": "Connectez-vous à l'aide de votre compte Office 365 si vous en avez un.",
      "Password has been successfully reseted": "Le mot de passe a été changé avec succès",
      "Passwords must be at least 6 characters.": "Les mots de passe doivent comporter au moins 6 caractères.",
      "Passwords must have at least one non alphanumeric character.": "Les mots de passe doivent avoir au moins un caractère non alphanumérique.",
      "Passwords must have at least one digit ('0'-'9').": "Les mots de passe doivent comporter au moins un chiffre ('0'-'9').",
      "Passwords must have at least one lowercase ('a'-'z').": "Les mots de passe doivent avoir au moins une minuscule ('a'-'z').",
      "Passwords must have at least one uppercase ('A'-'Z').": "Les mots de passe doivent avoir au moins une majuscule ('A'-'Z').",
      "O cree una cuenta local": "Ou créer un compte local",
      "Vuelva a introducir su contraseña": "Ressaisissez votre mot de passe",
      "What right do you want to exercise?": "Quel droit souhaitez-vous exercer?",
      "To create a local account you need to define a password. Your username will be your email.": "Pour créer un compte local, vous devez définir un mot de passe. Votre nom d'utilisateur sera votre e-mail.",
      "Create Password": "Créer un mot de passe",
      "data-delete-info": "(article 17 du RGPD «Droit à l'effacement (» droit à l'oubli «)») : si vous ne souhaitez plus que le groupe Fi traite vos données, retirez votre consentement, ainsi que tout lien, copie ou reproduction de vos données .",
      "data-portability-info": "(article 20 du RGPD «Droit à la portabilité des données») : c'est le fait de demander l'exportation de vos données et de les transférer à une autre société.",
      "data-rectification-info": "(article 16 du RGPD «Droit de rectification»): le cas dans lequel vous estimez que certaines de vos données sont inexactes ou incomplètes et demandez qu'elles soient mises à jour dans les meilleurs délais.",
      "Data portability request": "Demande de portabilité des données",
      "Cargando": "En chargeant",
      "Email": "E-mail",
      "Users": "Utilisateurs",
      "User Management": "Gestion des utilisateurs",
      "Roles": "Rôles",
      "Roles assigned": "Rôles attribués",
      "API Resources": "Ressources API",
      "We help you import your information in an agile way": "Nous vous aidons à importer vos informations de manière agile",
      "We no longer know where to put so much innovation!!": "¡¡On ne sait plus où mettre autant d'innovation!!",
      "Or the page may not be found ...": "Ou la page est introuvable ...",
      "Page not found": "Page non trouvée",
      "Download errors file": "Télécharger le fichier d'erreurs",
      "Download import template": "Télécharger le modèle d'importation",
      "Download template": "Télécharger le modèle",
      "Download warnings file": "Télécharger le fichier d'avertissements",
      "Do you want to access these applications? Ask us!": "Vous souhaitez accéder à ces applications ? Demandez nous!",
      "Bulk upload": "Téléchargement en masse",
      "Bulk upload items": "Téléchargement en masse",
      "Bulk Upload Options": "Options de téléchargement groupé",
      "Bulk Insert Users": "Insertion en masse d'utilisateurs",
      "Bulk Insert Users's Role": "Rôles des utilisateurs d'insertion en masse",
      "Bulk Insert Users's Roles": "Rôles des utilisateurs d'insertion en masse",
      "Open Result": "Ouvrir le Résultat",
      "Bulk Insert Groups's Roles": "Rôles des groupes d'insertion en masse",
      "Cancel": "Annuler",
      "Error": "Erreur",
      "Notifications": "Notifications",
      "No Notifications": "Aucune notification",
      "Email missing": "E-mail manquant",
      "Please enter your email before continue with the password reset.": "Veuillez saisir votre adresse électronique avant de poursuivre la réinitialisation du mot de passe.",
      "Please indicate if it is a direct request or through a representative": "Veuillez indiquer s'il s'agit d'une demande directe ou par l'intermédiaire d'un représentant",
      "Done": "Terminé",
      "Please check your email inbox. We have sent instructions on how reset your password.": "Veuillez vérifier votre boîte de réception. Nous avons envoyé des instructions pour réinitialiser votre mot de passe.",
      "Please input the application display name!": "Veuillez saisir le nom d'affichage de la demande!",
      "Please input the application description!": "Veuillez saisir la description de la demande!",
      "Please input the application private URL!": "Veuillez saisir l'URL privée de l'application!",
      "Please input the application redirect URIs!": "Veuillez saisir votre URI de redirection!",
      "Please input the email!": "Veuillez saisir l'e-mail!",
      "Please input the name of the interested!": "Veuillez saisir le nom de l'intéressé!",
      "Please enter your new password. Your username will remain the same as it was previously.": "Veuillez entrer votre nouveau mot de passe. Votre nom d'utilisateur restera le même qu'avant.",
      "Application Id": "Identifiant d'application",
      "Application name": "Nom de l'application",
      "Enabled": "Activé",
      "English": "Anglais",
      "Description": "Description",
      "Applications": "Candidatures",
      "Applications which is a member": "Candidatures qui est membre",
      "New application": "Nouvelle Candidature",
      "Please input your application id!": "Veuillez saisir votre identifiant d'application!",
      "Please input your application display name!": "Veuillez saisir le nom d'affichage de votre candidature!",
      "Please input your application name!": "Veuillez saisir le nom de votre demande!",
      "Please input your redirect URIs!": "Veuillez saisir votre URL de redirection!",
      "Please input the request type!": "Veuillez saisir le type de demande!",
      "Please enter your password. Remember that the login name is your email address." : "Veuillez saisir votre mot de passe. N'oubliez pas que le nom de connexion est votre adresse électronique.",
        "Invalid or expired token": "Lien de connexion invalide ou expiré. Merci de vous rapprocher de votre interlocuteur FI Group ou de votre administrateur Time Tracking pour qu’il vous en renvoie un.",
      "Application shared secret": "Secret partagé de l'application",
      "Name": "Nom",
      "New role": "Nouveau rôle",
      "Please input your API title!": "Veuillez saisir le titre de votre API!",
      "Scope": "Champ d'application",
      "Select another authentication method": "Sélectionnez une autre méthode d'authentification",
      "Select request type": "Sélectionnez le type de demande",
      "Send email invite": "Envoyer une invitation par e-mail",
      "Please enter the scope!": "Veuillez entrer le champ d'application!",
      "Groups": "Groupes",
      "Groups assigned": "Groupes assignés",
      "New group": "Nouveau groupe",
      "Please input your group name!": "Veuillez saisir le nom de votre groupe!",
      "Role": "Rôle",
      "Role name": "Nom de rôle",
      "Application required": "Demande requise",
      "Request failed with status code 403": "Vous n'êtes pas autorisé à effectuer cette action",
      "Role required": "Rôle requis",
      "User": "Utilisateur",
      "Group": "Groupe",
      "Group name": "Nom de groupe",
      "Total elements": "Éléments totaux",
      "Total imported": "Total importé",
      "Upload completed": "Téléchargement terminé",
      "User required": "Utilisateur requis",
      "Group required": "Groupe requis",
      "Please input your first name!": "Veuillez saisir votre prénom!",
      "Please input your last name!": "Veuillez saisir votre nom de famille!",
      "User name": "Nom d'utilisateur",
      "Tenant name": "Nom du Tenant",
      "Operation": "Opération",
      "First name": "Prénom",
      "Last name": "Nom de Famille",
      "limitation-treatment-info": "(article 18 du RGPD «Droit à la limitation du traitement») : le cas dans lequel vous souhaitez pouvoir isoler vos données afin qu'elles ne soient conservées que temporairement afin que vos données ne puissent, sauf conservation, être traitées uniquement avec votre consentement.",
      "Restricted": "Restreint",
      "Right of access": "Droit d'accès",
      "Right to be informed (that is, to obtain a copy of the same that may be in the possession of the company)": "Droit d'être informé (c'est-à-dire d'obtenir une copie de celui-ci qui pourrait être en la possession de l'entreprise)",
      "Right to data portability": "Droit à la portabilité des données",
      "Right to erasure (right to be forgotten)": "Droit à l'effacement (droit à l'oubli)",
      "Right to object to treatment": "Droit de s'opposer au traitement",
      "Right to rectification (that is, to correct personal data, or to complete incomplete data)": "Droit de rectification (c'est-à-dire de corriger des données personnelles ou de compléter des données incomplètes)",
      "RIGHTS AND EXERCISE OF THE INTERESTED PARTIES": "DROITS ET EXERCICE DES PARTIES INTÉRESSÉES",
      "rights-intro": "Conformément aux dispositions du règlement (UE) 2016/679 du Parlement européen et du Conseil relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, vous disposez de plusieurs droits sur vos données qui compléter le droit à l'information vous permettant d'être informé de la collecte de vos données et de leur devenir.",
      "Representative data": "Détails du représentant",
      "Request date": "Date de la demande",
      "Request details": "Détails de la demande",
      "Request for access to your personal data": "Demande d'accès à vos données personnelles",
      "Request for limitation of treatment": "Demande de limitation de traitement",
      "Request for rectification of your personal data": "Demande de rectification de vos données personnelles",
      "Request for denial of profiling": "Demande de refus de profilage",
      "Request sent successfully": "Demande envoyée avec succès",
      "Request to delete your personal data": "Demande de suppression de vos données personnelles",
      "Request to object to data processing": "Demande d'opposition au traitement des données",
      "Request through a representative": "Demande par l'intermédiaire d'un représentant",
      "Modified on": "Modifié le",
      "My data": "Mes données",
      "Please input your username!": "Veuillez saisir votre nom d'utilisateur!",
      "Please input your password!": "Veuillez saisir votre mot de passe!",
      "Please confirm your password!": "Veuillez confirmer votre mot de passe!",
      "Please input your email!": "Veuillez saisir votre email!",
      "Access is denied": " L'accès est refusé",
      "You dont have access to this resource. You might not have the required permissions. Please, contact your administrator for more information.": "Vous n'avez pas accès à cette ressource. Vous n'avez peut-être pas les autorisations requises. Veuillez contacter votre administrateur pour plus d'informations.",
      "Passwords don't match": "Les mots de passe ne correspondent pas",
      "Reset your password": "Réinitialiser votre mot de passe",
      "Are you sure want to reset your password? An email will be sent to": "Voulez-vous vraiment Rénitialiser votre mot de pass? un e-mail sera envoyé à",
      "Attach to this application a copy of your identification, or where appropriate, a copy of the order and identification of the representative": "Joindre à cette demande une copie de votre pièce d'identité ou, le cas échéant, une copie de l'ordonnance et de l'identification du représentant",
      "General properties": "Propriétés générales",
      "Display name": "Nom d'affichage",
      "App URI": "URI de l'application",
      "Preferred language": "Langue préférée",
      "Preferred language required": "Langue préférée requise",
      "Private url": "URL privé",
      "Public URI": "URI public",
      "Redirect URIs": "Rediriger les URIs",
      "The expiration date must be greater than today's date": "La date d'expiration doit être postérieure à la date d'aujourd'hui",
      "The passwords you entered do not match": "Les mots de passe que vous avez entrés ne correspondent pas",
      "This appplication is in PRE-RELEASE mode": "Cette application est en mode publication",
      "Icon": "Icône",
      "Icon (Ant Icon, URL or base64 image)": "Icône (Ant Icon, URL ou image base 64 )",
      "Enable the application": "Activer l'application/ Activer la demande",
      "Yes": "Oui",
      "No": "Non",
      "Save": "Sauver",
      "Save this string elsewhere because it will not be shown again": "Enregistrez cette chaîne ailleurs car elle ne sera plus affichée",
      "Role access scopes": "Portée de l'accès aux rôles",
      "Group roles": "Les rôles de groupe",
      "Members": "Membres",
      "New role's scope": "La portée du nouveau rôle",
      "New user": "Nouvel utilisateur",
      "New group's scope": "Champ d'action du nouveau groupe",
      "New password": "Nouveau mot de passe",
      "Restrict client access by scope": "Limiter l'accès des clients en fonction du champ d'application",
      "Member of": "Membre de",
      "Manual assigned roles": "Rôles attribués manuellement",
      "Filter by application": "Filtrer par application",
      "Username": "Nom d'utilisateur",
      "Base Application": "Application de base/ demande de base/ exécution de base",
      "You can add more roles later from the user edit screen.": "Vous pouvez ajouter d'autres rôles ultérieurement à partir de l'écran de modification de l'utilisateur.",
      "Base role": "Rôle de base",
      "Basic info": "Informations de base",
      "Confirm password": "Confirmez le mot de pass",
      "It seems that you dont have access to any application. Please contact your administartor": "Il semble que vous n'ayez pas l'autorisation d'énumérer des documents dans une entreprise. Si vous pensez que cela est incorrect, veuillez contacter votre administrateur",
      "Name is required": "Le nom est requis",
      "Role is required": "Role est requis",
      "Group is required": "Le groupe est requis",
      "Use an excel template to easily import": "Utilisez un modèle Excel pour importer facilement",
      "Use an Excel template to easily import your data": "Utilisez un modèle Excel pour importer facilement vos données",
      "User is required": "L'utilisateur est requis",
      "Scope is required": "Le champ est requis",
      "Username is required": "Le nom d'utilisateur est requis",
      "Personal Access Tokens": "Jeton D'accès Personnel",
      "personal-data-info": "(article 15 du RGPD «Droit d'accès de l'intéressé») : si vous souhaitez savoir si le groupe Fi traite ou non vos données personnelles, le cas échéant, obtenez une copie et le détail des traitements.",
      "Personal data stored for the user: {0}": "Données personnelles stockées pour l'utilisateur: {0}",
      "Please add the required documents!": "Veuillez ajouter les documents requis!",
      "Please check at least one right to exercise!": "Veuillez cocher au moins un droit d'exercice!",
      "Expires at": "Expire à",
      "New Personal Access Token (PAT)": "Nouveau Jeton D'accès Personnel (JAP)",
      "Application": "Candidature",
      "Confirmation required": "Confirmation requise",
      "Are you sure want to delete selected items?": "¿Voulez-vous vraiment supprimer les éléments sélectionnés?",
      "Personal Access Token (PAT) Created": "Jeton D'accès Personnel (JAP) Établi",
      "This is your newly created PAT. Save it carefuly since once you close this dialog, you will not see the token anymore": "Ceci est votre JAP nouvellement créé. Enregistrez-le soigneusement car une fois que vous fermez cette boîte de dialogue, vous ne verrez plus le jeton",
      "Please input your PAT's name!": "Veuillez saisir le nom de votre JAP!",
      "Please select your PAT's Application!": "Veuillez sélectionner l'application de votre JAP!",
      "Copy to clipboard": "Copier dans le presse-papier",
      "Add new": "Ajouter un nouvel élément",
      "Delete selected": "Supprimer les éléments sélectionnés",
      "denial-profiling-info": "(article 22 du RGPD «Décision individuelle automatisée, y compris le profilage») : il s'agit de demander à ne pas faire l'objet d'une décision individuelle automatisée.",
      "Import template": "Modèle d'importation",
      "Input search text...": "Texte de recherche d'entrée...",
      "PAT copied to clipboard!": "JAP copié dans le presse-papiers!",
      "Owners": "Propriétaires",
      "Permissions": "Autorisations",
      "Permissions assigned": "Autorisations attribuées",
      "Permission display name": "Nom d'affichage de l'autorisation",
      "Permission name": "Nom",
      "Portuguese": "Portugais",
      "Portuguese (BR)": "Portugais (BR)",
      "Portuguese (PT)": "Portugais (PT)",
      "New Permission": "Nouvelle autorisation",
      "Please input the new password!": "Veuillez saisir le nouveau mot de passe!",
      "Please input the password confirmation!": "Veuillez saisir la confirmation du mot de passe!",
      "Please input the permission name!": "Veuillez saisir le nom de l'autorisation!",
      "Permission": "Autorisation",
      "BulkInsert": "Insertion en masse",
      "Please input the permission!": "Veuillez saisir l'autorisation!",
      "Please input the request date!": "Veuillez saisir la date de la demande!",
      "No groups found for this user": "Aucun groupe trouvé pour cet utilisateur",
      "No permissions found for this user": "Aucune autorisation trouvée pour cet utilisateur",
      "object-data-info": "(article 21 du RGPD «Droit d'opposition») : il s'agit du fait d'arrêter tout traitement de vos données sous certaines conditions, à savoir le traitement nécessaire à l'exécution d'une mission de service public par le responsable du traitement, ou le traitement nécessaire à la aux fins des intérêts légitimes poursuivis par le responsable de traitement ou un tiers, ou le cas dans lequel vous ne souhaitez plus que vos données personnelles soient traitées à des fins de prospection commerciale.",
      "Select a user to check": "Sélectionnez un utilisateur",
      "Please select a user to check permissions": "Veuillez sélectionner un utilisateur pour vérifier les autorisations",
      "Shared secret": "Secret partagé",
      "Spanish": "Espagnol",
      "Successful action": "Action réussie",
      "Professional account (Office 365)": "Compte professionnel (Office 365)",
      "Profile": "Profil",
      "Local User": "Utilisateur local",
      "Or with the following providers": "Ou avec les fournisseurs suivants",
      "Login has successfully ended": "La connexion s'est terminée avec succès",
      "Enter your username": "S'il vous plaît entrer votre nom d'utilisateur",
      "Enter your password": "Veuillez saisir votre mot de passe",
      "Login": "Commencer la session",
      "Sign in using your Office 365 account if you have any": "Connectez-vous à l'aide de votre compte Office 365 si vous en avez un.",
      "Or create a local account": "Ou créer un compte local",
      "Re-enter your password": "Ressaisissez votre mot de passe",
      "Incorrect username or password": "Nom d’utilisateur ou mot de passe incorrect",
      "You dont have permission to obtain a PAT token for this application": "Vous n'êtes pas autorisé à obtenir un jeton PAT pour cette application",
      "Display name must have less than 256 chars.": "Le nom doit avoir moins de 256 caractères.",
      "Invalid email address.": "Adresse e-mail invalide.",
      "Username required": "Nom d'utilisateur requis",
      "Application not found": "Application non trouvée",
      "Role not found or it is not available for selected application": "Rôle introuvable ou non disponible pour l'application sélectionnée",
      "Email required": "Email requis",
      "Row": "Ligne",
      "Errors": "Erreurs",
      "Exercise rights": "Vos droits",
      "EXERCISE YOUR RIGHTS": "VOS DROITS",
      "Display name must have less than 200 chars.": "Le nom doit avoir moins de 200 caractères.",
      "Application Id must have less than 200 chars.": "L'ID d'application doit contenir moins de 200 caractères.",
      "Description must have less than 1000 chars.": "La description doit comporter moins de 1 000 caractères.",
      "Direct request": "Demande directe",
      "Each URI must have less than 2000 chars.": "Chaque URI doit avoir au maximum 2000 caractères.",
      "Export to Excel": "Exporter vers Excel",
      "Your information": "Vos informations",
      "Your picture": "Votre photo",
      "Click or drag file to update your profile picture": "Cliquez ou faites glisser le fichier pour mettre à jour votre photo de profil",
      "Your Profile": "Votre profil",
      "Click or drag file to update the app picture": "Cliquez ou faites glisser le fichier pour mettre à jour l'image de l'application",
      "Copyright": "Droits d'auteur",
      "FI Group all rights reserved": "FI Group, tous droits réservés",
      "Terms of Use": "Mentions légales",
      "To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right": "Pour nous aider à traiter efficacement votre demande, veuillez fournir autant de détails que possible sur les données personnelles que vous demandez pour exercer votre droit",
      "Data Subject Right": "Démarche données personnelles",
      "Cookies Policy": "Politique de confidentialité",
      "Problems to access? Contact Us!": "Des problèmes d'accès ? Contactez nous!",
      "Policies": "Politique",
      "Policy Body": "Organe de politique",
      "Areas": "Zones",
      "Area": "Zone",
      "Is Default Area": "Est la zone par défaut",
      "Is System Area": "Est la zone système",
      "Tenants": "Tenants",
      "Helpdesk": "Helpdesk",
      "Title": "Titre",
      "Changes have been saved": "Les modifications ont été enregistrées",
      "Export": "Exporter",
      "New Policy": "Nouvelle Politique",
      "Edit Policy": "Modifier la Politique",
      "OK": "Accepter",
      "Please input your policy title!":"Entrez le titre de votre police!",
      "Policy Id must have less than 200 chars.":"L'ID de stratégie doit comporter moins de 200 caractères.",
      "Logging in": "Se connecter",
      "My disclaimers": "Mes clauses de non-responsabilité",
      "Disclaimers": "Clause de non-responsabilité",
      "Accept": "Accepter",
      "Reason": "Raison",
      "Date": "Date",
      "Login Result": "Résultat de la connexion",
      "Authentication Provider": "Fournisseur d'authentification",
      "Success": "Succès",
      "Failure": "Échec",
      "UserLocked": "Utilisateur verrouillé",
      "Select Login Result...": "Sélectionnez Résultat de la connexion...",
      "Select Authentication Provider...": "Sélectionnez le fournisseur d'authentification...",
      "Select Operation...": "Sélectionnez l'opération...",
      "Select User...": "Sélectionner l'utilisateur...",
      "Select Tenant...": "Sélectionner le tenant...",
      "Application Name": "Nom de l'application",
      "Search into Metadata?": "Recherche dans les métadonnées?",
      "Authentication Logs": "Journaux d'authentification",
      "System Logs": "Journaux du système",
      "Session Logs": "Registros de sesión",
      "Login Success": "Connexion réussie",
      "User Locked": "Utilisateur verrouillé",
      "Invalid credentials": "les informations d'identification invalides",
      "Unknown error" : "Erreur inconnue",
      "Please verify your email." : "Veuillez vérifier votre e-mail.",
      "Include Users Global" : "Inclure les utilisateurs globaux",
      "Locked" : "Verrouillé",
      "Unlocked" : "Déverrouillé",
      "Status" : "Statut",
      "The user": "L'utilisateur",
      "has been unlocked" : "a été déverrouillé",
      "User Unlocked":"Utilisateur déverrouillé",
      "User Locked Permanently": "Verrouillage permanent de l'utilisateur",
      "Permanent lock":"Verrouillage permanent",
      "Your password has expired, please create a new password to login.": "Votre mot de passe a expiré, veuillez créer un nouveau mot de passe pour vous connecter.",
      "Applied":"Appliqué",
      "Waiting for Approver":"En attente d'approbateur",
      "Cancelled":"Annulé",
      "Rejected":"Rejeté",
      "RollBack":"Retour en arriere",
      "Incorrect username or password, you have 1 attempts left or your account will be locked" : "Informations d'identification incorrectes, ce qui fait 1 intention ou votre compte sera bloqué",
      "Incorrect username or password, you have 2 attempts left or your account will be locked" : "Informations d'identification incorrectes, ce qui fait 2 intention ou votre compte sera bloqué",
      "Incorrect username or password, you have 3 attempts left or your account will be locked" : "Informations d'identification incorrectes, ce qui fait 3 intention ou votre compte sera bloqué",
      "Incorrect username or password, you have 4 attempts left or your account will be locked" : "Informations d'identification incorrectes, ce qui fait 4 intention ou votre compte sera bloqué",
      "Incorrect username or password, you have 5 attempts left or your account will be locked" : "Informations d'identification incorrectes, ce qui fait 5 intention ou votre compte sera bloqué",
      "User Is Locked Out": "L'utilisateur est verrouillé",
      "User does not exist": "L'utilisateur n'existe pas",
      "Are you sure to disable the MFA factor?":"Êtes-vous sûr de désactiver le mode MFA ?",
      "Code expired!":"Le code a expiré !",
      "Resend Code":"Renvoyer le code",
      "A code was sent to your Email, please type code for login. The code will expire in 5 minutes":"Un code a été envoyé à votre e-mail, veuillez saisir le code pour vous connecter. Le code expirera dans 5 minutes.",
      "Minutes":"Minutes",
      "Seconds":"Secondes",  
      "Submit":"Soumettre",
	  "Error validating TwoAuthentification token for invalid code":"Code invalide",
      "Hi, ":"Bonjour, ",
      "Cannot reset password, user is locked":"Impossible de réinitialiser le mot de passe, l'utilisateur est verrouillé",
      "New User":"Nouvel utilisateur",
      "User Information":"Information de l'utilisateur",
      "User's Permissions":"Autorisations de l'utilisateur",
      "Create Operation Request":"Demande d'opération",
      "Operation Requests":"Demander une opération",
      "Area required":"Veuillez entrer la zone !",
      "Work Position":"Position de travail",
      "Phone Number":"Numéro de téléphone",
      "Departments":"Départements",
      "Save and exit":"Sauvegarder et quitter",
      "Next":"Suivant",
      "Clients": "Clients",
      "Result":"Résultat",
      "Provider":"Fornitore",
      "ReturnUrl":"URL",
      "User locked. Draft User!": "Utilisateur en attente d'approbation",
      "Access Requests": "Demandes d'accès",
      "You are validating access to FI Connect to users outside the company. Ensure that users must access the requested accounts and each application with the requested role":"Vous validez l'accès à FI Connect aux utilisateurs extérieurs à l'entreprise. Assurez-vous que les utilisateurs doivent accéder aux comptes demandés et à chaque application avec le rôle demandé" ,
      "Are you sure you want to remove all permissions from all current tenants for this user?":"Êtes-vous sûr de vouloir supprimer toutes les autorisations de tous les locataires actuels pour cet utilisateur ?",
      "Bulk assignment of permissions to existing users of this Tenant":"Attribution groupée d'autorisations aux utilisateurs existants de ce tenant",
      "Bulk loading of users and permissions for this Tenant": "Chargement groupé des utilisateurs et des autorisations pour ce tenant",
      "You do not have access to Users group permission assignment": "Vous n'avez pas accès à l'attribution des permissions du groupe Utilisateurs",
      "Global roles only": "Rôles mondiaux uniquement",
      "MFA Configuration": "MFC Configuration",
      "Running": "En cours",
      "Stopped": "Arrêtée",
      "Maintenance": "Entretien",
      "Connected per App last 30 minutes": "Connecté par App depuis 30 minutes",
      "Users Connected": "Utilisateurs connectés",
      "Global Administrator per Areas": "Administrateur global par domaine",
      "Global Administrator per App": "Administrateur global par application",
      "Global Administrators": "Administrateurs globaux",
      "Login success per hour": "Succès de connexion par heure",
      "Logs": "Registres",
      "Global Admin": "Administrateur global",
      "Polaris Permission Create": "Polaris Autorisation de création",
      "Invitation mail sent successfully.": "Le courriel d'invitation a été envoyé avec succès.",
      "Failed to send invitation mail. Please try again later.": "Échec de l'envoi du courrier d'invitation. Veuillez réessayer plus tard.",
      "Select Api Entry...": "Sélectionner une entrée API...",
      "Api Entry": "Entrée API",
      "Select a date...": "Sélectionner une date...",
      "Start date": "Date de début",
      "End date": "Date de fin",
      "Access Per User. Date Range": "Accès par utilisateur. Plage de dates",
      "User Access": "Accès utilisateur",
      "Select a Time Range": "Sélectionnez une plage horaire",
      "The 'Identity' application cannot be deleted.": "L'application 'Identity' ne peut pas être supprimée.",
      "The User is not the owner of the application and cannot delete it.": "L'utilisateur n'est pas le propriétaire de l'application et ne peut pas la supprimer.",
      "Application deleted successfully.": "Application supprimée avec succès.",
      "Failed to delete the application. Please try again later.": "Échec de la suppression de l'application. Veuillez réessayer plus tard.",
      "Image uploaded successfully": "Image téléchargée avec succès.",
      "At least 12 characters long.": "Au moins 12 caractères de long.",
      "At least one uppercase letter, one lowercase letter, one number, and one symbol.": "Au moins une lettre majuscule, une lettre minuscule, un chiffre et un symbole.",
      "Password must be changed every 90 days.": "Le mot de passe doit être changé tous les 90 jours.",
      "Cannot reuse any of the last 7 passwords.": "Il est interdit de réutiliser l'un des 7 derniers mots de passe.",
      "Password Requirements": "Exigences de mot de passe",
      "Approve Operation Request": "Approbation de la demande d’opération",
      "There's already a group named {0}": "Il existe déjà un groupe nommé {0}", 
      "Your session will expire in": "Votre session expirera dans",
      "Go to": "Aller à",
      },
    "pt": {
      "Remove this app from your favorites":"Remova este aplicativo dos seus favoritos",
      "My Clients": "Meus clientes",
      "Are you sure that you want to discard these changes?": "Tem certeza de que deseja descartar essas alterações?",
      "Show hierarchy": "Mostrar hierarquia",
      "Customized?": "Personalizado?", 
      "Tenants exported successfully": "Tenants exportados com sucesso",
      "Export Tenants Success": "Exportar sucesso de Tenants",
      "Export Member Success": "Membros exportados com sucesso",
      "Download File": "Fazer download do arquivo",
      "Select Area...": "Selecionar área...",
      "Select Creation type...": "Selecione o tipo de criação...",
      "Creation": "Creación",
      "Auto": "Auto",
      "Manual": "Manual",
      "Send": "Mandar",
      "Show only favorites applications":"Mostrar apenas aplicativos favoritos",
      "Add this app to your favorites":"Adicione este aplicativo aos seus favoritos",
      "Sort applications":"Classificar aplicativoss",
      "Are you sure to revoke this access?": "Tem certeza de revogar este acesso?",
      "Choose OK to Login and continue...": "Escolha OK para fazer login e continuar...",
      "Your session has expired.": "Sua sessão expirou.",
      "FI-Group password change": "Alteração de senha do Grupo FI",
      "Invitation FI GROUP": "Convite FI GROUP",
      "Welcome to FI GROUP": "Bem-vindo ao FI GROUP",
      "FI Connect is a digital applications HUB created by FI Group that seeks to transform and optimize the relationship we have with our customers.": "O FI Connect é um HUB de aplicações digitais desenvolvido pelo FI Group que visa transformar e optimizar a relação que temos com os nossos clientes.",
      "Please confirm your invitation": "Por favor, confirme o seu convite",
      "Add Tenant Roles": "Adicionar funções de Tenant",
      "Group's Users": "Usuários do grupo",
      "(Empty Email)": "(E-mail vide)",
      "(Empty Name)": "(E-mail vazio)",
      "Identity Tenants": "Tenants de identidad",
      "Apply to this entity and all its descendants": "Aplicar a esta entidade e todos os seus descendentes",
      "Start typing to search for applications...": "Comece a digitar para pesquisar aplicativos...",
      "Add new user role": "Adicionar nova função de usuário",
      "Group Name": "Nome do grupo",
      "Entities": "Entidades",
      "European VAT": "IVA Europeu",
      "Add Member to Tenant": "Adicionar membro ao Tenant",
      "Delete elements": "Excluir elementos",
      "elements deleted sussessful": "elementos deletados com sucesso",
      "Clear All": "Limpar Tudo",
      "Hide in user List": "ocultar na lista de usuários",
      "Ok": "Aceitar",
      "This file will be processed in the background.": "Este arquivo será processado em segundo plano.",
      "Upload complete": "Envio completo",
      "New Notification": "Nova Notificação",
      "Upload Successful": "Envio bem-sucedido",
      "Identities": "Identidades",
      "Add member to Group": "Adicionar membro ao grupo",
      "Group Details": "Detalhes do grupo",
      "Group Users": "Usuários do grupo",
      "Add Tenant Roles to User": "Adicionar funções de Tenant ao usuário",
      "Add Tenant Roles to Group": "Adicionar funções de Tenant ao grupo",
      "Tenant": "Locatário",
      "Global": "Global",
      "Edit": "Editar",
      "Notifications": "Notificações",
      "No Notifications": "Nenhuma notificação",
      "Display Name": "Nome de exibição",
      "Inheritance": "Herança",
      "Inherited": "Heredado",
      "Tenant Details": "Detalhes do Tenant",
      "Change Parent Tenant": "Alterar Tenant pai",
      "Tax Number": "Número de identificação fiscal",
      "Hierarchy": "Hierarquia",
      "Parent": "Pai",
      "View Number": "Número da vista",
      "New Entity": "Nova entidade",
      "Please enter a title": "Insira um título",
      "Area is requiered": "A área é necessária",
      "Select entity": "Selecionar entidade",
      "Terms of service": "Termos de serviço",
      "Job Title": "Cargo",
      "Manager": "Gerente",
      "Teams": "Times",
      "(2MB size limit)": "(Limite de tamanho de 2 MB)",
      "2MB files size limit exceeded": "Limite de tamanho de arquivos de 2MB excedido",
      "Already exists an app with this app id": "Já existe um aplicativo com este id de aplicativo",
      "Already exists an app with this name": "Já existe um aplicativo com este nome",
      "Access is denied": "Acesso negado",
      "Add new": "Adicionar novo",
      "Already exists an app with this app id and name": "Já existe um aplicativo com este nome e id de aplicativo",
      "An error ocurred": "Um erro ocorreu",
      "API Resources": "Recursos API",
      "App URI": "App URI",
      "Application Id": "ID da aplicação",
      "Application Id must have less than 200 chars.": "O ID do aplicativo deve ter menos de 200 caracteres.",
      "Application name": "Nome da aplicação",
      "Application not found": "Aplicação não encontrada",
      "Application required": "Aplicação necessária",
      "Please enter your password. Remember that the login name is your email address.": "Introduza a sua palavra-passe. Lembre-se que o nome de utilizador é o seu endereço de correio eletrónico.",
      "Invalid or expired token": "Ligação de ligação inválida ou expirada. Contacte a pessoa de contacto do FI Group ou o administrador do Controlo de Tempos para que lhe possam enviar uma nova ligação.",
      "Application shared secret": "Aplicação partilhada em sígilo",
      "Application": "Aplicação",
      "Applications": "Aplicações",
      "Applications which is a member": "Aplicações que é um membro",
      "This action can affect users permissions": "Esta ação pode afetar as permissões dos usuários",
      "Are you sure you want to change this entity parent?": "¿Tem certeza de que deseja alterar esta entidade pai?",
      "Are you sure want to delete selected items?": "Tem certeza que deseja excluir os itens selecionados?",
      "Are you sure want to reset your password? An email will be sent to": "Pretende redefinir a sua password? Um email será enviado para",
      "Attach to this application a copy of your identification, or where appropriate, a copy of the order and identification of the representative": "Anexe a este requerimento uma cópia de sua identificação ou, se for o caso, uma cópia do pedido e a identificação do representante",
      "Ask us if you want to also access:": "Pergunte-nos se deseja também acessar:",
      "Base Application": "Aplicação base",
      "Base role": "Função base",
      "Basic info": "Informação básica",
      "Bulk upload": "Upload em massa",
      "Uploaded User'Roles have been created/updated": "Os Função dos usuários enviados foram criados/atualizados",
      "Uploaded Group'Roles have been created/updated": "Os Função dos grupos enviados foram criados/atualizados",
      "Uploaded User have been created/updated": "Os Usuários enviados foram criados/atualizados",
      "Bulk upload items": "Upload de itens em massa",
      "Bulk Upload Options": "Opções de upload em massa",
      "Bulk Insert Users": "Usuários de inserção em massa",
      "Bulk Insert Users's Role": "Função dos usuários de inserção em massa",
      "Bulk Insert Users's Roles": "Função dos usuários de inserção em massa",
      "Bulk Insert Groups's Roles": "Função dos grupos de inserção em massa",
      "Open Result": "Abrir Resultado",
      "Cancel": "Cancelar",
      "Cannot reset password at this stage": "Não é possível redefinir a palavra-passe nesta fase",
      "Cargando": "Carregando",
      "Change password": "Mudar senha",
      "Check User Permissions": "Verificar as permissões do usuário",
      "Click or drag file to this area to upload": "Clique ou arraste o arquivo para esta área para fazer o upload",
      "Click or drag file to update the app picture": "Clique ou arraste o arquivo para atualizar a imagem do aplicativo",
      "Click or drag file to update your profile picture": "Clique ou arraste o arquivo para atualizar sua foto de perfil",
      "Close session": "Fechar sessão",
      "Configure clients, workgroups and companies": "Configure clientes, grupos de trabalho e empresas",
      "Confirm password": "Confirme a password",
      "Confirmation required": "Necessária confirmação",
      "Contraseña": "Password",
      "Created on": "Criado em",
      "Create Password": "Criar password",
      "Cookies Policy": "Política de cookies",
      "Copyright": "Copyright",
      "Copy to clipboard": "Copiar para área de transferência",
      "data-delete-info": "(artigo 17º do RGPD «Direito ao apagamento (» direito ao esquecimento «)»): se já não quiser que o grupo Fi processe os seus dados, retire o seu consentimento, bem como qualquer ligação, cópia ou reprodução dos seus dados .",
      "data-portability-info": "(artigo 20º do RGPD «Direito à portabilidade dos dados»): é o acto de solicitar a exportação dos seus dados e transferi-los para outra empresa.",
      "data-rectification-info": "(artigo 16º do RGPD «Direito de rectificação»): o caso em que considera que alguns dos seus dados são inexactos ou incompletos e solicita que sejam actualizados o mais brevemente possível.",
      "Data portability request": "Solicitação de portabilidade de dados",
      "Password": "Senha",
      "Facebook account": "Conta Facebook",
      "Fi Group users have to connect through Office 365 professional account": "Os usuários do Grupo Fi devem se conectar por meio de uma conta profissional do Office 365",
      "Google account": "Conta Google",
      "Data Subject Right": "Politica de Privacidade",
      "Delete selected": "Apagar selecionado",
      "denial-profiling-info": "(artigo 22 do RGPD «Decisão individual automatizada, incluindo definição de perfil»): significa solicitar que não seja objeto de decisão individual automatizada.",
      "Description": "Descrição",
      "Description must have less than 1000 chars.": "A descrição deve ter menos de 1000 caracteres.",
      "Direct request": "Pedido direto",
      "Display name": "Nome de exibição",
      "Display name must have less than 200 chars.": "O nome deve ter menos de 200 caracteres.",
      "Display name must have less than 256 chars.": "O nome deve ter menos de 256 caracteres.",
      "Done": "Concluído",
      "Download errors file": "Baixar arquivo de erros",
      "Download import template": "Baixar modelo de importação",
      "Download template": "baixar modelo",
      "Download warnings file": "Baixar arquivo de avisos",
      "Do you want to access these applications? Ask us!": "Você deseja acessar esses aplicativos? Pergunte-nos!",
      "Each URI must have less than 2000 chars.": "Cada URI deve ter no máximo 2.000 caracteres.",
      "El inicio de sesión ha terminado con éxito": "A sessão terminou com êxito",
      "Email": "E-mail",
      "Email missing": "E-mail não encontrado",
      "Email required": "Email obrigatório",
      "Enable the application": "Ative a aplicação",
      "Enabled": "Ativo",
      "English": "Inglês",
      "Enter your password": "Introduza a sua password",
      "Enter your username": "Introduza o nome de utilizador",
      "Error": "Erro",
      "Errors": "Erros",
      "Exercise rights": "Exercer direitos",
      "EXERCISE YOUR RIGHTS": "EXERCER DIREITOS",
      "Expires at": "Expire em",
      "Export to Excel": "Exportar para Excel",
      "FI Group all rights reserved": "FI Group, todos os direitos reservados",
      "Filter by application": "Filtrar por aplicação",
      "First name": "Primeiro nome",
      "Forgot your password?": "Esqueceu-se da sua password?",
      "French": "Francês",
      "Full name": "Nome completo",
      "General properties": "Propriedades gerais",
      "Grant search service": "Serviço de pesquisa de apoios",
      "Grant": "Financiamento",
      "Grants": "Apoios",
      "Group is required": "Grupo é obrigatório",
      "Group required": "Grupo necessário",
      "Group roles": "Funções dos grupos",
      "Group": "Grupo",
      "Group name": "Nome do grupo",
      "Groups": "Grupos",
      "Groups assigned": "Grupos atribuídos",
      "Icon (Ant Icon, URL or base64 image)": "Ícone (Ant Icon, URL or base64 image)",
      "Icon": "Ícone",
      "Identity management": "Gestão de identidade",
      "Incorrect username or password": "Nome de utilizador ou palavra-passe incorreto",
      "Invalid email address.": "Endereço de email invalido.",
      "Invalid preferred language": "Língua preferida inválida",
      "Invalit token.": "Token inválido.",
      "Italian": "Italiano",
      "Log in": "Iniciar sessão",
      "In case of being requested by representation, please indicate the representative's data": "Caso seja solicitado por representação, favor indicar os dados do representante",
      "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna": "Inicie sessão utilizado a sua conta Office 365",
      "Import template": "Modelo de importação",
      "Input search text...": "Insira texto de pesquisa",
      "Introduzca su contraseña": "Introduza a sua password",
      "Introduzca su nombre de usuario": "Introduza o nome de utilizador",
      "It seems that you dont have access to any application. Please contact your administartor": "Parece que não tem acesso a nenhuma aplicação. Por favor, contacte o administrador",
      "La contraseña se ha cambiado con éxito": "A password foi alterado com sucesso",
      "Last name": "Último nome",
      "limitation-treatment-info": "(artigo 18 do RGPD «Direito à limitação de tratamento»): o caso em que você deseja isolar seus dados para que sejam mantidos apenas temporariamente para que seus dados não possam, exceto para retenção, serem processados apenas com sua permissão.",
      "Loading": "A carregar",
      "Local User": "Utilizador local",
      "Login": "Iniciar sessão",
      "Login has successfully ended": "A sessão terminou com êxito",
      "No data": "Sem dados",
      "Manage your R&D claims with this application": "Faça a gestão dos seus projetos de I&D com esta aplicação",
      "Manages applications, users, groups and permissions": "Gesão de aplicações, utilizadores, grupos e permissões",
      "Manages receipts & taxes": "Gerir recinos & taxas",
      "Manual assigned roles": "Funções atribuídas manualmente",
      "Member of": "Membro de",
      "Members": "Membro",
      "Modified on": "Modificado em",
      "My data": "Meus dados",
      "My profile": "Meu perfil",
      "Name and surname of the interested": "Nome e sobrenome do interessado",
      "Name is required": "Nome é obrigatório",
      "Name": "Nome",
      "New application": "Nova aplicação",
      "New group": "Novo grupo",
      "New group's scope": "Novo âmbito de grupo",
      "New password": "Nova senha",
      "New Permission": "Nova permissão",
      "New Personal Access Token (PAT)": "Novo token de acesso pessoal",
      "New role": "Nova função",
      "New role's scope": "Novo âmbito de função",
      "New user": "Novo usuário",
      "No": "Não",
      "No groups found for this user": "Nenhum grupo encontrado para este usuário",
      "No permissions found for this user": "Nenhuma permissão encontrada para este usuário",
      "object-data-info": "(artigo 21.º do RGPD «Direito de oposição»): trata-se do acto de cessar todo o tratamento dos seus dados em determinadas condições, nomeadamente o tratamento necessário ao desempenho de uma missão de serviço público pelo responsável pelo tratamento, ou o tratamento necessário ao os legítimos interesses do responsável pelo tratamento ou de terceiros, ou o caso em que já não pretenda que os seus dados pessoais sejam tratados para fins de prospecção comercial.",
      "O cree una cuenta local": "Ou crie uma conta local",
      "Or create a local account": "Ou crie uma conta local",
      "Or the page may not be found ...": "Ou a página pode não ser encontrada ...",
      "Or with the following providers": "Ou com os seguintes provedores",
      "Owners": "Proprietários",
      "Page not found": "Página não encontrada",
      "To create a local account you need to define a password. Your username will be your email.": "Para criar uma conta de acesso é necessário que difina uma password. O seu utilizador será a conta de e-mail.",
      "Passwords don't match": "As passwords não coincidem",
      "Passwords must be at least 6 characters.": "As senhas devem ter pelo menos 6 caracteres.",
      "Passwords must have at least one non alphanumeric character.": "As senhas devem ter pelo menos um caractere não alfanumérico.",
      "Passwords must have at least one digit ('0'-'9').": "As senhas devem ter pelo menos um dígito ('0'-'9').",
      "Passwords must have at least one lowercase ('a'-'z').": "As senhas devem ter pelo menos uma minúscula ('a'-'z').",
      "Passwords must have at least one uppercase ('A'-'Z').": "As senhas devem ter pelo menos uma maiúscula ('A'-'Z').",
      "PAT copied to clipboard!": "TAP copiado para a área de transferência",
      "Permission name": "Nome de permissão",
      "Permission": "Permissão",
      "BulkInsert": "Inserção em massa",
      "Permissions": "Permissões",
      "Permissions assigned": "Permissões atribuídas",
      "Permission display name": "Nome de exibição da permissão",
      "Personal Access Token (PAT) Created": "Token de acesso pessoal (TAP) criado",
      "Personal Access Tokens": "Token de acesso pessoal (TAP)",
      "personal-data-info": "(artigo 15º do RGPD «Direito de acesso do interessado»): se pretende saber se o grupo Fi está a processar os seus dados pessoais ou não, em caso afirmativo, obtenha uma cópia e os detalhes dos tratamentos.",
      "Personal data stored for the user: {0}": "Dados pessoais armazenados para o usuário: {0}",
      "Please add the required documents!": "Adicione os documentos necessários!",
      "Please check at least one right to exercise!": "Por favor, marque pelo menos um direito de exercer!",
      "Please check your email inbox. We have sent instructions on how reset your password.": "Por favor, verifique a sua caixa de e-mai. Enviamos instruções sobre como pode redefinir a password.",
      "Please confirm your password!": "Por favor, confirme a password!",
      "Please enter the scope!": "Por favor, coloque o âmbito!",
      "Please enter your email before continue with the password reset.": "Por favor, coloque o seu e-mail antes de redefinir a sua password.",
      "Please indicate if it is a direct request or through a representative": "Indique se é uma solicitação direta ou por meio de um representante",
      "Please input the application description!": "Por favor, insira a descrição da aplicação!",
      "Please input the application display name!": "Por favor, atribua um nome à aplicação!",
      "Please input the application private URL!": "Por favor, insira a URL privada da aplicação!",
      "Please input the application redirect URIs!": "Por favor, as URLs de redirecionamento da aplicação!",
      "Please input the email!": "Por favor, insira o email!",
      "Please input the name of the interested!": "Por favor, insira o nome do interessado!",
      "Please input the new password!": "Por favor, insira a nova senha!",
      "Please input the password confirmation!": "Por favor, insira a confirmação da senha!",
      "Please input the permission name!": "Por favor, insira o nome da permissão!",
      "Please input the permission!": "Por favor, insira da permissão!",
      "Please input the request date!": "Por favor insira a data do pedido!",
      "Please input your API title!": "Por favor, atribua o título da API!",
      "Please input your application display name!": "Por favor, insira o nome de exibição da aplicação!",
      "Please input your application id!": "Por favor, coloque o ID da aplicação!",
      "Please input your application name!": "Por favor, insira o nome da aplicação!",
      "Please input your email!": "Por favor, insira o seu e-mail!",
      "Please input your first name!": "Por favor, coloque o seu primeiro nome!",
      "Please input your group name!": "Por favor, insira o nome de seu grupo!",
      "Please input your last name!": "Por favor, coloque o seu último nome!",
      "Please input your password!": "Por favor, insira a password!",
      "Please input your PAT's name!": "",
      "Please input your redirect URIs!": "Por favor, inclua os URLs de redirecionamento!",
      "Please input the request type!": "Por favor, insira o tipo de solicitação!",
      "Please input your username!": "Por favor, insira o nome de utilizador!",
      "Please select a user to check permissions": "Por favor, selecione um usuário para verificar as permissões",
      "Please select your PAT's Application!": "Selecione o aplicativo do seu TAP!",
      "Please select your preferred language!": "Selecione seu idioma preferido!",
      "Please type a valid email!": "Por favor digite um email válido!",
      "Please enter your new password. Your username will remain the same as it was previously.": "Introduza a sua palavra-passe. Lembre-se que o nome de acesso é o seu endereço de correio electrónico.",
      "Portal settings": "Definições",
      "Portuguese": "Português",
      "Portuguese (BR)": "Português (BR)",
      "Portuguese (PT)": "Português (PT)",
      "Preferred language": "Idioma preferido",
      "Preferred language required": "Idioma preferido obrigatório",
      "Private url": "URL privada",
      "Problems to access? Contact Us!": "Tens dificuldade em iniciar sessão? Contacte-nos!",
      "Professional account (Office 365)": "Conta profissional (Office 365)",
      "Profile": "Perfil",
      "Public URI": "URL pública",
      "Redirect URIs": "Redirecionar URIs",
      "Re-enter your password": "Volte a introduzir a password",
      "Remember me": "Lembrar-me",
      "Request failed with status code 403": "Você não tem permissão para realizar esta ação",
      "Reset your password": "Redefina a password",
      "Restrict client access by scope": "Restringir o acesso de cliente por liberdades de ação",
      "Restricted": "Acesso restrito",
      "Right of access": "Direito de acesso",
      "Right to be informed (that is, to obtain a copy of the same that may be in the possession of the company)": "Direito de ser informado (ou seja, obter cópia do mesmo que porventura esteja em poder da empresa)",
      "Right to data portability": "Direito à portabilidade de dados",
      "Right to erasure (right to be forgotten)": "Direito de apagar (direito de ser esquecido)",
      "Right to object to treatment": "Direito de se opor ao tratamento",
      "Right to rectification (that is, to correct personal data, or to complete incomplete data)": "Direito de retificação (ou seja, para corrigir dados pessoais ou para completar dados incompletos)",
      "RIGHTS AND EXERCISE OF THE INTERESTED PARTIES": "DIREITOS E EXERCÍCIOS DAS PARTES INTERESSADAS",
      "rights-intro": "De acordo com as disposições do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho sobre a proteção de indivíduos no que diz respeito ao tratamento de dados pessoais e à livre circulação de tais dados, você tem vários direitos sobre os seus dados que complementam o direito à informação, permitindo-lhe ser informado sobre a recolha dos seus dados e o que lhes acontece.",
      "Representative data": "Detalhes representativos",
      "Request date": "Data do pedido",
      "Request details": "Detalhes da solicitação",
      "Request for access to your personal data": "Solicitação de acesso aos seus dados pessoais",
      "Request for limitation of treatment": "Solicitação de limitação de tratamento",
      "Request for rectification of your personal data": "Solicitação de retificação dos seus dados pessoais",
      "Request for denial of profiling": "Solicitação de negação de criação de perfil",
      "Request sent successfully": "Solicitação enviada com sucesso",
      "Request to delete your personal data": "Solicitação de exclusão de seus dados pessoais",
      "Request to object to data processing": "Solicitação de objeção ao processamento de dados",
      "Request through a representative": "Solicite através de um representante",
      "Role access scopes": "Âmbito de acesso de função",
      "Role is required": "Função é obrigatória",
      "Role not found or it is not available for selected application": "Função não encontrada ou não disponível para o aplicativo selecionado",
      "Role required": "Função necessária",
      "Role": "Função",
      "Role name": "Nome da função",
      "Roles": "Funções",
      "Roles assigned": "Funções atribuídas",
      "Row": "Fileira",
      "Save": "Salvar",
      "Save this string elsewhere because it will not be shown again": "Guarde este texto num lugar seguro porque não será mostrado novamente",
      "Scope is required": "Âmbito é necessário",
      "Scope": "Âmbito",
      "Select a user to check": "Selecione um usuário",
      "Select another authentication method": "Selecione outro método de autenticação",
      "Select request type": "Selecione o tipo de pedido",
      "Send email invite": "Enviar convite por email",
      "Shared secret": "Segredo compartilhado",
      "Sign in using your Office 365 account if you have any": "Inicie sessão utilizado a sua conta Office 365",
      "Spanish": "Espanhol",
      "Successful action": "Ação bem sucedida",
      "Terms of Use": "Termos e Condições",
      "The expiration date must be greater than today's date": "A data de validade deve ser maior que a data de hoje",
      "The passwords you entered do not match": "As senhas inseridas não são iguais",
      "This appplication is in PRE-RELEASE mode": "Esta aplicação está em modo PRÉ-LANÇAMENTO",
      "This is your newly created PAT. Save it carefuly since once you close this dialog, you will not see the token anymore": "Este é o seu TAP recém-criado. Guarde-o com cuidado, pois assim que fechar esta caixa de diálogo, você não verá mais o token",
      "to": "à",
      "To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right": "Para nos ajudar a processar sua solicitação de forma eficiente, forneça o máximo de detalhes possível sobre os dados pessoais que você solicita para exercer o seu direito",
      "Total elements": "Elementos totais",
      "Total imported": "Total importado",
      "Upload completed": "Upload completo",
      "Use an excel template to easily import": "Use um modelo de excel para importar facilmente",
      "Use an Excel template to easily import your data": "Use um modelo do Excel para importar facilmente seus dados",
      "User is required": "Utilizador é obrigatório",
      "User Management": "Gestão de utilizadores",
      "User name": "Nome de utilizador",
      "Tenant name": "Nome do Tenant",
      "Operation": "Operação",
      "User required": "Utilizador necessário",
      "User": "Utilizador",
      "Username": "Utilizador",
      "Username is required": "Utilizador é necessário",
      "Username required": "Nome de usuário necessário",
      "Users": "Utilizadores",
      "User Permissions": "Permissões do Utilizador",
      "Local user": "Utilizador local",
      "Usuario": "Utilizador",
      "View progress and goals achieved by your companies.": "Veja o estado e objetivos das suas empresas",
      "Vuelva a introducir su contraseña": "Volte a introduzir a password",
      "What right do you want to exercise?": "Que direito você deseja exercer?",
      "We help you import your information in an agile way": "Ajudamos você a importar suas informações de forma ágil",
      "We no longer know where to put so much innovation!!": "Já não sabemos onde colocar tanta inovação!!",
      "Welcome {0}!": "Olá {0}!",
      "Welcome {0}": "Olá {0}",
      "Welcome": "Olá",
      "Welcome to": "Bem-vindo ao",
      "(without description)": "(sem descrição)",
      "(without name)": "(sem nome)",
      "Yes": "Sim",
      "You can add more roles later from the user edit screen.": "Pode adicionar mais funções posteriormente nas configurações do utilizador.",
      "You dont have access to this resource. You might not have the required permissions. Please, contact your administrator for more information.": "Não tem acesso a este recurso. Pode não ter as permissões necessárias. Por favor, entre em contacto com o administrador para mais informações.",
      "You dont have permission to obtain a PAT token for this application": "Você não tem permissão para obter um token PAT para este aplicativo",
      "Your information": "Sua informação",
      "Your picture": "Sua foto",
      "Your Profile": "Seu perfil",
      "Policies": "Políticas",
      "Policy Body": "Órgão político",
      "Areas": "Áreas",
      "Area": "Área",
      "Is Default Area": "É a área padrão",
      "Is System Area": "É área do sistema",
      "Tenants": "Tenants",
      "Helpdesk": "Helpdesk",
      "Title": "Título",
      "Changes have been saved": "Mudanças foram salvas",
      "Click to sort descending": "Clique para classificar decrescente",
      "Click to sort ascending": "Clique para classificar em ordem crescente",
      "Click to cancel sorting": "Clique para cancelar a classificação",
      "Export": "Exportar",
      "New Policy": "Nova Política",
      "Edit Policy": "Editar Política",
      "OK": "Aceitar",
      "Please input your policy title!":"Digite o título da sua apólice!",
      "Policy Id must have less than 200 chars.":"O ID da política deve ter menos de 200 caracteres.",
      "Logging in": "Fazendo login",
      "My disclaimers": "Meus avisos",
      "Disclaimers": "Isenções de responsabilidade",
      "Accept": "Aceitar",
      "Reason": "Razão",
      "Date": "Data",
      "Login Result": "Resultado de login",
      "Authentication Provider": "Provedor de Autenticação",
      "Success": "Sucesso",
      "Failure": "Falha",
      "UserLocked": "Usuário bloqueado",
      "Select Login Result...": "Selecione o resultado de login...",
      "Select Authentication Provider...": "Selecione o provedor de autenticação...",
      "Select Operation...": "Selecione Operação...",
      "Select User...": "Selecione usuário...",
      "Select Tenant...": "Selecione tenant...",
      "Application Name": "Nome do aplicativo",
      "Search into Metadata?": "Pesquisar em metadados?",
      "Authentication Logs": "Registros de Autenticação",
      "System Logs": "Registros do sistema",
      "Login Success": "Sucesso de login",
      "User Locked": "Usuário bloqueado",
      "Invalid credentials": "Credenciais inválidas",
      "Unknown error" : "Erro desconhecido",
      "Please verify your email." : "Verifique seu e-mail.",
      "Include Users Global" : "Incluir usuários globais",
      "Locked" : "Bloqueado",
      "Unlocked" : "Desbloqueado",
      "Status" : "Estado",
      "The user": "O usuário",
      "has been unlocked" : "foi desbloqueado",
      "User Unlocked":"Utilizador desbloqueado",
      "User Locked Permanently": "Utilizador bloqueado permanentemente",
      "Permanent lock":"Bloqueio permanente",
      "Your password has expired, please create a new password to login.": "A sua palavra-passe expirou, crie uma nova palavra-passe para iniciar sessão.",
      "Applied":"Aplicado",
      "Waiting for Approver":"Aguardando o aprovador",
      "Cancelled":"Cancelado",
      "Rejected":"Rejeitado",
      "RollBack":"Reverter",
      "Incorrect username or password, you have 1 attempts left or your account will be locked" : "Credenciais incorretas, a queda de 1 intenção ou sua conta será bloqueada",
      "Incorrect username or password, you have 2 attempts left or your account will be locked" : "Credenciais incorretas, a queda de 2 intenção ou sua conta será bloqueada",
      "Incorrect username or password, you have 3 attempts left or your account will be locked" : "Credenciais incorretas, a queda de 3 intenção ou sua conta será bloqueada",
      "Incorrect username or password, you have 4 attempts left or your account will be locked" : "Credenciais incorretas, a queda de 4 intenção ou sua conta será bloqueada",
      "Incorrect username or password, you have 5 attempts left or your account will be locked" : "Credenciais incorretas, a queda de 5 intenção ou sua conta será bloqueada",
      "User Is Locked Out": "O usuário está bloqueado",
      "User does not exist": "Usuário não existe",
      "Are you sure to disable the MFA factor?":"Tem certeza de desativar o modo MFA?",
      "Code expired!":"O código expirou!",
      "Resend Code":"Reenviar código",
      "A code was sent to your Email, please type code for login. The code will expire in 5 minutes":"Um código foi enviado para seu e-mail, digite o código para login. O código irá expirar em 5 minutos.",
      "Minutes":"Minutos",
      "Seconds":"Segundos",  
      "Submit":"Enviar",
	  "Error validating TwoAuthentification token for invalid code":"Código incorrecto",
      "Hi, ":"Olá, ",
      "Cannot reset password, user is locked":"Não é possível redefinir a senha, o usuário está bloqueado",
      "New User":"Novo usuário",
      "User Information":"Informação do usuário",
      "User's Permissions":"Permissões do usuário",
      "Create Operation Request":"Solicitação de operação",
      "Operation Requests":"Solicite uma operação",
      "Area required":"Por favor entre a área!",
      "Work Position":"Posição de trabalho",
      "Phone Number":"Número de telefone",
      "Departments":"Departamentos",
      "Save and exit":"Salvar e sair",
      "Next":"Seguinte",
      "Clients": "Clientes",
      "Result":"Resultado",
      "Provider":"Fornecedor",
      "ReturnUrl":"URL",
      "User locked. Draft User!": "Aprovação pendente do usuário",
      "Access Requests": "Solicitações de acesso",
      "You are validating access to FI Connect to users outside the company. Ensure that users must access the requested accounts and each application with the requested role":"Você está validando o acesso ao FI Connect para usuários fora da empresa. Certifique-se de que os usuários devem acessar as contas solicitadas e cada aplicativo com a função solicitada",
      "Are you sure you want to remove all permissions from all current tenants for this user?":"Tem certeza de que deseja remover todas as permissões de todos os locatários atuais deste usuário?",
      "Bulk assignment of permissions to existing users of this Tenant":"Atribuição em massa de permissões a usuários existentes deste tenant",
      "Bulk loading of users and permissions for this Tenant": "Carregamento em massa de usuários e permissões para este tenant",
      "You do not have access to Users group permission assignment": "Você não tem acesso à atribuição de permissões do grupo de Usuários",
      "Global roles only": "Apenas papéis globais",
      "MFA Configuration": "MFC Configuração",
      "Running": "Em curso",
      "Stopped": "Parado",
      "Maintenance": "Manutenção",
      "Connected per App last 30 minutes": "Ligado por aplicação nos últimos 30 minutos",
      "Users Connected": "Utilizadores ligados",
      "Global Administrator per Areas": "Administrador global por área",
      "Global Administrator per App": "Administrador global por aplicação",
      "Global Administrators": "Administradores globais",
      "Login success per hour": "Sucesso de login por hora",
      "Logs": "Registos",
      "Global Admin": "Administrador global",
      "Polaris Permission Create": "Polaris Permissão para Criar",
      "Invitation mail sent successfully.": "O e-mail de convite foi enviado com sucesso.",
      "Failed to send invitation mail. Please try again later.": "Falha ao enviar o convite por correio eletrónico. Por favor, tente novamente mais tarde.",
      "Select Api Entry...": "Selecionar entrada de API...",
      "Api Entry": "Entrada de API",
      "Select a date...": "Selecionar uma data...",
      "Start date": "Data de início",
      "End date": "Data de término",
      "Access Per User. Date Range": "Acesso por usuário. Intervalo de datas",
      "User Access": "Acesso do usuário",
      "Select a Time Range": "Selecione um intervalo de tempo",
      "The 'Identity' application cannot be deleted.": "O aplicativo 'Identity' não pode ser excluído.",
      "The User is not the owner of the application and cannot delete it.": "O usuário não é o proprietário do aplicativo e não pode excluí-lo.",
      "Application deleted successfully.": "Aplicativo excluído com sucesso.",
      "Failed to delete the application. Please try again later.": "Falha ao excluir o aplicativo. Por favor, tente novamente mais tarde.",
      "Image uploaded successfully": "Imagem carregada com sucesso.",
      "At least 12 characters long.": "Pelo menos 12 caracteres de comprimento.",
      "At least one uppercase letter, one lowercase letter, one number, and one symbol.": "Pelo menos uma letra maiúscula, uma letra minúscula, um número e um símbolo.",
      "Password must be changed every 90 days.": "A senha deve ser alterada a cada 90 dias.",
      "Cannot reuse any of the last 7 passwords.": "Não é possível reutilizar nenhuma das últimas 7 senhas.",
      "Password Requirements": "Requisitos de senha",
      "Approve Operation Request": "Aprovação de solicitação de operação",
      "There's already a group named {0}": "Já existe um grupo chamado {0}", 
      "Your session will expire in": "Sua sessão irá expirar em",
      "Go to": "Vá para",
      }, 
    "pt-br": {
      "Invalid or expired token": "Link de conexão inválido ou expirado. Entre em contato com a pessoa de contato do FI Group ou com o administrador de controle de tempo para que ele possa enviar um novo link.",
      "Access Per User. Date Range": "Acesso por usuário. Intervalo de datas",
      "User Access": "Acesso do usuário",
      "Select a Time Range": "Selecione um intervalo de tempo",
      "The 'Identity' application cannot be deleted.": "O aplicativo 'Identity' não pode ser excluído.",
      "The User is not the owner of the application and cannot delete it.": "O usuário não é o proprietário do aplicativo e não pode excluí-lo.",
      "Application deleted successfully.": "Aplicativo excluído com sucesso.",
      "Failed to delete the application. Please try again later.": "Falha ao excluir o aplicativo. Por favor, tente novamente mais tarde.",
      "Image uploaded successfully": "Imagem enviada com sucesso.",
      "There's already a group named {0}": "Já existe um grupo chamado {0}"
      },
    "it": {
      "Remove this app from your favorites":"Rimuovi questa app dai tuoi preferiti",
      "My Clients": "I Miei clienti",
      "Are you sure that you want to discard these changes?" :  " Sei sicuro di voler annullare queste modifiche? " ,
      "Show hierarchy" :  " Mostra la gerarchia " ,
      "Customized?" :  " Costume? " ,
      "Tenants exported successfully" :  " Tenant esportati correttamente " ,
      "Export Tenants Success": " Tenant esportati correttamente ",
      "Export Member Success": "Membri esportati con successo",
      "Download File" :  " Download File " ,
      "Select Area..." :  " Seleziona zona... " ,
      "Select Creation type..." :  " Seleziona il tipo di creazione... " ,
      "Creation" :  " Creazione " ,
      "Auto" :  " Auto " ,
      "Manual" :  " Manuale " ,
      "Send" :  " Inviare " ,
      "Show only favorites applications":"Mostra solo le applicazioni preferite",
      "Sort applications":"Ordina le applicazioni",
      "Add this app to your favorites":"Aggiungi questa app ai tuoi preferiti",
      "Are you sure to revoke this access?" :  " Sei sicuro di revocare questo accesso? " ,
      "Choose OK to Login and continue..." :  " Scegli OK per accedere e continuare... " ,
      "Your session has expired." :  " La tua sessione è scaduta. " ,
      "FI-Group password change" :  " Cambio password gruppo FI " ,
      "Invitation FI GROUP" :  "Invito al FI GROUP" ,
      "Welcome to FI GROUP" :  " Benvenuti nel GRUPPO FI " ,
      "FI Connect is a digital applications HUB created by FI Group that seeks to transform and optimize the relationship we have with our customers.": "FI Connect è un HUB di applicazioni digitali creato da FI Group per trasformare e ottimizzare il rapporto con i clienti.",
      "Please confirm your invitation" :  " Conferma il tuo invito di accesso " ,
      "Operation Completed Successfully" :  " Operazione completata con successo " ,
      "Your operation will be processed in background." :  " L'operazione verrà completata in background. " ,
      "Is Owner" :  " Proprietario " ,
      "Are you sure to revoke all user Access?" :  " Sei sicuro di revocare tutti gli accessi utente? " ,
      "Global roles" :  " Ruoli globali " ,
      "Group's Users" :  " utenti del gruppo " ,
      "Add Tenant Roles" :  " Aggiungi ruoli tenant " ,
      "(Empty Email)" :  " (Email vuota) " ,
      "(Empty Name)" :  " Nome vuoto " ,
      "Clear All" :  " Cancella tutto " ,
      "Add Member to Tenant" :  " Aggiungi membro al tenant " ,
      "elements deleted sussessful" :  " elementi rimossi con successo " ,
      "Delete elements" :  " Eliminare elementi " ,
      "Identity Tenants" :  " Inquilini di identità " ,
      "Apply to this entity and all its descendants" :  " Applicare a questa entità ea tutti i suoi discendenti " ,
      "Start typing to search for applications..." :  " Inizia a digitare per trovare le app... " ,
      "Add new user role" :  " Aggiungi nuovo ruolo utente " ,
      "Group Name" :  " Nome del gruppo " ,
      "Entities" :  " Entità " ,
      "European VAT" :  " IVA Europeo " ,
      "Ok" :  " Accettare " ,
      "Hide in user List": "nascondi nell'elenco degli utenti",
      "This file will be processed in the background." :  " Questo file verrà elaborato in background " ,
      "Upload Successful" :  " caricamento riuscito " ,
      "New Notification" :  " Nuova notifica " ,
      "Upload complete" :  " Pieno carico " ,
      "Identities" :  " Identità " ,
      "Add member to Group" :  " Aggiungi membro al gruppo " ,
      "Group Details" :  " Dettagli del gruppo " ,
      "Group Users" :  " Utenti del gruppo " ,
      "Add Tenant Roles to User" :  " Aggiungere ruoli tenant all'utente " ,
      "Add Tenant Roles to Group" :  " Aggiungere ruoli tenant al gruppo " ,
      "Tenant" :  " Inquilino " ,
      "Global" :  " Globale " ,
      "Hierarchy" :  " Gerarchia " ,
      "Parent" :  " Padre " ,
      "View Number" :  " Numero di vista " ,
      "New Entity" :  " Nuova entità " ,
      "Please enter a title" :  " Inserisci un titolo per favore " ,
      "Area is requiered" :  " l'area è obbligatoria " ,
      "Select entity" :  " Seleziona un'entità " ,
      "Tax Number" :  " Codice fiscale " ,
      "Change Parent Tenant" :  "Cambia Inquilino Principale" ,
      "Edit" :  " Modificare " ,
      "Display Name" :  " Nome da visualizzare " ,
      "Inheritance" :  " Eredità " ,
      "Inherited" :  " Ereditato " ,
      "Tenant Details" :  " Dettagli dell'inquilino " ,
      "Terms of service" :  " Condizioni d'uso " ,
      "Job Title" :  " Carico " ,
      "Manager" :  " Gestore " ,
      "Teams" :  " Squadre " ,
      "(2MB size limit)" :  " (Limite dimensione 2MB) " ,
      "2MB files size limit exceeded" :  " Limite di dimensione del file di 2 MB superato " ,
      "Access is denied" :  " Accesso negato " ,
      "Add new" :  " Aggiungi un nuovo elemento " ,
      "Already exists an app with this app id" :  " Esiste già un'app con questo ID app " ,
      "Already exists an app with this app id and name" :  " Esiste già un'applicazione con questo ID e nome dell'applicazione " ,
      "Already exists an app with this name" :  " Esiste già un'app con questo nome " ,
      "An error ocurred" :  " C'è stato un errore " ,
      "API Resources" :  " Risorse dell'API " ,
      "App URI" :  " URI dell'applicazione " ,
      "Application Id" :  " ID dell'app " ,
      "Application Id must have less than 200 chars." :  " L'ID applicazione deve contenere meno di 200 caratteri. " ,
      "Application name" :  " Nome dell'applicazione " ,
      "Application not found" :  " Applicazione non trovata " ,
      "Application required": " Applicazione richiesta ",
      "Please enter your password. Remember that the login name is your email address.": "Inserire la password. Ricordate che il nome utente è il vostro indirizzo e-mail.",
      "Invalid or expired token": "Link di connessione non valido o scaduto. Contattare il referente del gruppo FI o l'amministratore di Time Tracking per farsene inviare uno nuovo.",
      "Application shared secret": " Chiave segreta dell'applicazione ",
      "Application" :  " Applicazione " ,
      "Applications" :  " Applicazioni " ,
      "Applications which is a member" :  " App di cui sei membro " ,
      "Are you sure you want to change this entity parent?" :  " Sei sicuro di voler cambiare il padre di questa entità? " ,
      "This action can affect users permissions" :  " Questa azione potrebbe influire sulle autorizzazioni utente. " ,
      "Are you sure want to delete selected items?" :  " Sei sicuro di voler eliminare gli elementi selezionati? " ,
      "Are you sure want to reset your password? An email will be sent to" :  " Sei sicuro di voler reimpostare la tua password? Verrà inviata un'e-mail a " ,
      "Attach to this application a copy of your identification, or where appropriate, a copy of the order and identification of the representative" :  " Allegare a questa domanda una copia della propria identificazione o, se del caso, una copia dell'ordine e l'identificazione del rappresentante " ,
      "Base Application" :  " applicazione di base " ,
      "Base role" :  " Rol base " ,
      "Basic info" :  " Informazioni di base " ,
      "Bulk upload" :  " Caricamento in blocco " ,
      "Bulk upload items" :  " Caricamento in blocco " ,
      "Bulk Upload Options" :  " Opzioni di caricamento collettivo " ,
      "Bulk Insert Users" :  " Utenti di inserimento collettivo " ,
      "Bulk Insert Users's Role" :  " Inserimento collettivo di ruoli utente " ,
      "Uploaded User'Roles have been created/updated" :  " I ruoli utente caricati sono stati creati/aggiornati " ,
      "Uploaded Group'Roles have been created/updated" :  " I ruoli di gruppo caricati sono stati creati/aggiornati " ,
      "Uploaded User have been created/updated" :  " Gli utenti caricati sono stati creati/aggiornati " ,
      "Bulk Insert Users's Roles" :  " Inserimento collettivo di ruoli utente " ,
      "Bulk Insert Groups's Roles" :  " Inserisci i ruoli di gruppo in blocco " ,
      "Cancel" :  " Annulla " ,
      "Cannot reset password at this stage" :  " Impossibile reimpostare la password in questa fase " ,
      "Cargando" :  " Ricarica " ,
      "Change password" :  " Cambia la password " ,
      "Check User Permissions" :  " Controlla i permessi degli utenti " ,
      "Click or drag file to this area to upload" :  " Fare clic o trascinare il file in quest'area per caricarlo " ,
      "Click or drag file to update the app picture" :  " Fare clic o trascinare un file per aggiornare l'immagine dell'app " ,
      "Click or drag file to update your profile picture" :  " Fai clic o trascina un file per aggiornare la foto del tuo profilo " ,
      "Close session" :  " Cancella la sottoscrizione " ,
      "Configure clients, workgroups and companies" :  " Configurazione di client, gruppi di lavoro e aziende " ,
      "Confirm password" :  " Conferma password " ,
      "Confirmation required" :  " Conferma richiesta " ,
      "Cookies Policy" :  " Politica sui cookie " ,
      "Copyright" :  " Diritto d'autore " ,
      "Copy to clipboard" :  " Copia negli appunti " ,
      "Created on" :  " Creato il " ,
      "Create Password" :  " Crea una password " ,
      "data-delete-info" :  " (Articolo 17 del GDPR «Diritto alla cancellazione (»diritto all'oblio«)»): Se non vuoi più che Fi group tratti i tuoi dati, revoca il tuo consenso, così come qualsiasi link, copia o riproduzione dei tuoi dati. " ,
      "data-portability-info" :  " (art. 20 GDPR «Diritto alla portabilità dei dati»): è l'atto di richiedere l'esportazione dei propri dati e di trasferirli ad altra società. " ,
      "data-rectification-info" :  " (art. 16 GDPR “Diritto di rettifica”): il caso in cui ritieni che alcuni dei tuoi dati siano inesatti o incompleti e richiedi che vengano aggiornati al più presto. " ,
      "Data portability request" :  " Richiesta di portabilità dei dati " ,
      "Data Subject Right" :  " Elaborazione dati " ,
      "Delete selected" :  " Eliminare elementi selezionati " ,
      "denial-profiling-info" :  " (art. 22 GDPR “Decisione individuale automatizzata, compresa la profilazione”): si tratta di richiedere di non essere oggetto di una decisione individuale automatizzata. " ,
      "Description" :  " Descrizione " ,
      "Description must have less than 1000 chars." :  " La descrizione deve contenere meno di 1000 caratteri. " ,
      "Direct request" :  " Richiesta diretta " ,
      "Display name" :  " Nome " ,
      "Display name must have less than 200 chars." :  " Il nome deve contenere meno di 200 caratteri. " ,
      "Display name must have less than 256 chars." :  " Il nome deve contenere meno di 256 caratteri. " ,
      "Done" :  " Elenco " ,
      "Download import template" :  " Scarica il modello di importazione " ,
      "Download template" :  " Scarica modello " ,
      "Do you want to access these applications? Ask us!" :  " Vuoi accedere a queste applicazioni? Chiedici! " ,
      "Each URI must have 2000 chars max." :  " Ogni URI deve contenere al massimo 2000 caratteri. " ,
      "El inicio de sesión ha terminado con éxito" :  " Il login è andato a buon fine " ,
      "Email missing" :  " Email mancante " ,
      "Email" :  " E-mail " ,
      "Email required" :  " Email (obbligatorio " ,
      "Enable the application" :  " Abilita l'app " ,
      "Enabled" :  " Abilitato " ,
      "English" :  " Inglese " ,
      "Enter your password" :  " Inserisci la tua password " ,
      "Enter your username" :  " Inserisci il tuo nome utente " ,
      "Error" :  " Errore " ,
      "Errors" :  " Errori " ,
      "Exercise rights":  " Esercitare diritti " ,
      "EXERCISE YOUR RIGHTS":  " ESERCITARE DIRITTI " ,
      "Expires at" :  " Scade il " ,
      "Export to Excel" :  " Esportare in Excel " ,
      "Facebook account" :  " Account Facebook " ,
      "FI Group all rights reserved" :  " Gruppo FI, tutti i diritti riservati " ,
      "Fi Group users have to connect through Office 365 professional account" :  " Gli utenti di Fi Group devono connettersi tramite un account aziendale di Office 365 " ,
      "Filter by application" :  " Filtra per applicazione " ,
      "First name" :  " Nome " ,
      "Forgot your password?" :  " Hai dimenticato la tua password? " ,
      "French" :  " Francese " ,
      "Full name" :  " Nome e cognome " ,
      "General properties" :  " Proprietà generali " ,
      "Google account" :  " account Google " ,
      "Grant search service" :  " Concedere il servizio di ricerca " ,
      "Grant" :  " concedere " ,
      "Grants" :  " sovvenzioni " ,
      "Group is required" :  " Il gruppo è obbligatorio " ,
      "Group required" :  " gruppo richiesto " ,
      "Group roles" :  " ruoli di gruppo " ,
      "Group" :  " Gruppo " ,
      "Group name" :  " Nome del gruppo " ,
      "Groups" :  " Gruppi " ,
      "Groups assigned" :  " Gruppi assegnati " ,
      "Icon (Ant Icon, URL or base64 image)" :  " Icona (icona della formica, URL o immagine base64) " ,
      "Icon" :  " Icona " ,
      "Identity management" :  " Gestione dell'identità " ,
      "Incorrect username or password" :  " Nome utente o password non corretti " ,
      "Invalid preferred language" :  " Lingua preferita non valida " ,
      "Invalit token." :  " Gettone non valido. " ,
      "Italian" :  " Italiano " ,
      "In case of being requested by representation, please indicate the representative's data" :  " In caso di richiesta tramite rappresentanza, si prega di indicare i dati del rappresentante " ,
      "Log in" :  " Accesso " ,
      "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna" :  " Accedi utilizzando il tuo account Office 365 se ne hai uno " ,
      "Import template" :  " Modello di importazione " ,
      "Input search text..." :  " Cerca testo... " ,
      "Introduzca su contraseña" :  " Inserisci la tua password " ,
      "Introduzca su nombre de usuario" :  " Inserisci il tuo nome utente " ,
      "Invalid email address." :  " Indirizzo email non valido. " ,
      "It seems that you dont have access to any application. Please contact your administartor" :  " Apparentemente non hai accesso a nessuna applicazione. Contatta il tuo amministratore " ,
      "Last name" :  " Cognomi " ,
      "limitation-treatment-info" :  " (art. 18 del GDPR “Diritto alla limitazione del trattamento”): il caso in cui desideri poter isolare i tuoi dati affinché siano conservati solo temporaneamente in modo che i tuoi dati non possano, salvo che per la conservazione, essere trattati solo con il tuo consenso. " ,
      "Loading" :  " Ricarica " ,
      "Local User" :  " Utente locale " ,
      "Login" :  " Accesso " ,
      "Login has successfully ended" :  " Il login è andato a buon fine " ,
      "Manage your R&D claims with this application" :  " Gestisci le tue richieste di ricerca e sviluppo con questa applicazione " ,
      "Manages applications, users, groups and permissions" :  " Gestisci applicazioni, utenti, gruppi e permessi " ,
      "Manages receipts & taxes" :  " Gestisci ricevute e tasse " ,
      "Manual assigned roles" :  " Ruoli assegnati manualmente " ,
      "Member of" :  " Membro di " ,
      "Members" :  " Membri " ,
      "Modified on" :  " Modificato in " ,
      "My data" :  " I miei dati " ,
      "My profile" :  " Il mio profilo " ,
      "Name and surname of the interested" :  " Nome e cognome dell'interessato " ,
      "Name is required" :  " Il nome è obbligatorio " ,
      "Name" :  " Nome " ,
      "New application" :  " Nuova applicazione " ,
      "New group" :  " Nuovo gruppo " ,
      "New group's scope" :  " Nuovo ambito di gruppo " ,
      "New password" :  " Nuova password " ,
      "New Permission" :  " Nuovo permesso " ,
      "New Personal Access Token (PAT)" :  " Nuovo token di accesso personale (TAP) " ,
      "New role" :  " Nuovo ruolo " ,
      "New role's scope" :  " Nuovo ambito di ruolo " ,
      "New user" :  " Nuovo utente " ,
      "No" :  " No " ,
      "No data" :  " Non ci sono dati " ,
      "No groups found for this user" :  " Nessun gruppo trovato per questo utente " ,
      "No permissions found for this user" :  " Nessuna autorizzazione trovata per questo utente " ,
      "Notifications" :  " Notifiche " ,
      "Open Result" :  " Risultati aperti " ,
      "No Notifications" :  " Nessuna notifica " ,
      "object-data-info" :  " (Articolo 21 GDPR «Diritto di opposizione»): Si tratta dell'atto di interrompere ogni trattamento dei tuoi dati a determinate condizioni, vale a dire il trattamento necessario per l'esecuzione di una missione di servizio pubblico da parte del titolare del trattamento, o il trattamento necessario per le finalità del legittimo interessi perseguiti dal titolare del trattamento o da terzi, o il caso in cui non desideri più che i tuoi dati personali vengano trattati per finalità di prospezione commerciale. " ,
      "O cree una cuenta local" :  " Oppure crea un account locale " ,
      "Or create a local account" :  " Oppure crea un account locale " ,
      "Or the page may not be found ..." :  " Oppure la pagina potrebbe non essere trovata... " ,
      "Or with the following providers" :  " Oppure con i seguenti fornitori " ,
      "Owners" :  " Proprietario " ,
      "Page not found" :  " Pagina non trovata " ,
      "Password" :  " Parola d'ordine " ,
      "Passwords don't match" :  " Le passwords non corrispondono " ,
      "Password has been successfully reseted" :  " La password è stata modificata con successo " ,
      "Passwords must be at least 6 characters." :  " Le password devono contenere almeno 6 caratteri. " ,
      "Passwords must have at least one non alphanumeric character." :  " Le password devono contenere almeno un carattere non alfanumerico. " ,
      "Passwords must have at least one digit ('0'-'9')." :  " Le password devono contenere almeno una cifra ('0'-'9'). " ,
      "Passwords must have at least one lowercase ('a'-'z')." :  " Le password devono contenere almeno una lettera minuscola ('a'-'z'). " ,
      "Passwords must have at least one uppercase ('A'-'Z')." :  " Le password devono contenere almeno una lettera maiuscola ('A'-'Z'). " ,
      "PAT copied to clipboard!" :  " TAP copiato negli appunti! " ,
      "Permission name" :  " Nome " ,
      "Permission": " Autorizzazione ",
      "BulkInsert": " Inserimento di massa ",
      "Permissions" :  " Autorizzazioni " ,
      "Permissions assigned" :  " Autorizzazioni assegnate " ,
      "Permission display name" :  " Nome dell'autorizzazione " ,
      "Personal Access Token (PAT) Created" :  " Token di accesso personale (TAP) creato " ,
      "Personal Access Tokens" :  " Token di accesso personale " ,
      "personal-data-info" :  " (art. 15 GDPR “Diritto di accesso dell'interessato”): se vuole sapere se Fi group sta trattando o meno i suoi dati personali, in tal caso, ottenerne copia e dettagli dei trattamenti. " ,
      "Personal data stored for the user: {0}" :  " Dati personali memorizzati per l'utente: {0} " ,
      "Please add the required documents!" :  " Si prega di aggiungere i documenti richiesti! " ,
      "Please check at least one right to exercise!" :  " Seleziona almeno un diritto da esercitare! " ,
      "Please check your email inbox. We have sent instructions on how reset your password." :  " Si prega di controllare la posta in arrivo. Abbiamo inviato istruzioni su come reimpostare la password. " ,
      "Please confirm your password!" :  " Conferma la tua password! " ,
      "Please enter the scope!" :  " Si prega di inserire l'ambito! " ,
      "Please enter your email before continue with the password reset." :  " Inserisci l'email prima di procedere con il reset della password. " ,
      "Please indicate if it is a direct request or through a representative" :  " Si prega di indicare se si tratta di una richiesta diretta o tramite un rappresentante " ,
      "Please input the application description!" :  " Inserisci la descrizione dell'applicazione! " ,
      "Please input the application display name!" :  " Inserisci il nome dell'applicazione! " ,
      "Please input the application private URL!" :  " Inserisci l'URL privato dell'app! " ,
      "Please input the application redirect URIs!" :  " Inserisci gli URL di reindirizzamento dell'applicazione! " ,
      "Please input the email!" :  " Per favore inserisci l'e-mail! " ,
      "Please input the name of the interested!" :  " Inserisci il nome dell'interessato! " ,
      "Please input the new password!" :  " Inserisci la nuova password! " ,
      "Please input the password confirmation!" :  " Si prega di inserire la conferma della password! " ,
      "Please input the permission name!" :  " Inserisci il nome del permesso! " ,
      "Please input the permission!" :  " Per favore inserisci il permesso! " ,
      "Please input the request date!" :  " Inserisci la data della richiesta! " ,
      "Please input your API title!" :  " Inserisci il titolo della tua API! " ,
      "Please input your application display name!" :  " Inserisci il nome della tua applicazione! " ,
      "Please input your application id!" :  " Inserisci l'identificatore dell'app! " ,
      "Please input your application name!" :  " Inserisci il nome della tua applicazione! " ,
      "Please input your email!" :  " Inserisci la tua email! " ,
      "Please input your first name!" :  " Per favore inserisci il tuo nome! " ,
      "Please input your group name!" :  " Inserisci il nome del tuo gruppo! " ,
      "Please input your last name!" :  " Per favore inserisci i tuoi cognomi! " ,
      "Please input your password!" :  " Per favore inserisci LA TUA password! " ,
      "Please input your PAT's name!" :  " Inserisci il nome del tuo TAP! " ,
      "Please input your redirect URIs!" :  " Inserisci gli URL di reindirizzamento della tua app! " ,
      "Please input the request type!" :  " Inserisci il tipo di richiesta! " ,
      "Please input your username!" :  " Per favore inserisci il tuo cognome! " ,
      "Please select a user to check permissions" :  " Seleziona un utente per controllare le autorizzazioni " ,
      "Please select your PAT's Application!" :  " Seleziona l'applicazione del tuo TAP! " ,
      "Please select your preferred language!" :  " Si prega di selezionare la lingua preferita! " ,
      "Please type a valid email!" :  " Si prega di scrivere una email valida! " ,
      "Please enter your new password. Your username will remain the same as it was previously.": "Inserire la password. Ricordate che il nome di accesso è il vostro indirizzo e-mail.",
      "Portal settings" :  " Configurazione del portale " ,
      "Portuguese" :  " Portoghese " ,
      "Portuguese (BR)" :  " Portoghese (BR) " ,
      "Portuguese (PT)" :  " Portoghese (PT) " ,
      "Preferred language" :  " Lingua preferita " ,
      "Preferred language required" :  " Lingua preferita richiesta " ,
      "Private url" :  " URI privato " ,
      "Problems to access? Contact Us!" :  " Hai problemi di accesso? Contattaci! " ,
      "Professional account (Office 365)" :  " Account di lavoro (Office 365) " ,
      "Profile" :  " Profilo " ,
      "Public URI" :  " URI pubblico " ,
      "Remember me" :  " Ricordati di me " ,
      "Redirect URIs" :  " URI di reindirizzamento " ,
      "Re-enter your password" :  " Ripeti la password " ,
      "Request failed with status code 403" :  " Non sei autorizzato a eseguire questa azione " ,
      "Reset your password" :  " Reimposta la tua password " ,
      "Restrict client access by scope" :  " Limita l'accesso client in base all'ambito " ,
      "Restricted" :  " Limitato " ,
      "Right of access" :  " Diritto di accesso " ,
      "Right to be informed (that is, to obtain a copy of the same that may be in the possession of the company)" :  " Diritto di essere informato (ovvero di ottenere copia della stessa eventualmente in possesso della società) " ,
      "Right to data portability" :  " Diritto alla portabilità dei dati " ,
      "Right to erasure (right to be forgotten)" :  " Diritto alla cancellazione (diritto all'oblio) " ,
      "Right to object to treatment" :  " Diritto di opporsi al trattamento " ,
      "Right to rectification (that is, to correct personal data, or to complete incomplete data)" :  " Diritto alla rettifica (ovvero, per correggere i dati personali o per completare i dati incompleti) " ,
      "RIGHTS AND EXERCISE OF THE INTERESTED PARTIES" :  " DIRITTI ED ESERCIZIO DEGLI INTERESSATI " ,
      "rights-intro" :  " In conformità con le disposizioni del regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati, hai diversi diritti sui tuoi dati che integrano il diritto all'informazione consentendoti di essere informato sulla raccolta dei tuoi dati e su ciò che accade loro. " ,
      "Representative data" :  " Dati rappresentativi " ,
      "Request date" :  " Data di applicazione " ,
      "Request details" :  " Dettagli della richiesta " ,
      "Request for access to your personal data" :  " Richiesta di accesso ai propri dati personali " ,
      "Request for limitation of treatment" :  " Richiesta di limitazione del trattamento " ,
      "Request for rectification of your personal data" :  " Richiesta di rettifica dei dati personali " ,
      "Request for denial of profiling" :  " Richiesta di negazione della profilazione " ,
      "Request sent successfully" :  " Richiesta inviata con successo " ,
      "Request to delete your personal data" :  " Richiedi la cancellazione dei tuoi dati personali " ,
      "Request to object to data processing" :  " Richiesta di opposizione al trattamento dei dati " ,
      "Request through a representative" :  " Richiesta tramite un rappresentante " ,
      "Role access scopes" :  " Ambiti di accesso al ruolo " ,
      "Role is required" :  " Il ruolo è richiesto " ,
      "Role required" :  " Ruolo richiesto " ,
      "Role" :  " Ruolo " ,
      "Role name" :  " nome del ruolo " ,
      "Role not found or it is not available for selected application" :  " Ruolo non trovato o non disponibile per l'applicazione selezionata " ,
      "Roles" :  " Ruoli " ,
      "Roles assigned" :  " Ruoli assegnati " ,
      "Row" :  " Fila " ,
      "Save" :  " Mantenere " ,
      "Save this string elsewhere because it will not be shown again" :  " Salva questo valore da qualche altra parte perché non verrà più visualizzato " ,
      "Scope is required" :  " L'ambito è obbligatorio " ,
      "Scope" :  " Scopo " ,
      "Select a user to check" :  " Seleziona un utente " ,
      "Select another authentication method" :  " Seleziona un altro metodo di autenticazione " ,
      "Select request type" :  " Seleziona il tipo di richiesta " ,
      "Send email invite" :  " Invia invito via e-mail " ,
      "Shared secret" :  " Chiave segreta " ,
      "Sign in using your Office 365 account if you have any" :  " Accedi utilizzando il tuo account Office 365 se ne hai uno " ,
      "Spanish" :  " Spagnolo " ,
      "Successful action" :  " Azione riuscita " ,
      "Terms of Use" :  " Termini e Condizioni " ,
      "The expiration date must be greater than today's date" :  " La data di scadenza deve essere successiva alla data corrente " ,
      "The passwords you entered do not match" :  " Le password digitate non corrispondono " ,
      "This appplication is in PRE-RELEASE mode" :  " Questa applicazione è in modalità PRE-RELEASE " ,
      "This is your newly created PAT. Save it carefuly since once you close this dialog, you will not see the token anymore" :  " Questo è il TAP appena creato. Salvalo attentamente poiché una volta chiusa questa finestra di dialogo non vedrai più il token " ,     
      "to" :  " un " ,
      "To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right" :  " Per aiutarci a elaborare la tua richiesta in modo efficiente, ti preghiamo di fornire quanti più dettagli possibili sui dati personali che stai richiedendo per esercitare il tuo diritto. " ,
      "To create a local account you need to define a password. Your username will be your email." :  " Per creare un account locale, è necessario definire una password. Il tuo nome utente sarà la tua email. " ,
      "Total elements" :  " Elementi totali " ,
      "Total imported" :  " totale importato " ,
      "Upload completed" :  " caricamento completato " ,
      "Download errors file" :  " Scarica il file di errore " ,
      "Use an excel template to easily import" :  " Utilizza un modello Excel per importare facilmente " ,
      "Use an Excel template to easily import your data" :  " Utilizza un modello Excel per importare facilmente i tuoi dati " ,
      "User is required" :  " L'utente è obbligatorio " ,
      "User Management" :  " Gestione utenti " ,
      "User name" :  " Nome utente " ,
      "Tenant name": " Nome del Tenant ",
      "Operation": " Operazione ",
      "User Permissions" :  " Permessi utente " ,
      "User required" :  " Utente richiesto " ,
      "User" :  " Utente " ,
      "Username" :  " Nome utente " ,
      "Username is required" :  " è richiesto uno username " ,
      "Username required" :  " Nome utente richiesto " ,
      "Users" :  " Utenti " ,
      "Local user" :  " Utente locale " ,
      "Usuario" :  " Utente " ,
      "View progress and goals achieved by your companies." :  " Guarda i progressi e gli obiettivi raggiunti nelle loro aziende " ,
      "Vuelva a introducir su contraseña" :  " Ripeti la password " ,
      "What right do you want to exercise?" :  " Quale diritto vuoi esercitare? " ,
      "We help you import your information in an agile way" :  " Ti aiutiamo a importare rapidamente le tue informazioni " ,
      "We no longer know where to put so much innovation!!" :  " Non sappiamo più dove mettere tanta innovazione!! " ,
      "Welcome {0}!" :  " Benvenuto {0}! " ,
      "Welcome" :  " Benvenuto a " ,
      "Welcome to" :  " Benvenuto a " ,
      "(without description)" :  " (senza descrizione) " ,
      "(without name)" :  " (peccato nome) " ,
      "Yes" :  " sì " ,
      "You can add more roles later from the user edit screen." :  " Puoi aggiungere più ruoli in un secondo momento tramite la schermata di modifica " ,
      "You dont have access to this resource. You might not have the required permissions. Please, contact your administrator for more information." :  " Non hai accesso a questa risorsa. Non devi avere le autorizzazioni necessarie. Per ulteriori informazioni, contatta il tuo amministratore. " ,
      "You dont have permission to obtain a PAT token for this application" :  " Non hai l'autorizzazione per ottenere un token PAT per questa app " ,
      "Your information" :  " La tua informazione " ,
      "Your picture" :  " La sua foto " ,
      "Your Profile" :  " Il tuo profilo " ,
      "Policies" :  " Politiche " ,
      "Policy Body" :  " corpo politico " ,
      "Areas" :  " Le zone " ,
      "Area" :  " La zona " ,
      "Is Default Area" :  " È l'area predefinita " ,
      "Is System Area" :  " È l'area di sistema " ,
      "Tenants" :  " Inquilini " ,
      "Helpdesk" :  " Helpdesk " ,
      "Title" :  " Titolo " ,
      "Changes have been saved" :  " I cambiamenti sono stati salvati " ,
      "Export" :  " Esportare " ,
      "New Policy": "Nuova Politica",
      "Edit Policy": "Modifica Politica",
      "OK":"Accettare",
      "Please input your policy title!":"Inserisci il titolo della tua polizza!",
      "Policy Id must have less than 200 chars.":"L'ID criterio deve contenere meno di 200 caratteri.",
      "Logging in": "Entrando",
      "My disclaimers": "Le mie rinunce",
      "Disclaimers": "Esclusioni di responsabilità",
      "Accept": "Accettare",
      "Reason": "Motivo",
      "Date": "Data",
      "Login Result": "Risultato Accesso",
      "Authentication Provider": "Fornitore di autenticazione",
      "Success": "Successo",
      "Failure": "Fallimento",
      "UserLocked": "Utente bloccato",
      "Select Login Result...": "Seleziona Risultato accesso...",
      "Select Authentication Provider...": "Seleziona provider di autenticazione...",
      "Select Operation...": "Seleziona Operazione...",
      "Select User...": "Selezionare utente...",
      "Select Tenant...": "Selezionare tenant...",
      "Application Name": "Nome dell'applicazione",
      "Search into Metadata?": "Ricerca nei metadati?",
      "Authentication Logs": "Registri di autenticazione",
      "System Logs": "Registri di sistema",
      "Login Success": "Accesso riuscito",
      "User Locked": "Utente bloccato",
      "Invalid credentials": "Credenziali non valide",
      "Unknown error" : "Errore sconosciuto",
      "Please verify your email." : "Verifica la tua email.",
      "Include Users Global" : "Includi utenti globali",
      "Locked" : "Bloccato",
      "Unlocked" : "Sbloccato",
      "Status" : "Stato",
      "The user": "L'utente",
      "has been unlocked" : "è stato sbloccato",
      "User Unlocked":"Utente sbloccato",
      "User Locked Permanently": "Utente bloccato in modo permanente",
      "Permanent lock":"Blocco permanente",
      "Your password has expired, please create a new password to login.": "La password è scaduta, creare una nuova password per accedere.",
      "Applied":"Applicato",
      "Waiting for Approver":"In attesa di approvatore",
      "Cancelled":"Annullato",
      "Rejected":"Respinto",
      "RollBack":"RollBack",
      "Incorrect username or password, you have 1 attempts left or your account will be locked" : "Credenziali errate, il primo tentativo o il tuo account verrà bloccato",
      "Incorrect username or password, you have 2 attempts left or your account will be locked" : "Credenziali errate, dopo 2 tentativi o il tuo account verrà bloccato",
      "Incorrect username or password, you have 3 attempts left or your account will be locked" : "Credenziali errate, dopo 3 tentativi o il tuo account sarà bloccato",
      "Incorrect username or password, you have 4 attempts left or your account will be locked" : "Credenziali errate, dopo 4 tentativi il tuo account sarà bloccato",
      "Incorrect username or password, you have 5 attempts left or your account will be locked" : "Credenziali errate, dopo 5 tentativi il tuo account verrà bloccato",
      "User Is Locked Out" : "L'utente è bloccato",
      "Are you sure to disable the MFA factor?":"Sei sicuro di disabilitare la modalità MFA?",
      "Code expired!":"Il codice è scaduto!",
      "Resend Code":"Invia nuovamente il codice",
      "A code was sent to your Email, please type code for login. The code will expire in 5 minutes":"Un codice è stato inviato alla tua email, digita il codice per accedere. Il codice scadrà tra 5 minuti.",
      "Minutes":"Minuti",
      "Seconds":"Secondi",  
      "Submit":"Invia",
	  "Error validating TwoAuthentification token for invalid code":"Codice non corretto",
      "Hi, ":"Ciao, ",
      "Cannot reset password, user is locked":"Impossibile reimpostare la password, l'utente è bloccato",
      "New User":"Nuovo utente",
      "User Information":"Informazioni dell'utente",
      "User's Permissions":"Autorizzazioni dell'utente",
      "Create Operation Request":"Richiesta di operazione",
      "Operation Requests":"Richiedere un'operazione",
      "Area required":"¡Per favore, introduzca el área!",
      "Work Position":"Posizione di lavoro",
      "Phone Number":"Numero di telefono",
      "Departments":"Dipartimenti",
      "Save and exit":"Salva ed esci",
      "Next":"Seguente",
      "Clients":"Clienti",
      "Result":"Risultato",
      "Provider":"Fornitore",
      "ReturnUrl":"URL",
      "User locked. Draft User!": "Utente in attesa di approvazione",
      "Access Requests": "Richieste di accesso",
      "You are validating access to FI Connect to users outside the company. Ensure that users must access the requested accounts and each application with the requested role":"Stai convalidando l'accesso a FI Connect per utenti esterni all'azienda. Assicurati che gli utenti debbano accedere agli account richiesti e a ciascuna applicazione con il ruolo richiesto",
      "Are you sure you want to remove all permissions from all current tenants for this user?":"Rimuovere tutte le autorizzazioni da tutti i tenant attuali per questo utente?",
      "Bulk assignment of permissions to existing users of this Tenant":"Assegnazione in blocco dei permessi agli utenti esistenti di questo Tenant",
      "Bulk loading of users and permissions for this Tenant": "Caricamento in blocco di utenti e permessi per questo Tenant",
      "You do not have access to Users group permission assignment": "Non hai accesso all'assegnazione dei permessi del gruppo Utenti",
      "Global roles only": "Solo ruoli globali",
      "MFA Configuration": "MFC Configurazione",
      "Running": "In esecuzione",
      "Stopped": "Fermato",
      "Maintenance": "Manutenzione",
      "Connected per App last 30 minutes": "Collegato tramite App negli ultimi 30 minuti",
      "Users Connected": "Utenti collegati",
      "Global Administrator per Areas": "Amministratore globale per area",
      "Global Administrator per App": "Amministratore globale per applicazione",
      "Global Administrators": "Amministratori globali",
      "Login success per hour": "Successo di accesso all'ora",
      "Logs": "Registri",
      "Global Admin": "Amministratore Globale",
      "Polaris Permission Create": "Polaris Permesso di Creare",
      "Invitation mail sent successfully.": "L'e-mail di invito è stata inviata con successo.",
      "Failed to send invitation mail. Please try again later.": "Non è stato possibile inviare la mail di invito. Riprovare più tardi.",
      "Select Api Entry...": "Seleziona voce API...",
      "Api Entry": "Voce API",
      "Select a date...": "Seleziona una data...",
      "Start date": "Data di inizio",
      "End date": "Data di fine",
      "Access Per User. Date Range": "Accesso per utente. Intervallo di date",
      "User Access": "Accesso utente",
      "Select a Time Range": "Seleziona un intervallo di tempo",
      "The 'Identity' application cannot be deleted.": "L'applicazione 'Identity' non può essere eliminata.",
      "The User is not the owner of the application and cannot delete it.": "L'utente non è il proprietario dell'applicazione e non può eliminarla.",
      "Application deleted successfully.": "Applicazione eliminata con successo.",
      "Failed to delete the application. Please try again later.": "Eliminazione dell'applicazione non riuscita. Si prega di riprovare più tardi.",
      "Image uploaded successfully": "Immagine caricata con successo.",
      "At least 12 characters long.": "Almeno 12 caratteri di lunghezza.",
      "At least one uppercase letter, one lowercase letter, one number, and one symbol.": "Almeno una lettera maiuscola, una lettera minuscola, un numero e un simbolo.",
      "Password must be changed every 90 days.": "La password deve essere cambiata ogni 90 giorni.",
      "Cannot reuse any of the last 7 passwords.": "Non è possibile riutilizzare nessuna delle ultime 7 password.",
      "Password Requirements": "Requisiti della password",
      "Approve Operation Request": "Approvazione della richiesta di operazione",
      "There's already a group named {0}": "Esiste già un gruppo chiamato {0}",
      "Go to": "Vai a",
      },
    "de": {
        "Remove this app from your favorites":"Entfernen Sie diese App aus Ihren Favoriten",
        "My Clients": "Meine Kunden",
        "Are you sure that you want to discard these changes?": "Sind Sie sicher, dass Sie diese Änderungen verwerfen möchten?",
        "Show hierarchy": "Hierarchie anzeigen",
        "Customized?": "Angepasst?",
        "Tenants exported successfully": "Mieter erfolgreich exportiert",
        "Export Tenants Success": "Mieter erfolgreich exportiert",
        "Export Member Success": "Mitglieder erfolgreich exportiert",
        "Download File": "Datei herunterladen",
        "Select Area...": "Bereich auswählen...",
        "Select Creation type...": "Erstellungstyp auswählen...",
        "Creation": "Erstellung",
        "Auto": "Automatisch",
        "Manual": "Manuell",
        "Send": "Senden",
        "Show only favorites applications":"Nur Favoritenanwendungen anzeigen",
        "Sort applications":"Bewerbungen sortieren",
        "Add this app to your favorites":"Fügen Sie diese App zu Ihren Favoriten hinzu",
        "Are you sure to revoke this access?": "Sind Sie sicher, dass Sie diesen Zugriff widerrufen möchten?",
        "Choose OK to Login and continue...": "Wählen Sie OK, um sich anzumelden und fortzufahren...",
        "Your session has expired.": "Ihre Sitzung ist abgelaufen.",
        "FI-Group password change": "FI-Group Passwortänderung",
        "Invitation FI GROUP": "Einladung FI GROUP",
        "Welcome to FI GROUP": "Willkommen bei FI GROUP",
        "FI Connect is a digital applications HUB created by FI Group that seeks to transform and optimize the relationship we have with our customers.": "FI Connect ist ein digitaler Anwendungs-HUB, der von der FI Group erstellt wurde und darauf abzielt, die Beziehung zu unseren Kunden zu transformieren und zu optimieren.",
        "Please confirm your invitation": "Bitte bestätigen Sie Ihre Einladung",
        "Operation Completed Successfully": "Operation erfolgreich abgeschlossen",
        "Your operation will be processed in background.": "Ihre Operation wird im Hintergrund bearbeitet.",
        "Is Owner": "Eigentümer",
        "Are you sure to revoke all user Access?": "Sind Sie sicher, dass Sie den gesamten Benutzerzugriff widerrufen möchten?",
        "Global roles": "Globale Rollen",
        "Group's Users": "Gruppenbenutzer",
        "Add Tenant Roles": "Mieterrollen hinzufügen",
        "(Empty Email)": "(Leere E-Mail)",
        "(Empty Name)": "(Leerer Name)",
        "Clear All": "Alle löschen",
        "Add Member to Tenant": "Mitglied zum Mieter hinzufügen",
        "elements deleted sussessful": "Elemente erfolgreich gelöscht",
        "Delete elements": "Elemente löschen",
        "Identity Tenants": "Identitätsmieter",
        "Apply to this entity and all its descendants": "Auf diese Entität und alle ihre Nachkommen anwenden",
        "Start typing to search for applications...": "Beginnen Sie mit der Eingabe, um nach Anwendungen zu suchen...",
        "Add new user role": "Neue Benutzerrolle hinzufügen",
        "Group Name": "Gruppenname",
        "Entities": "Einheiten",
        "European VAT": "Europäische Mehrwertsteuer",
        "Ok": "OK",
        "This file will be processed in the background.": "Diese Datei wird im Hintergrund bearbeitet.",
        "Upload Successful": "Hochladen erfolgreich",
        "New Notification": "Neue Benachrichtigung",
        "Upload complete": "Hochladen abgeschlossen",
        "Identities": "Identitäten",
        "Add member to Group": "Mitglied zur Gruppe hinzufügen",
        "Group Details": "Gruppendetails",
        "Group Users": "Gruppenbenutzer",
        "Add Tenant Roles to User": "Mieterrollen zum Benutzer hinzufügen",
        "Add Tenant Roles to Group": "Mieterrollen zur Gruppe hinzufügen",
        "Tenant": "Mieter",
        "Global": "Global",
        "Hierarchy": "Hierarchie",
        "Parent": "Elternteil",
        "View Number": "Ansichtnummer",
        "New Entity": "Neue Entität",
        "Please enter a title": "Bitte geben Sie einen Titel ein",
        "Area is requiered": "Bereich ist erforderlich",
        "Select entity": "Entität auswählen",
        "Tax Number": "Steuernummer",
        "Change Parent Tenant": "Mieterelternteil ändern",
        "Edit": "Bearbeiten",
        "Display Name": "Anzeigename",
        "Inheritance": "Vererbung",
        "Inherited": "Geerbt",
        "Tenant Details": "Mieterdetails",
        "Terms of service": "Nutzungsbedingungen",
        "Job Title": "Berufsbezeichnung",
        "Manager": "Manager",
        "Teams": "Teams",
        "(2MB size limit)": "(2MB Größenlimit)",
        "2MB files size limit exceeded": "2MB Größenlimit überschritten",
        "Access is denied": "Zugriff verweigert",
        "Add new": "Neu hinzufügen",
        "Already exists an app with this app id": "Es existiert bereits eine App mit dieser App-ID",
        "Already exists an app with this app id and name": "Es existiert bereits eine App mit dieser App-ID und Namen",
        "Already exists an app with this name": "Es existiert bereits eine App mit diesem Namen",
        "An error ocurred": "Ein Fehler ist aufgetreten",
        "API Resources": "API-Ressourcen",
        "App URI": "App-URI",
        "Application Id": "Anwendungs-ID",
        "Application Id must have less than 200 chars.": "Anwendungs-ID muss weniger als 200 Zeichen haben.",
        "Application name": "Anwendungsname",
        "Application not found": "Anwendung nicht gefunden",
        "Application required": "Anwendung erforderlich",
        "Application shared secret": "Anwendungs-Geheimnis",
        "Application": "Anwendung",
        "Applications": "Anwendungen",
        "Applications which is a member": "Anwendungen, bei denen es sich um Mitglieder handelt",
        "Are you sure you want to change this entity parent?": "Sind Sie sicher, dass Sie das Elternteil dieser Entität ändern möchten?",
        "This action can affect users permissions": "Diese Aktion kann die Berechtigungen der Benutzer beeinflussen.",
        "Are you sure want to delete selected items?": "Sind Sie sicher, dass Sie die ausgewählten Elemente löschen möchten?",
        "Are you sure want to reset your password? An email will be sent to": "Sind Sie sicher, dass Sie Ihr Passwort zurücksetzen möchten? Eine E-Mail wird gesendet an",
        "Attach to this application a copy of your identification, or where appropriate, a copy of the order and identification of the representative": "Fügen Sie dieser Anwendung eine Kopie Ihres Ausweises bei oder gegebenenfalls eine Kopie der Bestellung und des Ausweises des Vertreters",
        "Base Application": "Basisanwendung",
        "Base role": "Basisrolle",
        "Basic info": "Grundinformationen",
        "Bulk upload": "Massen-Upload",
        "Bulk upload items": "Massen-Upload-Elemente",
        "Bulk Upload Options": "Optionen für Massen-Upload",
        "Bulk Insert Users": "Benutzer in großen Mengen einfügen",
        "Bulk Insert Users's Role": "Benutzerrollen in großen Mengen einfügen",
        "Uploaded User'Roles have been created/updated": "Hochgeladene Benutzerrollen wurden erstellt/aktualisiert",
        "Uploaded Group'Roles have been created/updated": "Hochgeladene Gruppenrollen wurden erstellt/aktualisiert",
        "Uploaded User have been created/updated": "Hochgeladene Benutzer wurden erstellt/aktualisiert",
        "Bulk Insert Users's Roles": "Benutzerrollen in großen Mengen einfügen",
        "Bulk Insert Groups's Roles": "Gruppenrollen in großen Mengen einfügen",
        "Cancel": "Abbrechen",
        "Cannot reset password at this stage": "Das Passwort kann in diesem Stadium nicht zurückgesetzt werden",
        "Cargando": "Laden",
        "Change password": "Passwort ändern",
        "Check User Permissions": "Benutzerberechtigungen überprüfen",
        "Click or drag file to this area to upload": "Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen",
        "Click or drag file to update the app picture": "Klicken oder ziehen Sie die Datei, um das App-Bild zu aktualisieren",
        "Click or drag file to update your profile picture": "Klicken oder ziehen Sie die Datei, um Ihr Profilbild zu aktualisieren",
        "Close session": "Sitzung beenden",
        "Configure clients, workgroups and companies": "Konfigurieren Sie Kunden, Arbeitsgruppen und Unternehmen",
        "Confirm password": "Passwort bestätigen",
        "Confirmation required": "Bestätigung erforderlich",
        "Cookies Policy": "Cookie-Richtlinie",
        "Copyright": "Urheberrecht",
        "Copy to clipboard": "In die Zwischenablage kopieren",
        "Created on": "Erstellt am",
        "Create Password": "Passwort erstellen",
        "data-delete-info": "(Artikel 17 der DSGVO „Recht auf Löschung (Recht auf Vergessenwerden)“): wenn Sie nicht mehr möchten, dass Fi Group Ihre Daten verarbeitet, widerrufen Sie Ihre Einwilligung sowie alle Links, Kopien oder Reproduktionen Ihrer Daten.",
        "data-portability-info": "(Artikel 20 der DSGVO „Recht auf Datenübertragbarkeit“): dies bedeutet, die Übertragung Ihrer Daten zu einer anderen Organisation zu beantragen.",
        "data-rectification-info": "(Artikel 16 der DSGVO „Recht auf Berichtigung“): dies ist der Fall, wenn Sie der Meinung sind, dass einige Ihrer Daten ungenau oder unvollständig sind und die Aktualisierung so schnell wie möglich beantragen.",
        "Data portability request": "Antrag auf Datenübertragbarkeit",
        "Data Subject Right": "Betroffenenrechte",
        "Delete selected": "Ausgewählte löschen",
        "denial-profiling-info": "(Artikel 22 der DSGVO „Automatisierte individuelle Entscheidung, einschließlich Profiling“): dies bedeutet, nicht Gegenstand einer automatisierten Entscheidung zu sein.",
        "Description": "Beschreibung",
        "Description must have less than 1000 chars.": "Beschreibung muss weniger als 1000 Zeichen haben.",
        "Direct request": "Direktanfrage",
        "Display name": "Anzeigename",
        "Display name must have less than 200 chars.": "Anzeigename muss weniger als 200 Zeichen haben.",
        "Display name must have less than 256 chars.": "Anzeigename muss weniger als 256 Zeichen haben.",
        "Done": "Fertig",
        "Download import template": "Importvorlage herunterladen",
        "Download template": "Vorlage herunterladen",
        "Do you want to access these applications? Ask us!": "Möchten Sie auf diese Anwendungen zugreifen? Fragen Sie uns!",
        "Each URI must have 2000 chars max.": "Jede URI darf maximal 2000 Zeichen haben.",
        "El inicio de sesión ha terminado con éxito": "Die Anmeldung war erfolgreich",
        "Email missing": "E-Mail fehlt",
        "Email": "E-Mail",
        "Email required": "E-Mail erforderlich",
        "Enable the application": "Anwendung aktivieren",
        "Enabled": "Aktiviert",
        "English": "Englisch",
        "Enter your password": "Geben Sie Ihr Passwort ein",
        "Enter your username": "Geben Sie Ihren Benutzernamen ein",
        "Error": "Fehler",
        "Errors": "Fehler",
        "Exercise rights": "Rechte ausüben",
        "EXERCISE YOUR RIGHTS": "IHRE RECHTE AUSÜBEN",
        "Expires at": "Läuft ab am",
        "Export to Excel": "Nach Excel exportieren",
        "Facebook account": "Facebook-Konto",
        "FI Group all rights reserved": "FI Group, alle Rechte vorbehalten",
        "Fi Group users have to connect through Office 365 professional account": "FI Group-Benutzer müssen sich über ein professionelles Office 365-Konto anmelden",
        "Filter by application": "Nach Anwendung filtern",
        "First name": "Vorname",
        "Forgot your password?": "Passwort vergessen?",
        "French": "Französisch",
        "Full name": "Vollständiger Name",
        "General properties": "Allgemeine Eigenschaften",
        "Google account": "Google-Konto",
        "Grant search service": "Fördersuchdienst",
        "Grant": "Förderung",
        "Grants": "Förderungen",
        "Group is required": "Gruppe ist erforderlich",
        "Group required": "Gruppe erforderlich",
        "Group roles": "Gruppenrollen",
        "Group": "Gruppe",
        "Group name": "Gruppenname",
        "Groups": "Gruppen",
        "Groups assigned": "Zugewiesene Gruppen",
        "Hide in user List": "In Benutzerliste verbergen",
        "Icon (Ant Icon, URL or base64 image)": "Symbol (Ant-Symbol, URL oder Base64-Bild)",
        "Icon": "Symbol",
        "Identity management": "Identitätsmanagement",
        "Incorrect username or password": "Falscher Benutzername oder falsches Passwort",
        "Invalid preferred language": "Ungültige bevorzugte Sprache",
        "Invalit token.": "Ungültiges Token.",
        "Italian": "Italienisch",
        "In case of being requested by representation, please indicate the representative's data": "Bei Anforderung durch einen Vertreter geben Sie bitte die Daten des Vertreters an",
        "Log in": "Anmelden",
        "Inicie sesión utilizando su cuenta de Office 365 si dispone de alguna": "Melden Sie sich mit Ihrem Office 365-Konto an, falls vorhanden",
        "Import template": "Importvorlage",
        "Input search text...": "Suchtext eingeben...",
        "Introduzca su contraseña": "Geben Sie Ihr Passwort ein",
        "Introduzca su nombre de usuario": "Geben Sie Ihren Benutzernamen ein",
        "Invalid email address.": "Ungültige E-Mail-Adresse.",
        "It seems that you dont have access to any application. Please contact your administartor": "Es scheint, dass Sie keinen Zugriff auf eine Anwendung haben. Bitte kontaktieren Sie Ihren Administrator",
        "Last name": "Nachname",
        "limitation-treatment-info": "(Artikel 18 der DSGVO „Recht auf Einschränkung der Verarbeitung“): dies bedeutet, dass Sie Ihre Daten vorübergehend isolieren möchten, sodass Ihre Daten, außer zur Speicherung, nur mit Ihrer Zustimmung verarbeitet werden.",
        "Loading": "Laden",
        "Local User": "Lokaler Benutzer",
        "Login": "Anmeldung",
        "Login has successfully ended": "Die Anmeldung wurde erfolgreich beendet",
        "Manage your R&D claims with this application": "Verwalten Sie Ihre F&E-Anträge mit dieser Anwendung",
        "Manages applications, users, groups and permissions": "Verwaltet Anwendungen, Benutzer, Gruppen und Berechtigungen",
        "Manages receipts & taxes": "Verwaltet Belege und Steuern",
        "Manual assigned roles": "Manuell zugewiesene Rollen",
        "Member of": "Mitglied von",
        "Members": "Mitglieder",
        "Modified on": "Geändert am",
        "My data": "Meine Daten",
        "My profile": "Mein Profil",
        "Name and surname of the interested": "Name und Nachname des Interessenten",
        "Name is required": "Name ist erforderlich",
        "Name": "Name",
        "New application": "Neue Anwendung",
        "New group": "Neue Gruppe",
        "New group's scope": "Neuer Gruppenbereich",
        "New password": "Neues Passwort",
        "New Permission": "Neue Berechtigung",
        "New Personal Access Token (PAT)": "Neuer persönlicher Zugriffstoken (PAT)",
        "New role": "Neue Rolle",
        "New role's scope": "Neuer Rollenbereich",
        "New user": "Neuer Benutzer",
        "No": "Nein",
        "No data": "Keine Daten",
        "No groups found for this user": "Keine Gruppen für diesen Benutzer gefunden",
        "No permissions found for this user": "Keine Berechtigungen für diesen Benutzer gefunden",
        "Notifications": "Benachrichtigungen",
        "Open Result": "Ergebnis öffnen",
        "No Notifications": "Keine Benachrichtigungen",
        "object-data-info": "(Artikel 21 der DSGVO „Widerspruchsrecht“): Dies ist die Handlung, die Verarbeitung Ihrer Daten unter bestimmten Bedingungen zu stoppen, nämlich die Verarbeitung, die zur Erfüllung einer öffentlichen Aufgabe durch den Verantwortlichen erforderlich ist, oder die Verarbeitung, die zur Verfolgung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich ist, oder der Fall, dass Sie nicht möchten, dass Ihre personenbezogenen Daten zu kommerziellen Zwecken verarbeitet werden.",
        "O cree una cuenta local": "Oder erstellen Sie ein lokales Konto",
        "Or create a local account": "Oder erstellen Sie ein lokales Konto",
        "Or the page may not be found ...": "Oder die Seite konnte nicht gefunden werden ...",
        "Or with the following providers": "Oder mit den folgenden Anbietern",
        "Owners": "Eigentümer",
        "Page not found": "Seite nicht gefunden",
        "Password": "Passwort",
        "Passwords don't match": "Passwörter stimmen nicht überein",
        "Password has been successfully reseted": "Passwort wurde erfolgreich zurückgesetzt",
        "Passwords must be at least 6 characters.": "Passwörter müssen mindestens 6 Zeichen lang sein.",
        "Passwords must have at least one non alphanumeric character.": "Passwörter müssen mindestens ein nicht alphanumerisches Zeichen enthalten.",
        "Passwords must have at least one digit ('0'-'9').": "Passwörter müssen mindestens eine Ziffer ('0'-'9') enthalten.",
        "Passwords must have at least one lowercase ('a'-'z').": "Passwörter müssen mindestens einen Kleinbuchstaben ('a'-'z') enthalten.",
        "Passwords must have at least one uppercase ('A'-'Z').": "Passwörter müssen mindestens einen Großbuchstaben ('A'-'Z') enthalten.",
        "PAT copied to clipboard!": "PAT in die Zwischenablage kopiert!",
        "Permission name": "Berechtigungsname",
        "Permission": "Berechtigung",
        "BulkInsert": "Masseneinfügung",
        "Permissions": "Berechtigungen",
        "Permissions assigned": "Berechtigungen zugewiesen",
        "Permission display name": "Anzeigename der Berechtigung",
        "Personal Access Token (PAT) Created": "Persönlicher Zugriffstoken (PAT) erstellt",
        "Personal Access Tokens": "Persönliche Zugriffstokens",
        "personal-data-info": "(Artikel 15 der DSGVO „Recht auf Auskunft“): Wenn Sie wissen möchten, ob Fi Group Ihre personenbezogenen Daten verarbeitet oder nicht, und falls ja, eine Kopie und die Details der Verarbeitung erhalten möchten.",
        "Personal data stored for the user: {0}": "Für den Benutzer gespeicherte personenbezogene Daten: {0}",
        "Please add the required documents!": "Bitte fügen Sie die erforderlichen Dokumente hinzu!",
        "Please check at least one right to exercise!": "Bitte wählen Sie mindestens ein Recht zur Ausübung aus!",
        "Please check your email inbox. We have sent instructions on how reset your password.": "Bitte überprüfen Sie Ihren Posteingang. Wir haben Anweisungen zum Zurücksetzen Ihres Passworts gesendet.",
        "Please confirm your password!": "Bitte bestätigen Sie Ihr Passwort!",
        "Please enter the scope!": "Bitte geben Sie den Bereich ein!",
        "Please enter your email before continue with the password reset.": "Bitte geben Sie Ihre E-Mail ein, bevor Sie mit dem Zurücksetzen des Passworts fortfahren.",
        "Please indicate if it is a direct request or through a representative": "Bitte geben Sie an, ob es sich um eine direkte Anfrage oder über einen Vertreter handelt",
        "Please input the application description!": "Bitte geben Sie die Anwendungsbeschreibung ein!",
        "Please input the application display name!": "Bitte geben Sie den Anwendungsanzeigenamen ein!",
        "Please input the application private URL!": "Bitte geben Sie die private URL der Anwendung ein!",
        "Please input the application redirect URIs!": "Bitte geben Sie die Weiterleitungs-URIs der Anwendung ein!",
        "Please input the email!": "Bitte geben Sie die E-Mail ein!",
        "Please input the name of the interested!": "Bitte geben Sie den Namen des Interessenten ein!",
        "Please input the new password!": "Bitte geben Sie das neue Passwort ein!",
        "Please input the password confirmation!": "Bitte geben Sie die Passwortbestätigung ein!",
        "Please input the permission name!": "Bitte geben Sie den Berechtigungsnamen ein!",
        "Please input the permission!": "Bitte geben Sie die Berechtigung ein!",
        "Please input the request date!": "Bitte geben Sie das Antragsdatum ein!",
        "Please input your API title!": "Bitte geben Sie den Titel Ihrer API ein!",
        "Please input your application display name!": "Bitte geben Sie den Anzeigenamen Ihrer Anwendung ein!",
        "Please input your application id!": "Bitte geben Sie die Anwendungs-ID ein!",
        "Please input your application name!": "Bitte geben Sie den Namen Ihrer Anwendung ein!",
        "Please input your email!": "Bitte geben Sie Ihre E-Mail ein!",
        "Please input your first name!": "Bitte geben Sie Ihren Vornamen ein!",
        "Please input your group name!": "Bitte geben Sie den Namen Ihrer Gruppe ein!",
        "Please input your last name!": "Bitte geben Sie Ihren Nachnamen ein!",
        "Please input your password!": "Bitte geben Sie Ihr Passwort ein!",
        "Please input your PAT's name!": "Bitte geben Sie den Namen Ihres PAT ein!",
        "Please input your redirect URIs!": "Bitte geben Sie Ihre Weiterleitungs-URIs ein!",
        "Please input the request type!": "Bitte geben Sie den Antragstyp ein!",
        "Please input your username!": "Bitte geben Sie Ihren Benutzernamen ein!",
        "Please select a user to check permissions": "Bitte wählen Sie einen Benutzer zur Überprüfung der Berechtigungen aus",
        "Please select your PAT's Application!": "Bitte wählen Sie die Anwendung Ihres PAT aus!",
        "Please select your preferred language!": "Bitte wählen Sie Ihre bevorzugte Sprache!",
        "Please type a valid email!": "Bitte geben Sie eine gültige E-Mail ein!",
        "Please enter your password. Remember that the login name is your email address.": "Bitte geben Sie Ihr Passwort ein. Denken Sie daran, dass Ihr Anmeldename Ihre E-Mail-Adresse ist.",
        "Invalid or expired token": "Ungültiger oder abgelaufener Token",
        "Portal settings": "Portaleinstellungen",
        "Portuguese": "Portugiesisch",
        "Portuguese (PT)": "Portugiesisch (PT)",
        "Portuguese (BR)": "Portugiesisch (BR)",
        "Preferred language": "Bevorzugte Sprache",
        "Preferred language required": "Bevorzugte Sprache erforderlich",
        "Private url": "Private URL",
        "Problems to access? Contact Us!": "Probleme beim Zugriff? Kontaktieren Sie uns!",
        "Professional account (Office 365)": "Professionelles Konto (Office 365)",
        "Profile": "Profil",
        "Public URI": "Öffentliche URI",
        "Remember me": "Erinnere dich an mich",
        "Redirect URIs": "Weiterleitungs-URIs",
        "Re-enter your password": "Geben Sie Ihr Passwort erneut ein",
        "Request failed with status code 403": "Sie haben keine Berechtigung, diese Aktion durchzuführen",
        "Reset your password": "Setzen Sie Ihr Passwort zurück",
        "Restrict client access by scope": "Beschränken Sie den Clientzugriff nach Bereich",
        "Restricted": "Eingeschränkt",
        "Right of access": "Auskunftsrecht",
        "Right to be informed (that is, to obtain a copy of the same that may be in the possession of the company)": "Recht auf Information (das heißt, eine Kopie davon zu erhalten, die sich im Besitz des Unternehmens befinden kann)",
        "Right to data portability": "Recht auf Datenübertragbarkeit",
        "Right to erasure (right to be forgotten)": "Recht auf Löschung (Recht auf Vergessenwerden)",
        "Right to object to treatment": "Widerspruchsrecht gegen die Verarbeitung",
        "Right to rectification (that is, to correct personal data, or to complete incomplete data)": "Recht auf Berichtigung (das heißt, personenbezogene Daten zu korrigieren oder unvollständige Daten zu vervollständigen)",
        "RIGHTS AND EXERCISE OF THE INTERESTED PARTIES": "RECHTE UND AUSÜBUNG DER BETROFFENEN",
        "rights-intro": "Gemäß den Bestimmungen der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr haben Sie mehrere Rechte in Bezug auf Ihre Daten, die das Recht auf Information ergänzen, sodass Sie über die Erhebung Ihrer Daten und deren Verwendung informiert sind.",
        "Representative data": "Vertreterdaten",
        "Request date": "Antragsdatum",
        "Request details": "Antragsdetails",
        "Request for access to your personal data": "Antrag auf Zugang zu Ihren personenbezogenen Daten",
        "Request for limitation of treatment": "Antrag auf Einschränkung der Verarbeitung",
        "Request for rectification of your personal data": "Antrag auf Berichtigung Ihrer personenbezogenen Daten",
        "Request for denial of profiling": "Antrag auf Ablehnung der Profilerstellung",
        "Request sent successfully": "Antrag erfolgreich gesendet",
        "Request to delete your personal data": "Antrag auf Löschung Ihrer personenbezogenen Daten",
        "Request to object to data processing": "Antrag auf Widerspruch gegen die Datenverarbeitung",
        "Request through a representative": "Antrag über einen Vertreter",
        "Role access scopes": "Rollen-Zugriffsbereiche",
        "Role is required": "Rolle ist erforderlich",
        "Role required": "Rolle erforderlich",
        "Role": "Rolle",
        "Role name": "Rollenname",
        "Role not found or it is not available for selected application": "Rolle nicht gefunden oder für die ausgewählte Anwendung nicht verfügbar",
        "Roles": "Rollen",
        "Roles assigned": "Zugewiesene Rollen",
        "Row": "Reihe",
        "Save": "Speichern",
        "Save this string elsewhere because it will not be shown again": "Speichern Sie diesen Wert an einem anderen Ort, da er nicht erneut angezeigt wird",
        "Scope is required": "Bereich ist erforderlich",
        "Scope": "Bereich",
        "Select a user to check": "Wählen Sie einen Benutzer zur Überprüfung aus",
        "Select another authentication method": "Wählen Sie eine andere Authentifizierungsmethode",
        "Select request type": "Antragstyp auswählen",
        "Send email invite": "E-Mail-Einladung senden",
        "Shared secret": "Geteiltes Geheimnis",
        "Sign in using your Office 365 account if you have any": "Melden Sie sich mit Ihrem Office 365-Konto an, falls Sie eines haben",
        "Spanish": "Spanisch",
        "Successful action": "Erfolgreiche Aktion",
        "Terms of Use": "Nutzungsbedingungen",
        "The expiration date must be greater than today's date": "Das Ablaufdatum muss später als das heutige Datum sein",
        "The passwords you entered do not match": "Die eingegebenen Passwörter stimmen nicht überein",
        "This appplication is in PRE-RELEASE mode": "Diese Anwendung befindet sich im VORVERÖFFENTLICHUNGSmodus",
        "This is your newly created PAT. Save it carefuly since once you close this dialog, you will not see the token anymore": "Dies ist Ihr neu erstellter PAT. Speichern Sie ihn sorgfältig, da er nach dem Schließen dieses Dialogs nicht mehr angezeigt wird",
        "to": "zu",
        "To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right": "Um uns bei der effizienten Bearbeitung Ihrer Anfrage zu unterstützen, geben Sie bitte so viele Details wie möglich zu den personenbezogenen Daten an, die Sie zur Ausübung Ihres Rechts anfordern",
        "To create a local account you need to define a password. Your username will be your email.": "Um ein lokales Konto zu erstellen, müssen Sie ein Passwort festlegen. Ihr Benutzername wird Ihre E-Mail sein.",
        "Total elements": "Gesamte Elemente",
        "Total imported": "Gesamt importiert",
        "Upload completed": "Upload abgeschlossen",
        "Download errors file": "Fehlerdatei herunterladen",
        "Use an excel template to easily import": "Verwenden Sie eine Excel-Vorlage zum einfachen Importieren",
        "Use an Excel template to easily import your data": "Verwenden Sie eine Excel-Vorlage, um Ihre Daten einfach zu importieren",
        "User is required": "Benutzer ist erforderlich",
        "User Management": "Benutzerverwaltung",
        "User name": "Benutzername",
        "Tenant name": "Tenant-Name",
        "Operation": "Operation",
        "User Permissions": "Benutzerberechtigungen",
        "User required": "Benutzer erforderlich",
        "User": "Benutzer",
        "Username": "Benutzername",
        "Username is required": "Benutzername ist erforderlich",
        "Username required": "Benutzername erforderlich",
        "Users": "Benutzer",
        "Local user": "Lokaler Benutzer",
        "Usuario": "Benutzer",
        "View progress and goals achieved by your companies.": "Sehen Sie sich den Fortschritt und die erreichten Ziele Ihrer Unternehmen an.",
        "Vuelva a introducir su contraseña": "Geben Sie Ihr Passwort erneut ein",
        "What right do you want to exercise?": "Welches Recht möchten Sie ausüben?",
        "We help you import your information in an agile way": "Wir helfen Ihnen, Ihre Informationen auf agile Weise zu importieren",
        "We no longer know where to put so much innovation!!": "Wir wissen nicht mehr, wohin mit so viel Innovation!!",
        "Welcome {0}!": "Willkommen {0}!",
        "Welcome": "Willkommen",
        "Welcome to": "Willkommen zu",
        "(without description)": "(ohne Beschreibung)",
        "(without name)": "(ohne Name)",
        "Yes": "Ja",
        "You can add more roles later from the user edit screen.": "Sie können später weitere Rollen über den Benutzerbearbeitungsbildschirm hinzufügen.",
        "You dont have access to this resource. You might not have the required permissions. Please, contact your administrator for more information.": "Sie haben keinen Zugriff auf diese Ressource. Möglicherweise haben Sie nicht die erforderlichen Berechtigungen. Bitte wenden Sie sich an Ihren Administrator, um weitere Informationen zu erhalten.",
        "You dont have permission to obtain a PAT token for this application": "Sie haben keine Berechtigung, einen PAT-Token für diese Anwendung zu erhalten",
        "Your information": "Ihre Informationen",
        "Your picture": "Ihr Bild",
        "Your Profile": "Ihr Profil",
        "Policies": "Richtlinien",
        "Policy Body": "Richtlinientext",
        "Areas": "Bereiche",
        "Area": "Bereich",
        "Is Default Area": "Ist Standardbereich",
        "Is System Area": "Ist Systembereich",
        "Tenants": "Tenants",
        "Helpdesk": "Helpdesk",
        "Title": "Titel",
        "Changes have been saved": "Die Änderungen wurden gespeichert",
        "Export": "Exportieren",
        "New Policy": "Neue Richtlinie",
        "Edit Policy": "Richtlinie bearbeiten",
        "Please input your policy title!": "Bitte geben Sie den Titel Ihrer Richtlinie ein!",
        "Policy Id must have less than 200 chars.": "Die Richtlinien-ID darf nicht mehr als 200 Zeichen enthalten.",
        "Logging in": "Anmelden",
        "OK": "Akzeptieren",
        "My disclaimers": "Meine Haftungsausschlüsse",
        "Disclaimers": "Haftungsausschlüsse",
        "Accept": "Akzeptieren",
        "Reason": "Grund",
        "Date": "Datum",
        "Login Result": "Anmeldeergebnis",
        "Authentication Provider": "Authentifizierungsanbieter",
        "Success": "Erfolgreich",
        "Failure": "Fehlgeschlagen",
        "UserLocked": "Benutzer gesperrt",
        "Select Login Result...": "Anmeldeergebnis auswählen...",
        "Select Authentication Provider...": "Authentifizierungsanbieter auswählen...",
        "Select Operation...": "Operation auswählen...",
        "Select User...": "Benutzer auswählen...",
        "Select Tenant...": "Tenant auswählen...",
        "Application Name": "Anwendungsname",
        "Search into Metadata?": "In Metadaten suchen?",
        "Authentication Logs": "Authentifizierungsprotokolle",
        "System Logs": "Systemprotokolle",
        "Session Logs": "Sitzungsprotokolle",
        "Login Success": "Erfolgreiche Anmeldung",
        "User Locked": "Benutzer gesperrt",
        "Invalid credentials": "Ungültige Anmeldedaten",
        "Unknown error": "Unbekannter Fehler",
        "Please verify your email.": "Bitte überprüfen Sie Ihre E-Mail.",
        "Include Users Global": "Globale Benutzer einschließen",
        "Locked": "Gesperrt",
        "Unlocked": "Entsperrt",
        "Status": "Status",
        "The user": "Der Benutzer",
        "has been unlocked": "wurde entsperrt",
        "User Unlocked": "Benutzer entsperrt",
        "User Locked Permanently": "Benutzer dauerhaft gesperrt",
        "Permanent lock": "Dauerhafte Sperre",
        "Your password has expired, please create a new password to login.": "Ihr Passwort ist abgelaufen, bitte erstellen Sie ein neues Passwort, um sich anzumelden.",
        "Group Alias": "Gruppenalias",
        "Bulk Insert Group members": "Massenimport von Gruppenmitgliedern",
        "Applied": "Angewendet",
        "Waiting for Approver": "Warten auf Genehmigung",
        "Cancelled": "Abgebrochen",
        "Rejected": "Abgelehnt",
        "RollBack": "Zurücksetzen",
        "Incorrect username or password, you have 1 attempts left or your account will be locked": "Falscher Benutzername oder falsches Passwort, Sie haben noch 1 Versuch, bevor Ihr Konto gesperrt wird",
        "Incorrect username or password, you have 2 attempts left or your account will be locked": "Falscher Benutzername oder falsches Passwort, Sie haben noch 2 Versuche, bevor Ihr Konto gesperrt wird",
        "Incorrect username or password, you have 3 attempts left or your account will be locked": "Falscher Benutzername oder falsches Passwort, Sie haben noch 3 Versuche, bevor Ihr Konto gesperrt wird",
        "Incorrect username or password, you have 4 attempts left or your account will be locked": "Falscher Benutzername oder falsches Passwort, Sie haben noch 4 Versuche, bevor Ihr Konto gesperrt wird",
        "Incorrect username or password, you have 5 attempts left or your account will be locked": "Falscher Benutzername oder falsches Passwort, Sie haben noch 5 Versuche, bevor Ihr Konto gesperrt wird",
        "User Is Locked Out": "Benutzer ist gesperrt",
        "User does not exist": "Benutzer existiert nicht",
        "Are you sure to disable the MFA factor?": "Sind Sie sicher, dass Sie den MFA-Faktor deaktivieren möchten?",
        "Code expired!": "Code abgelaufen!",
        "Resend Code": "Code erneut senden",
        "A code was sent to your Email, please type code for login. The code will expire in 5 minutes": "Ein Code wurde an Ihre E-Mail gesendet, bitte geben Sie den Code zum Anmelden ein. Der Code läuft in 5 Minuten ab.",
        "Minutes": "Minuten",
        "Seconds": "Sekunden",
        "Submit": "Einreichen",
        "Error validating TwoAuthentification token for invalid code": "Fehler bei der Validierung des Zwei-Faktor-Authentifizierungstokens aufgrund eines ungültigen Codes",
        "Hi, ": "Hallo, ",
        "Cannot reset password, user is locked": "Passwort kann nicht zurückgesetzt werden, Benutzer ist gesperrt",
        "New User": "Neuer Benutzer",
        "User Information": "Benutzerinformationen",
        "User's Permissions": "Berechtigungen",
        "Create Operation Request": "Betriebsanforderung erstellen",
        "Operation Requests": "Betriebsanforderungen",
        "Area required": "Bereich erforderlich",
        "Work Position": "Position",
        "Phone Number": "Telefonnummer",
        "Departments": "Abteilungen",
        "Save and exit": "Speichern und beenden",
        "Next": "Weiter",
        "Clients": "Kunden",
        "Result": "Ergebnis",
        "Provider": "Anbieter",
        "ReturnUrl": "URL",
        "User locked. Draft User!": "Benutzer gesperrt. Benutzerentwurf!",
        "Access Requests": "Zugriffsanfragen",
        "You are validating access to FI Connect to users outside the company. Ensure that users must access the requested accounts and each application with the requested role": "Sie validieren den Zugang zu FI Connect für Benutzer außerhalb des Unternehmens. Stellen Sie sicher, dass die Benutzer auf die angeforderten Konten und jede Anwendung mit der angeforderten Rolle zugreifen müssen",
        "Are you sure you want to remove all permissions from all current tenants for this user?": "Sind Sie sicher, dass Sie alle Berechtigungen von allen aktuellen Tenants für diesen Benutzer entfernen möchten?",
        "Bulk assignment of permissions to existing users of this Tenant": "Massenhafte Zuweisung von Berechtigungen an bestehende Benutzer dieses Tenants",
        "Bulk loading of users and permissions for this Tenant": "Massenhafte Übertragung von Benutzern und Berechtigungen für diesen Tenant",
        "Invalid action: No more Tenant roles can be added to the Users Group.": "Ungültige Aktion: Es können keine weiteren Tenant-Rollen zur Benutzergruppe hinzugefügt werden.",
        "You do not have access to Users group permission assignment": "Sie haben keinen Zugriff auf die Zuweisung von Benutzergruppenberechtigungen",
        "Global roles only": "Nur globale Rollen",
        "MFA Configuration": "MFA Konfiguration",
        "Running": "Läuft",
        "Stopped": "Gestoppt",
        "Maintenance": "Wartung",
        "Connected per App last 30 minutes": "Verbunden pro App in den letzten 30 Minuten",
        "Users Connected": "Verbundene Benutzer",
        "Global Administrator per Areas": "Globaler Administrator pro Bereich",
        "Global Administrator per App": "Globaler Administrator pro App",
        "Global Administrators": "Globale Administratoren",
        "Login success per hour": "Erfolgreiche Anmeldungen pro Stunde",
        "Logs": "Protokolle",
        "Global Admin": "Globaler Administrator",
        "Polaris Permission Create": "Polaris Berechtigung erstellen",
        "Invitation mail sent successfully.": "Einladungs-E-Mail erfolgreich gesendet.",
        "Failed to send invitation mail. Please try again later.": "Fehler beim Senden der Einladungs-E-Mail. Bitte versuchen Sie es später erneut.",
        "Select Api Entry...": "API-Eintrag auswählen...",
        "Api Entry": "API-Eintrag",
        "Select a date...": "Datum auswählen...",
        "Start date": "Startdatum",
        "End date": "Enddatum",
        "Access Per User. Date Range": "Zugriff pro Benutzer. Datumsbereich",
        "User Access": "Benutzerzugriff",
        "Select a Time Range": "Wählen Sie einen Zeitraum",
        "The 'Identity' application cannot be deleted.": "Die Anwendung 'Identity' kann nicht gelöscht werden.",
        "The User is not the owner of the application and cannot delete it.": "Der Benutzer ist nicht der Eigentümer der Anwendung und kann sie nicht löschen.",
        "Application deleted successfully.": "Anwendung erfolgreich gelöscht.",
        "Failed to delete the application. Please try again later.": "Anwendung konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.",
        "Image uploaded successfully": "Bild erfolgreich hochgeladen.",
        "At least 12 characters long.": "Mindestens 12 Zeichen lang.",
        "At least one uppercase letter, one lowercase letter, one number, and one symbol.": "Mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Symbol.",
        "Password must be changed every 90 days.": "Das Passwort muss alle 90 Tage geändert werden.",
        "Cannot reuse any of the last 7 passwords.": "Es dürfen keine der letzten 7 Passwörter wiederverwendet werden.",
        "Password Requirements": "Passwortanforderungen",
        "Approve Operation Request": "Genehmigung des Betriebsantrags",
        "There's already a group named {0}": "Es gibt bereits eine Gruppe namens {0}",
        "Go to": "Gehe zu",      }
  }
}