import { FC, useState, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Modal, Select, Tag, Tooltip, Button, Checkbox, DatePicker } from 'antd'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import { clone, formatDateTime, nameof } from 'src/core/utils/object'
import { container } from 'src/inversify.config'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import { SystemLogStore, SystemLogSummary, OperationType } from 'src/stores/authLogs/logs-store'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import { CheckOutlined, LockOutlined, LoadingOutlined, DownloadOutlined } from '@ant-design/icons'
import HttpService from 'src/core/services/http.service'
import FileSaver from 'file-saver'
import { buildUrl } from 'src/components/util'
import TenantSelect from 'src/components/tenantEdit'
import UserSelect from 'src/components/userEdit'

import { SearchOutlined } from '@ant-design/icons'
import CodeEditor from 'src/common/code-editor'
import * as React from 'react'
import moment from 'moment'

const { RangePicker } = DatePicker
const { info, error, confirm } = Modal
const { Option } = Select

interface SystemLogViewProps extends RouteComponentProps, CacheProps, WithTranslation {

}
const SystemLog: FC<SystemLogViewProps> = (props) => {
  const { t } = props
  const systemLogStore = container.get<SystemLogStore>(SystemLogStore)
  const systemLogStoreState = systemLogStore.state
  const [isBusy, setIsBusy] = useState(false)
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'date', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    parameters: {
      includeHierarchy: 'true',
    },
    odataObject: {
      filter: {}
    },
  } as Query)

  const [operationFilter, setOperationFilter] = useState<any>()
  const [initPagination, setInitPagination] = useState(false)
  const httpService = container.get(HttpService)
  const [indeterminate, setIndeterminate] = useState(0)
  const [tenantFilter, setTenantFilter] = useState<any>()
  const [userFilter, setUserFilter] = useState<any>()
  const [startDateFilter, setStartDateFilter] = useState<any>()
  const [endDateFilter, setEndDateFilter] = useState<any>()

  const getMetadataValue = (value) => {
    if (value % 3 == 0) return undefined
    if (value % 3 == 1) return true
    else return false
  }

  useEffect(() => {
    var queryStored = props.cache.getWithCustomKey(systemLogStore.baseUrl)
    if (queryStored && queryStored.query)
      setQuery(queryStored.query)
    if (queryStored && queryStored.operation)
      setOperationFilter(queryStored.operation)
    if (queryStored && queryStored.tenant)
      setTenantFilter(queryStored.tenant)
    if (queryStored && queryStored.user)
      setUserFilter(queryStored.user)
    if (queryStored && queryStored.startDate)
      setStartDateFilter(queryStored.startDate)
    if (queryStored && queryStored.endDate)
      setEndDateFilter(queryStored.endDate)

    systemLogStore.load(Build(queryStored?.query || query, queryStored?.operation || operationFilter, (queryStored?.tenant) || tenantFilter, (queryStored?.user) || userFilter, (queryStored?.intoMetadata) || getMetadataValue(indeterminate), queryStored?.startDate || startDateFilter, queryStored?.endDate || endDateFilter))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const Build = (query: Query, operation: any, tenant: any, user: any, intoMetadata: any, startDate: any, endDate: any): Query => {
    if (operation) {
      query.parameters.operation = operation
    }
    else {
      query.parameters.operation = undefined

    }
    if (tenant) {
      var filterTenant = {
        tenantId: { eq: { type: 'guid', value: tenant.value } }
      }
      query.odataObject.filter.tenant = filterTenant
    }
    else {
      query.odataObject.filter.tenant = undefined
    }
    if (user) {
      var filterUser = {
        userId: { eq: { type: 'guid', value: user.id } }
      }
      query.odataObject.filter.user = filterUser
    }
    else {
      query.odataObject.filter.user = undefined
    }
    if (intoMetadata) {
      query.parameters.intoMetadata = intoMetadata
    }
    else {
      query.parameters.intoMetadata = undefined
    }
    if (intoMetadata && tenant) {
      query.parameters.metadataTenantId = tenant.value
    }
    else {
      query.parameters.metadataTenantId = undefined
    }
    if (intoMetadata && user) {
      query.parameters.metadataUserId = user.id
    }
    else {
      query.parameters.metadataUserId = undefined
    }

    if (startDate) {
      var startDateMoment = moment(startDate).format("YYYY-MM-DD HH:mm:ss")
      query.parameters.startDate = startDateMoment
    }
    else {
      query.parameters.startDate = undefined
    }
    if (endDate) {
      var endDateMoment = moment(endDate).format("YYYY-MM-DD HH:mm:ss")
      query.parameters.endDate = endDateMoment
    }
    else {
      query.parameters.endDate = undefined
    }
    props.cache.saveWithCustomKey(systemLogStore.baseUrl, { query: query, operation: operation })
    return query
  }

  const onDownloadExcel = async () => {
    const downloadExcelUrl: string = 'api/v1/admin/systemlog/export'
    const downloadExcelFilename: string = 'SystemLog.xlsx'
    setIsBusy(true)
    const result = await httpService.get(`${downloadExcelUrl}?${buildUrl(query)}`, {
      responseType: 'arraybuffer'
    })
    const blob = new Blob([result.data as any], { type: result.headers['content-type'] });
    (FileSaver as any).saveAs(blob, downloadExcelFilename)
    setIsBusy(false)
  }


  const GetOperation = (data) => {
    const showModal = () => {
      let resultData = JSON.stringify(data.metaData, null, 2)
      Modal.info({
        title: 'Metadata',
        width: 800,
        content: (
          <div>
            <CodeEditor
              value={resultData}
              classSuffix="fi-code-editor"
              height={300}
              language="yaml"
            />

          </div>
        ),
        onOk() { },
      })
    }

    if (data.operation === "Permission") {
      return (
        <span>
          <Tag icon={<CheckOutlined />} color="#87d068">
            {t("Permission")}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.operation === "BulkInsert") {
      return (
        <span>
          <Tag icon={<LockOutlined />} color="yellow">
            {t("BulkInsert")}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.operation === "PolarisPermissionCreate") {
      return (
        <span>
          <Tag icon={<LockOutlined />} color="red">
            {t("Polaris Permission Create")}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    if (data.operation === "ApproveOperationRequest") {
      return (
        <span>
          <Tag icon={<LockOutlined />} color="blue">
            {t("Approve Operation Request")}
          </Tag>
          <Button
            icon={<SearchOutlined />}
            onClick={showModal}
            style={{ marginLeft: '5px' }}
          />
        </span>
      )
    }
    return null
  }
  const onChangeMetadataCustomized = (value) => {
    var newquery = query
    systemLogStore.load(Build(newquery, operationFilter, tenantFilter, userFilter, getMetadataValue(indeterminate + 1), startDateFilter, endDateFilter,))
    setIndeterminate(indeterminate + 1)
  }

  const onTenantFilterChange = (tenant: any) => {
    setInitPagination(!initPagination)
    setTenantFilter(tenant)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    systemLogStore.load(Build(newquery, operationFilter, tenant, userFilter, getMetadataValue(indeterminate), startDateFilter, endDateFilter))
  }

  const onUserFilterChange = (user: any) => {
    setInitPagination(!initPagination)
    setUserFilter(user)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    systemLogStore.load(Build(newquery, operationFilter, tenantFilter, user, getMetadataValue(indeterminate), startDateFilter, endDateFilter))
  }

  const onOperationFilterChange = (operation: any) => {
    setInitPagination(!initPagination)
    setOperationFilter(operation)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    systemLogStore.load(Build(newquery, operation, tenantFilter, userFilter, getMetadataValue(indeterminate), startDateFilter, endDateFilter))
  }

  const onDateRangeFilterChange = (dates: any) => {
    var [startDate, endDate] = [null, null]
    if (dates) {
      [startDate, endDate] = dates
    }
    setInitPagination(!initPagination)
    setStartDateFilter(startDate)
    setEndDateFilter(endDate)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    systemLogStore.load(Build(newquery, operationFilter, tenantFilter, userFilter, getMetadataValue(indeterminate), startDate, endDate))
  }


  const load = (q: Query) => {
    systemLogStore.load(q)
  }


  const tableModel = {
    query: query,
    columns: [
      {
        centered: true,
        field: 'userName',
        title: t('Username'),
        renderer: (data) => <span>{data.userName}</span>
      },
      {
        field: 'tenantName',
        title: t('Tenantname'),
        renderer: (data) => <span>{data.tenantName}</span>
      },
      {
        sortable: true,
        field: 'operation',
        title: t('Operation'),
        renderer: (data) => <span>{GetOperation(data)}</span>
      },
      {
        sortable: true,
        field: 'date',
        title: t('Date'),
        renderer: (data) => <span>{formatDateTime(data.date, undefined, "DD-MM-YYYY HH:mm:ss")}</span>,
      },

    ],
    data: systemLogStoreState.value,
    sortFields: [
        { field: 'date', text: t('Date'), useProfile: false },
        { field: 'operation', text: t('Operation'), useProfile: false }
    ],
  } as TableModel<SystemLogSummary>




  const rightToolbar = (
    <React.Fragment>
      <li>
        <Select defaultValue={operationFilter}
          allowClear placeholder={t("Select Operation...")}
          style={{ width: 250 }}
          onChange={(value) => onOperationFilterChange(value)}
        >
          <Option value={OperationType.Permission.toString()}>{
            <Tag icon={<CheckOutlined />} color="#87d068">
              {t("Permission")}
            </Tag>}
          </Option>

          <Option value={OperationType.BulkInsert.toString()}>{
            <Tag icon={<LockOutlined />} color="yellow">
              {t("BulkInsert")}
            </Tag>}
          </Option>

          <Option value={OperationType.PolarisPermissionCreate.toString()}>{
            <Tag icon={<LockOutlined />} color="red">
              {t("Polaris Permission Create")}
            </Tag>}
          </Option>

          <Option value={OperationType.ApproveOperationRequest.toString()}>{
            <Tag icon={<LockOutlined />} color="blue">
              {t("Approve Operation Request")}
            </Tag>}
          </Option>

        </Select>
      </li>

      <li>
        <Tooltip placement="topLeft" title={t('Export to Excel')}>
          <Button onClick={e => onDownloadExcel()} disabled={isBusy}>
            {isBusy && <LoadingOutlined spin />}
            {!isBusy && <DownloadOutlined />}
          </Button>
        </Tooltip>
      </li>
      <li>
        <Checkbox style={{ marginTop: 5, marginLeft: 15 }} indeterminate={indeterminate % 3 == 0} onChange={(x) => onChangeMetadataCustomized(x.target.checked)} checked={indeterminate % 3 == 1} >
          {t("Search into Metadata?")}
        </Checkbox>
      </li>

      <li>
        <UserSelect minWidth={200} value={userFilter} placeholder={t("Select User...")} valueAsItemReference onChange={(value) => onUserFilterChange(value)} />
      </li>

      <li>
        <TenantSelect value={tenantFilter} placeholder={t("Select Tenant...")} valueAsItemReference onChange={(value) => onTenantFilterChange(value)} style={{ width: '100%', height: '40px' }} />
      </li>

      <li>
        <RangePicker
          renderExtraFooter={() => t("Select a date...")}
          style={{ width: 350 }}
          format='DD-MM-YYYY HH:mm:ss'
          showTime
          onChange={(dates) => onDateRangeFilterChange(dates)}
          placeholder={[t('Start date'), t('End date')]}
          value={[startDateFilter, endDateFilter]}
          disabledDate={(current) => {
            if (startDateFilter && !endDateFilter) {
              return current && current < moment(startDateFilter)
            } else if (!startDateFilter && endDateFilter) {
              return current && current > moment(endDateFilter)
            }
            return false
          }}
        />
      </li>

    </React.Fragment>
  )

  return (

    <div style={{ width: '100%' }}>
      <TableView
        enableTableSettings
        rightToolbar={rightToolbar}
        onQueryChanged={(query: Query) => {
          setQuery(query)
          systemLogStore.load(Build(query, operationFilter, tenantFilter, userFilter, getMetadataValue(indeterminate), startDateFilter, endDateFilter))
        }}
        onRefresh={() => systemLogStore.load(query)}
        model={tableModel}
        error={systemLogStoreState.errorMessage.value && formatMessage(systemLogStoreState.errorMessage.value)}
      >
      </TableView>
    </div>
  )
}



// Wire up the React component to the Redux store
export default withCache(withTranslation()(withRouter(SystemLog)))