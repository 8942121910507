import { IdcardOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons"
import { FC, useMemo } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { QueryResult } from "../core/stores/data-store"
import SelectionInput from "../core/ui/form/selectionInput"
import { container } from "../inversify.config"

import { UsersStore, UserSummary} from 'src/stores/users/users-store'
import FloatSelectionInput from "../core/ui/form/floatSelectionInput"

export interface UserReference {
  id: string
  name: string
  displayName: string,
  email : string
}

const UserEditor: FC<
  {
    label?: string
    required?: boolean
    onlyname? : boolean
    idMode?: boolean
    disabled?: boolean
    searchType?: string
    minWidth?: number
    placeholder?: string
    value?: string
    valueAsItemReference: boolean
    onChange?: (value: UserReference) => void
    onSelect?: (value: string) => void
  } & WithTranslation
> = ({ t, disabled, searchType, minWidth, placeholder, value, onChange, onSelect, i18n, ...props }) => {
  const currentStore = useMemo(() => container.get(UsersStore), [])
  const currentState = currentStore.state

  const formatTitle = (user: UserReference) => {
    return props.onlyname ? user?.name : user?.displayName
  }

  const getLabel = (u: UserSummary, onlyName: boolean = false) => {
    let name = `${u.firstName || ''} ${u.lastName || ''}`.trim()
    if (name.length === 0) name = u.email
    if (name.length === 0) name = `User_${u.id}`
    return onlyName ? name : name === u.email ? name : name + " (" + u.email + ")"
  };

 

  let getUser = async (query: string, value: string) => {
    await currentStore.load({
      searchQuery: query,
      skip: 0,
      take: 1000
    })
    return {
      items: currentState.items.value.map((o) => ({ id: o.id ? `${o.id}` : null, email: o.userName, name: getLabel(o, true), displayName: getLabel(o, false) })),
      count: currentState.count.value
    } as QueryResult<any>
  }

  return (
    <FloatSelectionInput
      content={(o: any) => (
        <div>
          <UserOutlined />
          <span>&nbsp;{formatTitle(o)}</span>
        </div>
      )}
      searchable
      nullable
      multiple={false}
      onSelect={(item) => {
        if (onSelect) {
          onSelect(item.id || item)
        }
      }}
      valueAsItemReference
      disabled={disabled}
      minWidth={minWidth}
      placeholder={placeholder}
      query={getUser}
      value={value}
      onChange={(item) => {
        if (onChange) {
          onChange(item ? { id: item.id, name: formatTitle(item) , displayName : item.displayName, email : item.email } : null)
        }
      }}
      {...props}
    />
  )
}

export default withTranslation()(UserEditor)





